import React, { useState } from "react";
import { Container } from "@mui/material";
import { colorPalette } from "../../../../theme/color-palette";
import { Button } from "../../../common/Button";
import { CustomDrawer } from "../../../common/Drawer";
import { Navbar } from "../../../Navbar/Mobile";
import UserDetails from "../UserDetails";

export const UserInfo = ({
  userInfoDrawer,
  setUserInfoDrawer,
  userData,
  setUserData,
  onChangeHandler,
  onSubmitHandler,
  allCountries,
  type,
  travellerList,
  handleSavedTraveller,
}) => {
  const { white500, primary500 } = colorPalette;
  const [isValidFName, setIsValidFName] = useState(true);
  const [isValidLName, setIsValidLName] = useState(true);

  return (
    <div>
      <CustomDrawer
        position={"top"}
        state={userInfoDrawer}
        setState={(val) => setUserInfoDrawer(val)}
        label={""}
      >
        <div className='fixed top-0 w-full z-10'>
          <Navbar
            color={white500}
            bgColor={primary500}
            label={"Add Guest"}
            showMenu={false}
            onClose={() => setUserInfoDrawer({ bottom: false })}
          />
        </div>
        <Container className='mt-20 mb-15 mobile_passenger_container'>
          <UserDetails
            isValidFName={isValidFName}
            isValidLName={isValidLName}
            setIsValidFName={setIsValidFName}
            setIsValidLName={setIsValidLName}
            onChangeHandler={onChangeHandler}
            userData={userData}
            allCountries={allCountries}
            type={type}
            travellerList={travellerList}
            handleSavedTraveller={handleSavedTraveller}
          />
        </Container>
        <div className='py-4 bg-white  mt-10 fixed bottom-0 w-full shadow-md'>
          <Container>
            <Button
              label={"Confirm"}
              color={white500}
              bgColor={primary500}
              size={"medium"}
              variant={"contained"}
              width={"100%"}
              disabled={
                !(
                  isValidFName &&
                  isValidLName &&
                  userData?.firstName &&
                  userData?.birthdate &&
                  userData?.salutation &&
                  userData?.lastName
                )
              }
              onClickHandler={() => onSubmitHandler(userData?.id, type)}
            />
          </Container>
        </div>
      </CustomDrawer>
    </div>
  );
};
