import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AUTHLINKS, NAVLINKS } from '../../../utils/constant';
import { LoginPage } from '../../Login-Signup/Login/LoginPage';
import './navbar.scss';
import { Avatar } from '@mui/material';
import { verifyToken } from '../../../Api/Auth';
import ProfileDropdown from './ProfileDropdown';

export function Navbar({ logo, customClass }) {
  const [open, setOpen] = useState(false);
  const [signUpModal, setSignUpModal] = useState(false);
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const [isAuth, setIsAuth] = useState(false);

  const userData = localStorage.getItem('user');

  useEffect(() => {
    verifyToken()
      .then(({ data }) => setIsAuth(data))
      .catch((err) => {
        setIsAuth(false);
        // dispatch(
        //   toastHandler({
        //     ...openToast,
        //     open: true,
        //     message: err?.response?.data?.message || "Something went wrong...",
        //   })
        // );
      });
  }, [userData]);

  const handleOpen = (item) => {
    setOpen(true);
    item === 'Sign Up' ? setSignUpModal(true) : setSignUpModal(false);
  };
  const handleClose = (setShowOtp) => {
    setOpen(false);
    setShowOtp(false);
  };

  const loggedUser = JSON.parse(localStorage.getItem('user'));

  const avatarBg = customClass === 'navbar-home' ? '#b62079' : '';

  const handleDropdown = (event) => {
    anchorElProfile
      ? setAnchorElProfile(null)
      : setAnchorElProfile(event.currentTarget);
  };

  const openProfile = Boolean(anchorElProfile);

  return (
    <>
      <nav className={customClass}>
        <div className='header-right'>
          <Link to={'/'} className='logo'>
            <img src={logo} alt='flybase-logo' className='flybase-logo' />
          </Link>
        </div>

        <div className='flex items-center'>
          <div className='nav-links'>
            {NAVLINKS?.map((item, index) => (
              <Link key={item.name} to={item.url}>
                {item?.name}
              </Link>
            ))}
          </div>
          <div className="auth-signs relative">
            {!isAuth ? (
              <p
                className={`cursor-pointer ${
                  customClass === 'navbar-home' ? 'text-inherit' : 'text-white'
                }`}
              >
                {AUTHLINKS?.map((item, index) => {
                  return (
                    <span
                      className='ml-12'
                      key={index}
                      onClick={() => handleOpen(item)}
                    >
                      {item}
                    </span>
                  );
                })}
              </p>
            ) : (
              <div
                onClick={handleDropdown}
                className="flex justify-center items-center cursor-pointer"
              >
                <Avatar
                  sx={{ background: avatarBg }}
                  className='ml-12 mr-28 mb-2'
                >
                  {loggedUser?.user?.name[0].toUpperCase()}
                </Avatar>
                {openProfile && (
                  <ProfileDropdown
                    loggedUser={loggedUser?.user}
                    avatarBg={avatarBg}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </nav>
      <LoginPage
        open={open}
        signUpModal={signUpModal}
        setSignUpModal={setSignUpModal}
        handleClose={handleClose}
      />
    </>
  );
}
