import React, { useEffect, useState } from "react";
import { FlightOptions } from "./FlightOptions";
import { SeatOptions } from "./SeatOptions";
import "./index.scss";
import { useSelector } from "react-redux";

export const MobileSearch = ({
  selectedIndex,
  setSelectedIndex,
  faresDetails,
}) => {
  const {
    cart: { searchFare },
  } = useSelector((state) => state);
  const [step, setStep] = useState(1);
  const [selectionStep, setSelectionStep] = useState(1);
  const [flightFareList, setFlightList] = useState([]);
  const [total, setTotal] = useState();

  useEffect(() => {
    if (searchFare?.schedule) {
      const { returnSchedule, schedule } = searchFare;
      if (selectionStep === 1) {
        setFlightList(schedule);
      } else {
        setFlightList(returnSchedule);
      }
    }
  }, [selectionStep, searchFare]);

  return (
    <div className='search_card_type'>
      {step === 1 ? (
        <FlightOptions
          setSelectionStep={setSelectionStep}
          selectionStep={selectionStep}
          setStep={setStep}
          step={step}
          flightFareList={flightFareList}
          total={total}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
      ) : (
        <SeatOptions
          setSelectionStep={setSelectionStep}
          selectionStep={selectionStep}
          setStep={setStep}
          step={step}
          total={total}
          setTotal={setTotal}
          faresDetails={faresDetails}
        />
      )}
    </div>
  );
};
