import React, { useRef } from 'react';
import { FlightBookingSection } from '../../homepage/Desktop/Search/FlightBookingSection/FlightBookingSection';

export const DetailsForFlightSearch = React.memo(
  ({
    tabValue,
    date,
    setDate,
    anchorElCalender,
    setAnchorElCalender,
    tripType,
    setTripType,
    departureLocation,
    setDepartureLocation,
    arrivalLocation,
    setArrivalLocation,
    attendantType,
    setAttendantType,
    flightListState,
    setFlightListState,
    submitDataHandler,
    setPassengerNumbers,
    passengerNumbers,
  }) => {
    const inputRefDepart = useRef();
    const inputRefReturn = useRef();

    //event handlers for setting cities
    const changeDepartureCity = (event, newValue) => {
      setDepartureLocation(newValue);
      setArrivalLocation('');
    };

    const changeArrivalCity = (event, newValue) => {
      setArrivalLocation(newValue);
    };

    return (
      <FlightBookingSection
        departureCityValue={departureLocation}
        setDepartureCityValue={setDepartureLocation}
        arrivalCityValue={arrivalLocation}
        setArrivalCityValue={setArrivalLocation}
        changeDepartureCity={changeDepartureCity}
        changeArrivalCity={changeArrivalCity}
        tabValue={tabValue}
        passengerNumbers={passengerNumbers}
        setPassengerNumbers={setPassengerNumbers}
        date={date}
        setDate={setDate}
        anchorElCalender={anchorElCalender}
        setAnchorElCalender={setAnchorElCalender}
        tripType={tripType}
        setTripType={setTripType}
        submitDataHandler={submitDataHandler}
        attendantType={attendantType}
        setAttendantType={setAttendantType}
        inputRefDepart={inputRefDepart}
        inputRefReturn={inputRefReturn}
      />
    );
  }
);
