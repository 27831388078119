import React from 'react';
import Premium from '../../../assets/svgs/seatIcon.svg';
import Back from '../../../assets/svgs/back.svg';
import Front from '../../../assets/svgs/front.svg';
import Selected from '../../../assets/svgs/selected.svg';
import Unavailable from '../../../assets/svgs/unavailable.svg';
import Normal from '../../../assets/svgs/normal-seat.svg';
import styled from 'styled-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Tooltip } from '@mui/material';

let seatType = {
  Premium: Premium,
  Back: Back,
  Front: Front,
  Selected: Selected,
  Unavailable: Unavailable,
  Normal: Normal,
};

const seatTypeName = (name) => {
  if (name?.includes('Back')) return 'Back';
  if (name?.includes('Front')) return 'Front';
  if (name?.includes('Premium')) return 'Premium';
  return 'Normal';
};

const CommonSeatMap = ({ seatMapData, selectedSeatHandler }) => {
  const planeData = (val, index) => {
    if (val.type === 'EXIT') {
      return (
        <Exit size={seatMapData?.columnsString?.length}>
          <ArrowBackIcon />
          <p>Emergency Exit</p>
          <ArrowForwardIcon />
        </Exit>
      );
    } else if (val.type === 'Lavatory' || val.type === 'Galley') {
      return (
        <Essentials size={Math.floor(seatMapData?.columnsString?.length / 2)}>
          {val.type}
        </Essentials>
      );
    } else if (val === '') {
      return (
        <div className='flex items-center justify-center'>{index + 1}</div>
      );
    } else if (!val?.available) {
      return (
        <div>
          <img
            className='my-seat'
            src={seatType.Unavailable}
            alt='flight seat'
          />
        </div>
      );
    } else if (val) {
      return (
        <div onClick={() => selectedSeatHandler(val.name)}>
          <img
            className='my-seat cursor-pointer'
            src={
              val?.selected
                ? seatType?.Selected
                : seatType[seatTypeName(val?.seatType)]
            }
            alt='flight seat'
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <div>
      <SeatMapContainer
        className='mt-5'
        size={seatMapData?.columnsString?.length}
      >
        {seatMapData?.columnsString?.map((item) => (
          <p>{item}</p>
        ))}
      </SeatMapContainer>
      {seatMapData?.data?.map((item, index) => (
        <SeatMapContainer size={seatMapData?.columnsString?.length}>
          {item?.map((val) => {
            return (
              <>
                <Tooltip
                  title={
                    <>
                      <p>{val?.name}</p>
                      <p>Price : {val?.price}</p>
                    </>
                  }
                  arrow
                >
                  {planeData(val, index)}
                </Tooltip>
              </>
            );
          })}
        </SeatMapContainer>
      ))}
    </div>
  );
};

export default CommonSeatMap;

const SeatMapContainer = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: ${({ size }) => `repeat(${size}, 1fr)`};
`;

const Exit = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column-start: span ${({ size }) => `${size}`};
  margin: 10px 8px 20px 8px;
`;

const Essentials = styled.div`
  text-align: center;
  grid-column-start: span ${({ size }) => `${size}`};
  margin: 10px 0px;
`;
