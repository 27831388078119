import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bookingDetailsApi } from "../Api/Booking";
import { Loader } from "../components/MobileLoader";
import { ModifyBooking } from "../components/profileDetails-Bookings/desktop/manageBooking/ModifyBooking";
import { toastHandler } from "../Redux/userReducer/user.action";
import { useUrlExtract, useWindowSize } from "../utils/utils";
import ModifyBookingMobile from "../components/profileDetails-Bookings/mobile/manageBookings/ModifyBooking";
import PositionedSnackbar from "../components/common/Toast";

const ModifyBookingView = () => {
  const [bookingData, setBookingData] = useState("");

  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;

  
  const dispatch = useDispatch();
  const allUrl = useUrlExtract("all");
  const {
    user: { openToast },
  } = useSelector((state) => state);

  useEffect(() => {
    bookingDetailsApi(allUrl?.bookingId)
      .then(({ data }) => {
        setBookingData(data);
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
      });
  }, [allUrl?.bookingId]);

  if (!bookingData) {
    return <Loader />;
  }

  return (
    <>
      {isMobile ? (
        <ModifyBookingMobile bookingData={bookingData} />
      ) : (
        <ModifyBooking bookingData={bookingData} />
      )}
      <PositionedSnackbar />
    </>
  );
};

export default ModifyBookingView;
