import React from "react";
import { useWindowSize } from "../../utils/utils";

export const Loader = () => {
  const [innerWidth] = useWindowSize();
  const isMobile = innerWidth < 640 && innerWidth !== 0;
  return (
    <>
      <div className='w-screen h-screen flex justify-center items-center m-auto '>
        <img
          src={
            "https://flybigassets.s3.ap-south-1.amazonaws.com/Webassets/gifLoaderShort.gif"
          }
          alt='loader'
          width={isMobile ? 350 : "auto"}
        />
      </div>
    </>
  );
};
