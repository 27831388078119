import React from "react";
import { Contact } from "../components/Contact/contact";

export const ContactView = () => {

  return (
    <div>
      <Contact />
    </div>
  );
};
