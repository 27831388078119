import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(
  name,
  distance
) {
  return { name, distance };
}

const rows = [
  createData('Take-off distance (MTOW, ISA, SL)', '1,315m'),
  createData('Landing distance (MLW, SL)', '915m'),
  createData('Range (max pax)', '740NM / 1,370km')
];

export default function DataSummary() {
  return (
    <TableContainer component={Paper} sx={{borderRadius:'0px', marginBottom:'200px'}}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow  sx={{backgroundColor:'#B62079' }}>
            <TableCell></TableCell>
            <TableCell align="left" sx={{color:'#ffffff'}}>ATR 72-600</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
            >
              <TableCell align="left" sx={{color: '#667085', fontWeight:'700'}}>{row.name}</TableCell>
              <TableCell align="left" sx={{color: '#667085'}}>{row.distance}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
