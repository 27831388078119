import React from "react";
import { CheckInBox } from "../../common/checkIn/CheckInBox";
import { Divider } from "@mui/material";
import { useScrollTop } from "../../../customHooks/useScrollTop";

export const CheckInStepOne = () => {
  useScrollTop();

  return (
    <div className="w-full m-auto">
      <main className="checkIn-container w-max-desk m-auto p-6">
        <p className="font350 text-xl mb-4">Check-In</p>
        <p className="font325 text-xs gotham-book mb-6">
          Complete it for free 72 hr to 60 min before your flight.
        </p>

        <CheckInBox usedIn="checkin" />

        <Divider />

        <div>
          <p className="font350 text-xl my-6">FAQs</p>

          <section>
            <p className="font350 text-sm mb-4">Who all can check-in ?</p>

            <ul className="ml-8">
              <li className="font325 text-xs mb-2 gotham-book">
                Guests holding a valid and confirmed flybig booking Reference or
                PNR.
              </li>
              <li className="font325 text-xs mb-2 gotham-book">
                A maximum of 9 guests per PNR with a maximum of 4 segments for
                customers fulfilling the check-in window criteria.
              </li>
            </ul>
          </section>

          <section>
            <p className="font350 text-sm mb-4 mt-6">
              Guests excluded from mandatory web check-in:
            </p>

            <ul className="ml-8">
              <li className="font325 text-xs mb-2 gotham-book">
                Unaccompanied minors (UNMR).
              </li>
              <li className="font325 text-xs mb-2 gotham-book">
                Guests with medical case (MEDA).
              </li>
              <li className="font325 text-xs mb-2 gotham-book">
                Guests travelling on a stretcher (STCR).
              </li>
            </ul>
          </section>
        </div>
      </main>
    </div>
  );
};
