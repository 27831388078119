import { Skeleton } from "@mui/material";
import React from "react";

export const SkeletonComp = ({ width, height, varient, customClass }) => {
  return (
    <Skeleton
      width={width}
      height={height}
      varient={varient}
      className={customClass}
    />
  );
};
