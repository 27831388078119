import { searchActionType } from "./search.actionType";
import { searchResults } from "../../Api/search";
import { toastHandler } from "../userReducer/user.action";
import { store } from "../store";
// const {store}

const { FLIGHT_LIST, SEARCH_DETAILS, PASSENGER_NUMBER } = searchActionType;

export const flightListHandler = () => {
  const {
    user: { openToast },
  } = store.getState();

  return async (dispatch) => {
    try {
      let { data } = await searchResults();
      dispatch(saveListData(data));
    } catch (err) {
      dispatch(
        toastHandler({
          ...openToast,
          open: true,
          message: "Something Went Wrong",
          severity: "error",
        })
      );
    }
  };
};

const saveListData = (data) => {
  return {
    type: FLIGHT_LIST,
    payload: data,
  };
};

export const searchDetailsHandler = (data) => {
  return {
    type: SEARCH_DETAILS,
    payload: data,
  };
};

export const updatePassengerNumbers = (data) => {
  return {
    type: PASSENGER_NUMBER,
    payload: data,
  };
};
