import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reviewBookingData } from "../Api/Booking";
import PositionedSnackbar from "../components/common/Toast";
import { Loader } from "../components/MobileLoader/index";
import { ReviewPage } from "../components/reviewPage/Index";
import { toastHandler } from "../Redux/userReducer/user.action";
import { useUrlExtract } from "../utils/utils";

export function ReviewPageView() {
  const [reviewData, setReviewData] = useState("");
  const sessionId = useUrlExtract("sessionId");
  const dispatch = useDispatch();
  const {
    user: { openToast },
  } = useSelector((state) => state);

  useEffect(() => {
    reviewBookingData(sessionId)
      .then(({ data }) => {
        setReviewData(data);
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message || "Something went wrong...",
            severity: "error",
          })
        );
      });
  }, []);

  return (
    <>
      {!reviewData?.sessionId ? (
        <Loader />
      ) : (
        <ReviewPage reviewData={reviewData} />
      )}
      <PositionedSnackbar />
    </>
  );
}
