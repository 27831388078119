import React, { useEffect, useState } from 'react';
import { Grid, TextField, CircularProgress } from '@mui/material';
import { Button } from '../Button';
import { colorPalette } from '../../../theme/color-palette';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkInDataHandler,
  checkInStepHandler,
} from '../../../Redux/CheckInReducer/checkIn.action';
import { useNavigate } from 'react-router-dom';
import { getDetailsByPnr } from '../../../Api/Booking';
import { toastHandler } from '../../../Redux/userReducer/user.action';

export const CheckInBox = ({ usedIn }) => {
  const [pnr, setPnr] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const {
    user: { openToast },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(()=>{
    // TEMPORARY REDIRECT
    if(usedIn === 'manage-booking'){
      window.open('https://flybig.paxlinks.com/manage-booking')
    }else{
      window.open('https://flybig.paxlinks.com/web-checkin')
    }
  },[])

  const { primary500, white500 } = colorPalette;

  const handleCheckIn = () => {
    setLoading(true);
    getDetailsByPnr(pnr, name)
      .then(({ data }) => {
        if (usedIn === 'manage-booking') {
          navigate(`/my-bookings-itinerary?bookingId=${data?.id}`);
        } else {
          dispatch(checkInDataHandler(data));
          dispatch(checkInStepHandler(2));
          navigate('/check-in');
        }
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: 'error',
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const buttonLabel = usedIn === 'manage-booking' ? 'View Booking' : 'check-in';

  return (
    <Grid
      container
      spacing={2}
      className='checkIn-box-container w-full m-auto w-max-desk py-6 items-center'
    >
      <Grid item lg={4} md={4} xs={12}>
        <p className='text-xs font-medium mb-2'>PNR or Booking reference</p>
        <TextField
          type='text'
          size='small'
          value={pnr}
          onChange={(e) => {
            setPnr(() => e.target.value.toUpperCase());
          }}
          variant='outlined'
          className='w-full'
          placeholder='PNR / Booking reference is required'
        ></TextField>
      </Grid>
      <Grid item lg={4} md={4} xs={12}>
        <p className='text-xs font-medium mb-2'>Last Name</p>
        <TextField
          type='text'
          size='small'
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          variant='outlined'
          className='w-full'
          placeholder='Enter Last Name...'
        ></TextField>
      </Grid>
      <Grid item lg={2} md={2} xs={12}>
        <div className='mt-6'>
          <Button
            label={
              loading ? (
                <>
                  Loading...
                  <span className='ml-4 mt-1'>
                    <CircularProgress color='inherit' size={20} />
                  </span>
                </>
              ) : (
                buttonLabel
              )
            }
            bgColor={primary500}
            color={white500}
            width={'100%'}
            onClickHandler={handleCheckIn}
            disabled={loading || pnr?.length < 6 || name.trim().length === 0}
          />
        </div>
      </Grid>
    </Grid>
  );
};
