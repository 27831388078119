import React, { useEffect, useState } from "react";
import { colorPalette } from "../../../../theme/color-palette";
import { Navbar } from "../../../Navbar/Mobile";
import logo from "../../../../assets/svgs/logo.svg";
import { useNavigate } from "react-router-dom";
import { FlightDetailsComp } from "../../../common/FlightDetails";
import { Box, Divider, Tooltip } from "@mui/material";
import PaxTable from "../PaxTable";
import { Button } from "../../../common/Button";
import {
  postCancelBooking,
  fareDetailsCheckin,
  getDetailsByPnr,
} from "../../../../Api/Booking";
import { useDispatch, useSelector } from "react-redux";
import {
  checkInDataHandler,
  checkInStepHandler,
} from "../../../../Redux/CheckInReducer/checkIn.action";
import {
  cancelBookingData,
  toastHandler,
} from "../../../../Redux/userReducer/user.action";
import { useUrlExtract } from "../../../../utils/utils";
import { BsInfoCircle } from "react-icons/bs";
import CancelModal from "../../common/CancelModal";

const { primary500, white500 } = colorPalette;

const Itinerary = ({ bookingData }) => {
  const [faresData, setFaresData] = useState({});
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [cancelBookingFailed, setCancelBookingFailed] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    user: { openToast },
  } = useSelector((state) => state);

  const { bookingId: Id } = useUrlExtract("bookingId");

  const {
    bookingId,
    passengers,
    selectedFare: { schedule },
    selectedFare,
    status,
  } = bookingData;

  useEffect(() => {
    fareDetailsCheckin(Id)
      .then(({ data }) => {
        setFaresData(data);
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
      });
  }, []);

  const navigateToWebCheckin = () => {
    getDetailsByPnr(bookingId, passengers?.[0]?.lastName)
      .then(({ data }) => {
        dispatch(checkInDataHandler(data));
        dispatch(checkInStepHandler(2));
        navigate("/check-in");
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
      });
  };

  const navigateToCancelBooking = () => {
    navigate(`/cancel-booking?bookingId=${Id}`);
  };

  const handleCancelModal = () => {
    setIsCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setIsCancelModal(false);
  };

  const handleCancelBooking = () => {
    setIsCancelLoading(true);
    postCancelBooking(Id)
      .then(({ data }) => {
        navigate(`/cancel-booking?bookingId=${Id}`);
        dispatch(cancelBookingData(data));
      })
      .catch((err) => {
        setIsCancelLoading(false);
        setCancelBookingFailed(true);
      });
  };

  const navigateToModifyBooking = () => {
    navigate(`/modify-booking?bookingId=${Id}`);
  };

  return (
    <div>
      <Navbar
        color={white500}
        bgColor={primary500}
        showMenu={true}
        icon={logo}
        onClose={() => navigate(-1)}
      />
      <main className='w-full p-4'>
        <p className='flex justify-between items-center'>
          <span className='text-lg'>PNR : {bookingId}</span>
          <span className='text-green500'>{status}</span>
        </p>

        <Divider className='my-4' />

        <div>
          {schedule?.[0] && (
            <FlightDetailsComp
              departureTime={schedule?.[0]?.departureTime}
              arrivalTime={schedule?.[0]?.arrivalTime}
              departureDate={schedule?.[0]?.departureDate}
              arrivalDate={schedule?.[0]?.arrivalDate}
              origin={schedule?.[0]?.origin}
              destination={schedule?.[0]?.destination}
            />
          )}
        </div>

        <Divider className='my-4' />

        <div>
          <p>Guest Details</p>
        </div>

        <Divider className='my-4' />

        <PaxTable passengers={passengers} flightData={schedule} />

        {selectedFare?.returnSchedule?.length && (
          <PaxTable
            passengers={passengers}
            flightData={selectedFare?.returnSchedule}
          />
        )}

        <Divider className='my-4' />

        <div className='p-1 flex justify-between items-center'>
          <span>
            Total Amount Paid
            <Tooltip
              title={
                <Box>
                  <p className='flex justify-between font325 text-xs mb-2 gotham-book'>
                    <span>Fares Total</span>{" "}
                    <span>
                      {faresData?.fareDetails?.total -
                        faresData?.fareDetails?.totalTaxAndFees?.total}
                    </span>
                  </p>
                  <p className='flex justify-between font325 text-xs mb-2 gotham-book'>
                    <span>Total Tax and Fees</span>{" "}
                    <span>
                      {faresData?.fareDetails?.totalTaxAndFees?.total}
                    </span>
                  </p>
                  <p className='flex justify-between font325 text-xs mb-2 gotham-book'>
                    <span>Check-in Payments</span>{" "}
                    <span>{faresData?.webCheckinPayments}</span>
                  </p>
                </Box>
              }
              arrow
              enterTouchDelay={0}
            >
              <span className='ml-2 mt-1 cursor-pointer'>
                <BsInfoCircle />
              </span>
            </Tooltip>
          </span>
          <span> {faresData?.total} </span>
        </div>

        <Divider className='my-4' />

        <div className={"mb-4"}>
          <Button
            bgColor={primary500}
            color={white500}
            label='web check-in'
            width={"100%"}
            onClickHandler={navigateToWebCheckin}
          />
        </div>

        <div className='flex justify-between'>
          <Button
            bgColor={primary500}
            color={white500}
            label='Modify Booking'
            width={"47%"}
            onClickHandler={navigateToModifyBooking}
          />
          <Button
            bgColor={primary500}
            color={white500}
            label='Cancel Booking'
            width={"47%"}
            onClickHandler={navigateToCancelBooking}
          />
        </div>
      </main>
      <CancelModal
        isCancelModal={isCancelModal}
        handleCloseCancelModal={handleCloseCancelModal}
        cancelBookingFailed={cancelBookingFailed}
        isCancelLoading={isCancelLoading}
        handleCancelBooking={handleCancelBooking}
      />
    </div>
  );
};

export default Itinerary;
