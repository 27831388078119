import React from "react";
import Premium from "../../../assets/svgs/seatIcon.svg";
import Back from "../../../assets/svgs/back.svg";
import Front from "../../../assets/svgs/front.svg";
import Selected from "../../../assets/svgs/selected.svg";
import Unavailable from "../../../assets/svgs/unavailable.svg";

export const SeatTypes = ({ screen }) => {
  return (
    <div className='flex justify-evenly items-center my-3'>
      <div className='flex flex-col justify-center items-center'>
        <img src={Premium} alt='seat type' />
        <p className='font325 text-xxs'>
          {screen === "desktop" ? "Premium Seat" : "Premium"}{" "}
        </p>
      </div>
      <div className='flex flex-col justify-center items-center'>
        <img src={Selected} alt='seat type' />
        <p className='font325 text-xxs'>
          {screen === "desktop" ? "Selected Seat" : "Selected"}{" "}
        </p>
      </div>
      <div className='flex flex-col justify-center items-center'>
        <img src={Front} alt='seat type' />
        <p className='font325 text-xxs'>
          {screen === "desktop" ? "Front Seat" : "Front"}{" "}
        </p>
      </div>
      <div className='flex flex-col justify-center items-center'>
        <img src={Back} alt='seat type' />
        <p className='font325 text-xxs'>
          {screen === "desktop" ? "Back Seat" : "Back"}{" "}
        </p>
      </div>
      <div className='flex flex-col justify-center items-center'>
        <img src={Unavailable} alt='seat type' />
        <p className='font325 text-xxs'>
          {screen === "desktop" ? "Unavailable" : "Unavailable"}{" "}
        </p>
      </div>
    </div>
  );
};
