import React from "react";
import CommonSignUp from "../Common/SignUp";
import "../signupForm.scss";

const SignUp = ({
  userData,
  setUserData,
  sendOtp,
  verifyOtpHandler,
  otp,
  setOtp,
  showSignUpOtp,
  setShowSignUpOtp,
  countryCodeList,
}) => {
  return (
    <div className='py-4'>
      <CommonSignUp
        sendOtp={sendOtp}
        userData={userData}
        setUserData={setUserData}
        verifyOtpHandler={verifyOtpHandler}
        otp={otp}
        setOtp={setOtp}
        showSignUpOtp={showSignUpOtp}
        setShowSignUpOtp={setShowSignUpOtp}
        countryCodeList={countryCodeList}
      />
    </div>
  );
};

export default SignUp;
