import Axios from "axios";
import { LOGIN_ENDPOINTS } from "./endpoints";
import {
  getLocalAccessToken,
  getLocalRefreshToken,
  getUser,
  removeUser,
  setUser,
} from "../utils/token.service";

const {
  REFRESH_TOKEN,
  GOOGLE,
  OTP_RESEND,
  OTP_VERIFY,
  OTP_LOGIN,
  OTP_REGISTER,
  FACEBOOK,
} = LOGIN_ENDPOINTS;

const axiosInstance = (() => {
  const headers = {
    "Content-Type": "application/json",
    mode: "no-cors",
  };

  return Axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: headers,
  });
})();

axiosInstance.interceptors.request.use(
  function (config) {
    const accessToken = getLocalAccessToken();
    if (accessToken) {
      config.headers.authorization = `bearer ${accessToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    if (
      !(
        originalConfig.url === REFRESH_TOKEN ||
        originalConfig.url === OTP_REGISTER ||
        originalConfig.url === OTP_LOGIN ||
        originalConfig.url === OTP_VERIFY ||
        originalConfig.url === OTP_RESEND ||
        originalConfig.url === GOOGLE ||
        originalConfig.url === FACEBOOK
      ) &&
      error.response
    ) {
      if (
        error?.response?.status === 401 &&
        !originalConfig?._retry &&
        getLocalRefreshToken()
      ) {
        originalConfig._retry = true;

        try {
          const rs = await axiosInstance.post(REFRESH_TOKEN, {
            refreshToken: getLocalRefreshToken(),
          });

          const user = getUser();
          const { tokens, ...other } = user;

          setUser({ tokens: rs?.data, ...other });

          return axiosInstance(originalConfig);
        } catch (_error) {
          removeUser();
          return Promise.reject(_error);
        }
      }
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
