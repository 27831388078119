import React from "react";
import { FAQ } from "../components/FAQ/faq";

export const FAQView = () => {

  return (
    <div>
      <FAQ />
    </div>
  );
};
