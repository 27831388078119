import React from "react";
import { useSelector } from "react-redux";
import { useScrollTop } from "../../../customHooks/useScrollTop";
import { CheckInStepOne } from "./CheckInStepOne";
import { CheckInStepThree } from "./CheckInStepThree";
import { CheckInStepTwo } from "./CheckInStepTwo";
import "./webCheckInDesk.scss";

export const CheckInStepper = () => {
  useScrollTop();
  const {
    checkIn: { checkInStep },
  } = useSelector((state) => state);

  return (
    <>
      {checkInStep === 3 ? (
        <CheckInStepThree />
      ) : checkInStep === 2 ? (
        <CheckInStepTwo />
      ) : (
        <CheckInStepOne />
      )}
    </>
  );
};
