import { cartActionTypes } from "./cart.actionType";

const initialState = {
  cartData: {
    arrival: {},
    departure: {},
  },
  searchFare: {},
  ancillaryStep: 0,
  loader: false,
  seatData: [],
  ancillaryData: [],
};

const {
  SAVE_CART,
  SEARCH_FARE,
  LOADER,
  ANCILLARY_STEP,
  SEAT_DATA,
  ANCILLARY_DATA,
} = cartActionTypes;

export const reducer = (state = initialState, action) => {
  switch (action?.type) {
    case SEARCH_FARE:
      return {
        ...state,
        searchFare: JSON.parse(JSON.stringify(action?.payload)),
      };
    case SAVE_CART:
      return {
        ...state,
        cartData: JSON.parse(JSON.stringify(action?.payload)),
      };
    case LOADER:
      return {
        ...state,
        loader: action?.payload,
      };
    case ANCILLARY_STEP:
      return { ...state, ancillaryStep: action?.payload };
    case ANCILLARY_DATA:
      return { ...state, ancillaryData: action?.payload };
    case SEAT_DATA:
      return { ...state, seatData: action?.payload };
    default:
      return state;
  }
};
