import React, { useEffect, useState } from "react";
import { bookingDetailsApi } from "../../Api/Booking";
import { useUrlExtract, useWindowSize } from "../../utils/utils";
import ConfirmationPageDesk from "./Desktop/ConfirmationPageDesk";
import BookingConfirmation from "./Mobile";
import { Loader } from "../MobileLoader/index";
import { useDispatch, useSelector } from "react-redux";
import { toastHandler } from "../../Redux/userReducer/user.action";

export const BookingDetails = () => {
  const [innerWidth] = useWindowSize();
  const isMobile = innerWidth < 640 && innerWidth !== 0;

  const [bookingDetails, setBookingDetails] = useState("");
  const [loader, setLoader] = useState(false);
  const { bookingId, success } = useUrlExtract("bookingId");

  const dispatch = useDispatch();
  const {
    user: { openToast },
  } = useSelector((state) => state);

  useEffect(() => {
    setLoader(true);
    bookingDetailsApi(bookingId)
      .then(({ data }) => {
        setBookingDetails(data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
      });
  }, [bookingId]);

  if (loader) {
    return <Loader />;
  }
  return (
    <div>
      {isMobile ? (
        <BookingConfirmation data={bookingDetails} success={success} />
      ) : (
        <ConfirmationPageDesk bookingDetails={bookingDetails} />
      )}
    </div>
  );
};
