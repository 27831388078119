import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import React from "react";
import parse from "html-react-parser";
import "./questionAnswer.scss";

const QuestionAnswer = ({ qnaData }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {qnaData?.map((item, index) => {
        return (
          <Accordion
            className='accordianContainer'
            expanded={expanded === index + 1}
            onChange={handleChange(index + 1)}
          >
            <AccordionSummary
              expandIcon={<CustomExpandIcon />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <p className='summary'>{parse(item?.question)}</p>
            </AccordionSummary>
            <AccordionDetails>
              <p className='description'>{parse(item?.answer)}</p>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default QuestionAnswer;

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none",
        },
        ".expandIconWrapper": {
          display: "none",
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block",
        },
      }}
    >
      <div className='accordianWrapper expandIconWrapper'>-</div>
      <div className='accordianWrapper collapsIconWrapper'>+</div>
    </Box>
  );
};
