import React from "react";
import { useNavigate } from "react-router-dom";
import { getFormattedDate } from "../../../../utils/utils";
import { FlightType } from "../../../FlightType";

export const BookingListItem = ({ data }) => {
  const navigate = useNavigate();

  const handleShowBooking = () => {
    navigate(`/my-bookings-itinerary?bookingId=${data?.id}`);
  };

  return (
    <div className='border flex flex-col justify-between w-full h-30'>
      <p className='flex justify-between items-center bg-gray100 p-1 h-10'>
        <span className='text-green500 text-sm'>PNR : {data?.bookingId} </span>
        <span className='text-xs gotham-book'>
          {getFormattedDate(data?.departureDate, "DD MMM (ddd)")}
        </span>
      </p>
      <section className='flex justify-between items-center p-4'>
        <span className='bg-black text-white p-1'>{data?.origin}</span>
        <div className='w-full px-4'>
          <FlightType type={"One Stop"} noOfStops={0} />
        </div>
        <span className='bg-black text-white p-1'>{data?.destination}</span>
      </section>
      <p
        className='text-right bg-gray100 p-1 h-8 text-link500 text-xs'
        onClick={handleShowBooking}
      >
        SHOW DETAILS
      </p>
    </div>
  );
};
