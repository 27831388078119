import { Grid, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import OtpInput from "react-otp-input";
import { colorPalette } from "../../../../theme/color-palette";
import { emailValidate, SALUTATION_ADULT } from "../../../../utils/constant";
import { Button } from "../../../common/Button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { toastHandler } from "../../../../Redux/userReducer/user.action";

const CommonSignUp = ({
  userData,
  setUserData,
  otp,
  setOtp,
  verifyOtpHandler,
  sendOtp,
  showSignUpOtp,
  setShowSignUpOtp,
  countryCodeList,
  type,
  resendOtpHandler,
  registerOtpHandler,
}) => {
  const { white500, primary500 } = colorPalette;
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [timer, setTimer] = useState(120000);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const dispatch = useDispatch();
  const {
    user: { openToast },
  } = useSelector((state) => state);

  useEffect(() => {
    if (userData?.countryCode === "91") {
      userData?.mobileNumber?.length >= 10
        ? setIsValidPhone(true)
        : setIsValidPhone(false);
    } else {
      setIsValidPhone(true);
    }
  }, [userData]);

  const handleSendOtp = () => {
    const name = [userData.firstName, userData.lastName].join(" ").trim();

    if (name.length > 30) {
      dispatch(
        toastHandler({
          ...openToast,
          open: true,
          message:
            "The first name and last name combined cannot exceed 30 characters.",
          severity: "error",
        })
      );
    } else {
      sendOtp();
    }
  };

  return (
    <div className="px-4">
      <section>
        <Grid container spacing={2}>
          <Grid item lg={3} xs={4} sm={3} className="mb-4">
            <p className="text-xs font-medium mb-1">Title</p>
            <TextField
              select
              type="text"
              size={"small"}
              value={userData?.title}
              onChange={(e) => {
                setUserData((prev) => ({
                  ...prev,
                  title: e.target.value,
                }));
              }}
              variant="outlined"
              className="w-full"
            >
              {SALUTATION_ADULT?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid className="mb-4" item lg={9} xs={8} sm={9}>
            <p className="text-xs font-medium mb-1">First Name</p>
            <TextField
              type="text"
              size={"small"}
              value={userData?.firstName}
              onChange={(e) => {
                setUserData((prev) => ({
                  ...prev,
                  firstName: e.target.value,
                }));
              }}
              variant="outlined"
              className="w-full"
              placeholder="Enter your first name"
            />
          </Grid>
          <Grid className="mb-4" item lg={12} xs={12} sm={12}>
            <p className="text-xs font-medium mb-1">Last Name</p>
            <TextField
              type="text"
              size={"small"}
              value={userData?.lastName}
              onChange={(e) => {
                setUserData((prev) => ({
                  ...prev,
                  lastName: e.target.value,
                }));
              }}
              variant="outlined"
              className="w-full"
              placeholder="Enter your last name"
            />
          </Grid>
          <Grid className="mb-4" item lg={12} xs={12} sm={12}>
            <p className="text-xs font-medium mb-1">Email*</p>
            <TextField
              type="email"
              size={"small"}
              value={userData?.email}
              onChange={(e) => {
                setUserData((prev) => ({
                  ...prev,
                  email: e.target.value,
                }));
                setIsValidEmail(emailValidate.test(e.target.value));
              }}
              error={userData?.email && !isValidEmail}
              helperText={
                userData?.email && !isValidEmail ? "Please check email id" : ""
              }
              variant="outlined"
              className="w-full"
              placeholder="Enter your email"
            ></TextField>
          </Grid>

          <Grid item lg={12} xs={12} sm={12} className="mb-2 w-full">
            <p className="text-xs font-medium mb-1">Mobile Number</p>
            <PhoneInput
              country={"in"}
              value={userData?.countryCode}
              inputProps={{
                required: true,
                autoFocus: true,
              }}
              countryCodeEditable={false}
              onChange={(value, data, event, formattedValue) => {
                setShowSignUpOtp(false);
                setUserData((prev) => {
                  return {
                    ...prev,
                    countryCode: data?.dialCode,
                    mobileNumber: value?.slice(data?.dialCode?.length),
                  };
                });
              }}
            />
          </Grid>

          <Grid className="mb-4" item lg={12} xs={12} sm={12}>
            {showSignUpOtp && (
              <p className="mb-1 text-xs font-medium">OTP Code</p>
            )}
            <div className="flex items-center">
              {showSignUpOtp && (
                <div className="fle flex-col">
                  <OtpInput
                    value={otp}
                    className="otp-input"
                    onChange={(otp) => setOtp(otp)}
                    numInputs={6}
                    separator={<span className="ml-2"></span>}
                  />
                  <Countdown date={Date.now() + timer} renderer={renderer} />
                </div>
              )}

              <div className={`${showSignUpOtp && "ml-3"}`}>
                {!showSignUpOtp && (
                  <Button
                    label="Send OTP"
                    color={white500}
                    bgColor={primary500}
                    size={"large"}
                    variant={"contained"}
                    disabled={!isValidPhone}
                    width={"100%"}
                    onClickHandler={handleSendOtp}
                  />
                )}
              </div>
            </div>
          </Grid>

          <Grid item lg={12} xs={12} sm={12}>
            {showSignUpOtp && (
              <p className="mb-1">
                Didn't receive OTP?{" "}
                <span
                  onClick={() => {
                    setTimer(timer + 1);
                    resendOtpHandler("signup");
                  }}
                  className="text-link500"
                >
                  Resend OTP
                </span>
              </p>
            )}
            <Button
              label="SignUp"
              color={white500}
              bgColor={primary500}
              size={"medium"}
              variant={"contained"}
              width={"100%"}
              height={"4rem"}
              disabled={!showSignUpOtp}
              onClickHandler={verifyOtpHandler}
            />
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default CommonSignUp;

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return "";
  } else {
    // Render a countdown
    return (
      <p className="mt-2">
        You can resend OTP in {minutes}:{seconds} minutes
      </p>
    );
  }
};
