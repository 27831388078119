import { Box, Modal } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { colorPalette } from "../../../../theme/color-palette";
import { modalStyle, useUrlExtract } from "../../../../utils/utils";
import { Button } from "../../../common/Button";

const PostCancelModal = ({ handleClosePostCancelModal, postCancelModal }) => {
  const navigate = useNavigate();
  const { cancelled } = useUrlExtract("all");
  const {
    user: { cancelledBooking },
  } = useSelector((state) => state);
  const { primary500, white500 } = colorPalette;

  return (
    <Modal open={postCancelModal} onClose={handleClosePostCancelModal}>
      <Box
        sx={modalStyle(350, 4)}
        className='flex flex-col items-between justify-center text-center'
      >
        <>
          <div
            className={`${
              cancelled === "true" ? "bg-green200" : "bg-red100"
            } p-4 border-rounded`}
          >
            {cancelled === "true" ? (
              <>
                <p className='text-sm mb-1'>Your booking has been cancelled.</p>
                <p className='text-xs gotham-book'>
                  The refund amount of ₹ {cancelledBooking?.data?.refundAmount}{" "}
                  will be credited into your source payment method in 5-7
                  working days
                </p>
              </>
            ) : (
              <p className='text-sm'>
                There was a problem, <br /> please try after some time{" "}
              </p>
            )}
          </div>
          <div className='mt-6'>
            <Button
              label={"go to home"}
              bgColor={primary500}
              color={white500}
              width={"100%"}
              onClickHandler={() => navigate("/")}
            />
          </div>
        </>
      </Box>
    </Modal>
  );
};

export default PostCancelModal;
