import axiosInstance from "../axiosInstance";
import { BOOKING_ENDPOINTS, SEARCH_ENDPOINTS } from "../endpoints";

const {
  ROUTE,
  FARE,
  POPULAR_ROUTES,
  FARE_DETAILS,
  FARE_CALENDER,
  UI_CONTENT,
  UI_SECTIONS,
} = SEARCH_ENDPOINTS;
const { BOOKING } = BOOKING_ENDPOINTS;

export const searchResults = () => axiosInstance.get(ROUTE);

export const searchFare = (body) => axiosInstance.post(FARE, body);

export const searchPopularRoutes = () => axiosInstance.get(POPULAR_ROUTES);

export const searchfareCalender = (origin, destination) =>
  axiosInstance.get(FARE_CALENDER + origin + "&destination=" + destination);

export const searchFareDetails = (fareType) =>
  axiosInstance.get(FARE_DETAILS + fareType);

export const searchUiContent = () => axiosInstance.get(UI_CONTENT);

export const searchUiSections = () =>
  axiosInstance.get(`${UI_CONTENT}${UI_SECTIONS}`);

export const searchUiSectionsSorted = (title) =>
  axiosInstance.get(`${UI_CONTENT}${UI_SECTIONS}?title=${title}`);

export const searchModifiedFareDetails = (bookingId, body) =>
  axiosInstance.post(BOOKING + "/" + bookingId + "/fares", body);
