import React, { useEffect, useState } from "react";
import { colorPalette } from "../../../../theme/color-palette";
import { Navbar } from "../../../Navbar/Mobile";
import styled from "styled-components";
import { Grid, Container } from "@mui/material";
import {
  DATE_FORMAT,
  MOBILE_SEARCH_TAB,
  ROUNDTRIP,
  ONE_WAY,
} from "../../../../utils/constant";
import CalenderScroll from "../CalenderScroll";
import { EditSearch } from "../EditSearch";
import { Card } from "../../../MobileCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormattedDate,
  itineraryUrl,
  useUrlExtract,
} from "../../../../utils/utils";
import { CustomDrawer } from "../../../common/Drawer";
import { Search } from "../../../homepage/Mobile/Search";
import { searchDetailsHandler } from "../../../../Redux/searchReducer/search.action";
import { useNavigate } from "react-router-dom";
import { selectedFlightHandler } from "../../../../Redux/CartReducer/cart.action";
import moment from "moment";
import NoResults from "../../../NoResults";
import { Button } from "../../../common/Button";

const { black500, white500, primary500, gray600, black400 } = colorPalette;

export const FlightOptions = ({
  setStep,
  selectionStep,
  setSelectionStep,
  flightFareList,
  step,
  total,
  selectedIndex,
  setSelectedIndex,
}) => {
  const [flightDeparture, setFlightDeparture] = useState();
  const [flightArrival, setFlightArrival] = useState();
  const [departureDate, setDepartureDate] = useState();
  const [editDrawer, setEditDrawer] = useState({ top: false });
  const [selected, setSelected] = useState("Book");
  const [flightListState, setFlightListState] = useState([]);
  const [activeInput, setActiveInput] = useState(0);
  const [activeDate, setActiveDate] = useState(0);

  const {
    search: {
      flightList,
      searchDetails: {
        departure,
        arrival,
        attendantType: attendantTypeState,
        passengerNumbers: passengerNumbersState,
        tripType: tripTypeState,
        date: flightDate,
      },
    },
    cart: { cartData },
    search: { searchDetails },
  } = useSelector((state) => state);

  const [tripType, setTripType] = useState(tripTypeState);
  const [departureLocation, setDepartureLocation] = useState(departure);
  const [arrivalLocation, setArrivalLocation] = useState(arrival);
  const [attendantType, setAttendantType] = useState(attendantTypeState);
  const [passengerNumbers, setPassengerNumbers] = useState({
    ...passengerNumbersState,
  });
  const [date, setDate] = useState(flightDate);
  const [dateClone] = useState(flightDate);

  useEffect(() => {
    const { from, enteredTo, to } = date;
    if (tripType === "Round Trip" && from) {
      setDate({
        from: new Date(from),
        enteredTo: new Date(moment(from).add(1, "days").toDate()),
        to: new Date(moment(from).add(1, "days").toDate()),
      });
    } else {
      setDate({
        from: new Date(from),
        to: new Date(to),
        enteredTo: new Date(enteredTo),
      });
    }
  }, [tripType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const { arrival, departure, date } = searchDetails;
    const { enteredTo, from } = date;
    if (selectionStep === 1) {
      setFlightDeparture(departure);
      setFlightArrival(arrival);
      setDepartureDate(getFormattedDate(from, DATE3));
    } else {
      setFlightDeparture(arrival);
      setFlightArrival(departure);
      setDepartureDate(getFormattedDate(enteredTo, DATE3));
      setSelectedIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionStep, searchDetails]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { DATE3 } = DATE_FORMAT;
  const { bookingType } = useUrlExtract("all");

  const onClickHandler = (val, flightType) => {
    setStep(2);
    if (selectionStep === 1) {
      dispatch(
        selectedFlightHandler({
          ...cartData,
          departure: { ...val, flightType: flightType },
        })
      );
    } else {
      dispatch(
        selectedFlightHandler({
          ...cartData,
          arrival: { ...val, flightType: flightType },
        })
      );
    }
  };

  const submitDataHandler = (scrollDate) => {
    const { adult, children, infant } = passengerNumbers;
    const { from, to, enteredTo } = date;
    dispatch(
      searchDetailsHandler({
        departure: departureLocation,
        arrival: arrivalLocation,
        attendantType: attendantType,
        passengerNumbers: passengerNumbers,
        tripType: tripType,
        date: scrollDate?.from ? scrollDate : date,
      })
    );
    let urlItinerary = itineraryUrl(tripType, {
      departureLocation: departureLocation?.code,
      arrivalLocation: arrivalLocation.code,
      arrivalDate: scrollDate?.enteredTo || enteredTo,
      departureDate: scrollDate?.from || from,
    });

    navigate(
      `/search-result?tripType=${tripType[0]}&itinerary=${urlItinerary}&paxType=A-${adult}_C-${children}_I-${infant}&atdType=${attendantType}`
    );
    setEditDrawer({ top: false });
  };

  const routingHandler = () => {
    if (step === 2) {
      setStep(1);
    } else if (selectionStep === 2) {
      setSelectionStep(1);
    } else if (selectionStep === 1) {
      navigate(-1);
    }
  };

  return (
    <div>
      <Navbar
        color={white500}
        bgColor={primary500}
        label={"Search Results"}
        showMenu={false}
        onClose={routingHandler}
      />
      <div>
        <Grid container>
          {tripType === ROUNDTRIP
            ? MOBILE_SEARCH_TAB?.map((item, index) => (
                <Tab
                  key={index}
                  onClick={() => {
                    if (cartData?.departure?.id) {
                      setSelectionStep(index + 1);
                    }
                  }}
                  item
                  selected={index + 1 === selectionStep}
                  xs={6}
                >
                  {item}
                </Tab>
              ))
            : ""}
        </Grid>
      </div>
      {selectionStep === 2 && (
        <div className='h-8 bg-primary200 '>
          <Grid container className='h-full'>
            <Grid xs={2} className='h-full'>
              <p className='h-full border-left-green500 flex items-center pl-1 text-xxs'>
                Departure
              </p>
            </Grid>
            <Grid xs={4} className='h-full'>
              <div className='flex flex-col items-center justify-center h-full'>
                <p className='text-xxs'>
                  {cartData?.departure?.route?.[0]} -{" "}
                  {
                    cartData?.departure?.route?.[
                      cartData?.departure?.route?.length - 1
                    ]
                  }
                </p>
                <p className='text-xxs'>
                  {cartData?.departure?.departureTime} -{" "}
                  {cartData?.departure?.arrivalTime}
                </p>
              </div>
            </Grid>
            <Grid xs={3}>
              <div className='flex flex-col items-center justify-center h-full'>
                <p className='text-xxs'>{cartData?.departure?.duration}</p>
                <p className='text-xxs'>{cartData?.departure?.flightType}</p>
              </div>
            </Grid>
            <Grid xs={3}>
              <p className='flex items-center pl-1 text-xxs h-full justify-end mr-5'>
                ₹ {total?.total?.total}
              </p>
            </Grid>
          </Grid>
        </div>
      )}
      <div>
        {tripType === ONE_WAY && bookingType !== "modify" ? (
          <CalenderScroll
            date={date}
            tripType={tripTypeState}
            setDate={setDate}
            selectionStep={selectionStep}
            submitDataHandler={submitDataHandler}
            dateClone={dateClone}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        ) : (
          ""
        )}
      </div>
      <div className='bg-gray100 pt-4 h-full'>
        <Container>
          <EditSearch
            flightDeparture={flightDeparture}
            flightArrival={flightArrival}
            passengerNumbers={searchDetails?.passengerNumbers}
            departureDate={departureDate}
            setEditDrawer={setEditDrawer}
          />
          <div>
            {Object.keys(flightFareList)?.map((type) =>
              flightFareList[type]?.map((item) => (
                <Card item={item} type={type} onClickHandler={onClickHandler} />
              ))
            )}
          </div>
        </Container>
        <div className='mt-20'>
          {!flightFareList?.direct?.length &&
          !flightFareList?.connecting?.length ? (
            <>
              <NoResults />
              <div className='mt-4 mx-2'>
                <Button
                  label={"Modify flights"}
                  color={white500}
                  bgColor={primary500}
                  size={"medium"}
                  variant={"contained"}
                  width={"100%"}
                  onClickHandler={() => setEditDrawer({ top: true })}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <CustomDrawer
        position={"top"}
        state={editDrawer}
        setState={(val) => setEditDrawer(val)}
        label={""}
      >
        <Search
          selected={selected}
          setSelected={setSelected}
          tripType={tripType}
          setTripType={setTripType}
          departureLocation={departureLocation}
          setDepartureLocation={setDepartureLocation}
          arrivalLocation={arrivalLocation}
          setArrivalLocation={setArrivalLocation}
          attendantType={attendantType}
          setAttendantType={setAttendantType}
          date={date}
          setDate={setDate}
          flightListState={flightListState}
          setFlightListState={setFlightListState}
          activeInput={activeInput}
          setActiveInput={setActiveInput}
          flightList={flightList}
          activeDate={activeDate}
          setActiveDate={setActiveDate}
          passengerNumbers={passengerNumbers}
          setPassengerNumbers={setPassengerNumbers}
          searchDataHandler={submitDataHandler}
        />
      </CustomDrawer>
    </div>
  );
};

const Tab = styled(Grid)`
  color: ${({ selected }) => (selected ? black500 : gray600)};
  text-align: center;
  font-size: 14px;
  border-bottom: ${({ selected }) =>
    selected ? "3px solid" + primary500 : "1px solid" + black400};
  padding: 14px 0px;
  &:nth-child(2) {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
`;
