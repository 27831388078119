import React from 'react';

export const SelectedSeat = ({ flightSvg, origin, destination, data }) => {
  return (
    <>
      <p className='selected-seat-heading'>
        <span>
          <img src={flightSvg} alt='flight svg' />
        </span>
        <span className='text-sm font-normal ml-2'>
          {origin} to {destination}
        </span>
      </p>
      {data?.map((item, index) => {
        return (
          <section className='user-selecting-seat p-4' key={index}>
            <p className='font350 text-sm gotham-book'>Guest {index + 1}</p>
            <p className='font350 text-base mt-2'>
              {item?.firstName} {item?.lastName}
            </p>
            {/* <div className='uss-info-tab flex justify-around items-center mt-3'>
              <span className='text-white'>{item?.seat}</span>
              <span className='text-white'>x</span>
            </div> */}
            {/* <p className='mt-3 text-sm font325 gotham-book'>Select Your Seat</p> */}
          </section>
        );
      })}
    </>
  );
};
