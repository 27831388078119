import React, { useEffect, useState } from 'react';
import { Box, InputLabel, Grid, Popover } from '@mui/material';
import arrowSvg from '../../../../../assets/svgs/arrows.svg';
import './flightBookingSection.scss';
import { RadioBtnGrp } from '../../../../common/radio-buttons/RadioBtnGrp';
import { AutocompleteComp } from '../../../../common/autocompleteComp/AutocompleteComp';
import flightUpSvg from '../../../../../assets/svgs/flight-up.svg';
import flightDownSvg from '../../../../../assets/svgs/flight-down.svg';
import {
  ATTENDANT_TYPE_TOOLTIP,
  ROUNDTRIP,
  TRIP_TYPE,
} from '../../../../../utils/constant';
import { SelectPassenger } from '../../../Common/Select-Passenger';
import {
  getFormattedDate,
  handleSwitchCities,
} from '../../../../../utils/utils';
import calenderSvg from '../../../../../assets/svgs/calenderSvg.svg';
import { DateRange } from '../../../../common/DateRange';
import { DateUtils } from 'react-day-picker';
import { useSelector } from 'react-redux';
import { Button } from '../../../../common/Button';
import { colorPalette } from '../../../../../theme/color-palette';
import moment from 'moment';
import { CheckInBox } from '../../../../common/checkIn/CheckInBox';
import RoundtripTooltip from '../../../../common/RoundtripTooltip';
import { searchfareCalender } from '../../../../../Api/search';

let dateInitialState = {
  from: null,
  to: null,
  enteredTo: null,
};

export const FlightBookingSection = React.memo(
  ({
    tabValue,
    departureCityValue,
    setDepartureCityValue,
    arrivalCityValue,
    setArrivalCityValue,
    changeDepartureCity,
    changeArrivalCity,
    // passengerNumbers,
    // setPassengerNumbers,
    date,
    setDate,
    anchorElCalender,
    setAnchorElCalender,
    tripType,
    setTripType,
    submitDataHandler,
    attendantType,
    setAttendantType,
    inputRefDepart,
    inputRefReturn,
  }) => {
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const [fareCalender, setFareCalender] = useState({});

    useEffect(() => {
      inputRefReturn.current.click();
    }, [shouldUpdate]);

    //fetching passenger numbers
    const { searchDetails } = useSelector((state) => state.search);

    const { from, to } = date;

    const { primary500, white500 } = colorPalette;

    useEffect(() => {
      const { from, enteredTo, to } = date;
      setDate({
        from: new Date(from),
        to: new Date(to),
        enteredTo: new Date(enteredTo),
      });
    }, []);

    useEffect(() => {
      departureCityValue?.code &&
        arrivalCityValue?.code &&
        searchfareCalender(
          departureCityValue?.code,
          arrivalCityValue?.code
        ).then(({ data }) => {
          setFareCalender(data?.prices);
        });
    }, [departureCityValue, arrivalCityValue]);

    function TabPanel(props) {
      const { children, value, index } = props;

      return (
        <div
          role='tabpanel'
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <>{children}</>
            </Box>
          )}
        </div>
      );
    }

    let datePickerBoxClass =
      tripType === 'Round Trip'
        ? 'return-date-select'
        : 'return-date-select-disabled return-date-select';

    const navigateToResultPage = () => {
      submitDataHandler();
    };

    //popover//////////////////////////////
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const customId = open ? 'simple-popover-passenger' : undefined;
    ////////////////////////////////////////////////

    //day picker functions
    const handleCalenderViewDepart = (event) => {
      setAnchorElCalender(event.currentTarget);
      tripType === ROUNDTRIP
        ? setDate({ from: date?.from, to: date?.to, enteredTo: null })
        : setDate({ ...dateInitialState, from });
    };
    const [anchorElCalenderReturn, setAnchorElCalenderReturn] = useState(null);
    const handleCalenderViewReturn = (event) => {
      setTripType('Round Trip');
      setAnchorElCalenderReturn(event.currentTarget);
      setDate({ from: date?.from, to: date?.to, dateInitialState });
    };
    const handleCloseDepartCalender = () => {
      setAnchorElCalender(null);
    };
    const handleCloseReturnCalender = () => {
      setAnchorElCalenderReturn(null);
    };

    const openDepartCalender = Boolean(anchorElCalender);
    const openReturnCalender = Boolean(anchorElCalenderReturn);

    const {
      search: { flightList },
    } = useSelector((state) => state);

    const openDropdownCitiesDepart = () => {
      inputRefDepart.current.click();
    };

    const openDropdownCitiesReturn = () => {
      inputRefReturn.current.click();
    };

    const disableSearchFlight =
      !departureCityValue ||
      !arrivalCityValue ||
      !date?.from ||
      (tripType === ROUNDTRIP && !date?.to)
        ? true
        : false;

    const singleDateHandlerDepart = (day, modifiers = {}) => {
      if (modifiers.disabled) {
        return;
      }
      setDate(() => ({
        ...date,
        from: day,
        to: day > to ? new Date(moment(day).add(1, 'days').toDate()) : to,
      }));
      setAnchorElCalender(null);
    };

    const singleDateHandlerReturn = (day, modifiers = {}) => {
      if (modifiers.disabled) {
        return;
      }
      setDate({
        ...date,
        to: day,
      });
      setAnchorElCalenderReturn(null);
    };

    return (
      <main className='flight-booking-tabs'>
        <TabPanel value={tabValue} index={0}>
          <Grid container className='relative'>
            <Grid item lg={3} md={3}>
              <RadioBtnGrp
                value={tripType}
                handleChange={setTripType}
                inputArray={TRIP_TYPE}
                isRow={true}
              />
            </Grid>
            {tripType !== ROUNDTRIP && (
              <Grid item lg={9} md={9}>
                {/* <RoundtripTooltip /> */}
              </Grid>
            )}
          </Grid>
          <Grid container className='selections justify-between mt-1'>
            <Grid item lg={6.8} md={12} sm={12} className='locations'>
              <AutocompleteComp
                type='FROM'
                id='auto-complete-departure'
                img={flightUpSvg}
                customClass='departure'
                cityValue={departureCityValue}
                changeCityValue={changeDepartureCity}
                optionData={flightList}
                inputRef={inputRefDepart}
                openDropdownCities={openDropdownCitiesDepart}
                setShouldUpdate={setShouldUpdate}
              />

              {/*arrival <-> departure Switch Btn*/}
              <span
                className='arrow-svg cursor-pointer'
                onClick={() =>
                  handleSwitchCities(
                    flightList,
                    arrivalCityValue,
                    departureCityValue,
                    setDepartureCityValue,
                    setArrivalCityValue
                  )
                }
              >
                <img src={arrowSvg} alt='arrow-svg' />
              </span>

              <AutocompleteComp
                type='TO'
                id='auto-complete-arrival'
                img={flightDownSvg}
                customClass='arrival'
                cityValue={arrivalCityValue}
                changeCityValue={changeArrivalCity}
                optionData={departureCityValue?.destinations}
                inputRef={inputRefReturn}
                openDropdownCities={openDropdownCitiesReturn}
              />
            </Grid>
            <Grid
              item
              lg={3}
              md={7.8}
              sm={7.8}
              className='depart-return-date-select'
            >
              <div className='depart-date-select'>
                <InputLabel htmlFor='calender-select-depart'>
                  <span>DEPARTURE</span>
                </InputLabel>

                <div
                  className='date-default cursor-pointer'
                  onClick={handleCalenderViewDepart}
                >
                  <div className='flex justify-between items-center'>
                    <p>
                      <span className='text-base font-normal mr-6 inline-block w-full'>
                        {getFormattedDate(from, 'ddd, DD')}
                      </span>{' '}
                      <br />
                      <span className='text-base font-normal mr-6'>
                        {getFormattedDate(from, 'MMM')}
                      </span>
                    </p>
                    <span>
                      <img src={calenderSvg} alt='calender svg' />
                    </span>
                  </div>
                  <p className='text-xxs font-normal mt-2'>
                    {getFormattedDate(from, 'YYYY')}
                  </p>
                </div>
                <Popover
                  id='simple-popover-calender-depart'
                  open={openDepartCalender}
                  anchorEl={anchorElCalender}
                  onClose={handleCloseDepartCalender}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  PaperProps={{
                    style: {
                      minWidth: '23rem',
                      minHeight: '21rem',
                      padding: '10px',
                    },
                  }}
                  className='custom-popover-depart'
                >
                  <DateRange
                    state={date}
                    setState={setDate}
                    handleDayClick={singleDateHandlerDepart}
                    numberOfMonths={tripType === ROUNDTRIP ? 2 : 1}
                    canChangeMonth={true}
                    selectedDays={[from]}
                    fareCalender={fareCalender}
                  />
                </Popover>
              </div>

              <div className={datePickerBoxClass}>
                <InputLabel htmlFor='calender-select-return'>
                  <span>RETURN</span>
                </InputLabel>

                <div
                  className='date-default cursor-pointer'
                  onClick={handleCalenderViewReturn}
                >
                  {tripType === 'Round Trip' ? (
                    <>
                      <div className='flex justify-between items-center'>
                        <p>
                          <span className='text-base font-normal mr-6 inline-block w-full'>
                            {getFormattedDate(to, 'ddd, DD')}
                          </span>{' '}
                          <br />
                          <span className='text-base font-normal mr-6'>
                            {getFormattedDate(to, 'MMM')}
                          </span>
                        </p>
                        <span>
                          <img src={calenderSvg} alt='calender svg' />
                        </span>
                      </div>
                      <p className='text-xxs font-normal mt-2'>
                        {getFormattedDate(to, 'YYYY')}
                      </p>
                    </>
                  ) : (
                    <div>
                      Tap to add <br /> a return <br /> date
                    </div>
                  )}
                </div>
                <Popover
                  id='simple-popover-calender-return'
                  open={openReturnCalender}
                  anchorEl={anchorElCalenderReturn}
                  onClose={handleCloseReturnCalender}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  PaperProps={{
                    style: {
                      minWidth: '23rem',
                      minHeight: '20rem',
                      padding: '10px',
                    },
                  }}
                >
                  <DateRange
                    state={date}
                    setState={setDate}
                    handleDayClick={singleDateHandlerReturn}
                    numberOfMonths={tripType === ROUNDTRIP ? 2 : 1}
                    canChangeMonth={true}
                    disabledDays={{ before: from }}
                    selectedDays={[to]}
                    fareCalender={fareCalender}
                  />
                </Popover>
              </div>
            </Grid>

            {/*Guest Section*/}
            <Grid item lg={2} md={4} sm={4} className='passengers'>
              <div className='passenger-input'>
                <div onClick={handleClick} className='cursor-pointer'>
                  <InputLabel htmlFor='passanger-selection-input'>
                    <p>GUEST</p>
                  </InputLabel>
                  <p
                    className={`font350 text-sm ${
                      searchDetails.passengerNumbers.children &&
                      searchDetails.passengerNumbers.infant
                        ? ''
                        : 'mt-2'
                    }`}
                  >
                    <span className='flex justify-start'>
                      <span className='mr-4 block w-1'>
                        {searchDetails.passengerNumbers.adult}{' '}
                      </span>{' '}
                      <span> Adult(s)</span>
                    </span>

                    {searchDetails.passengerNumbers.children > 0 && (
                      <span className='flex justify-start'>
                        <span className='mr-4 block w-1'>
                          {searchDetails.passengerNumbers.children}{' '}
                        </span>{' '}
                        <span> Children(s)</span>
                      </span>
                    )}

                    {searchDetails.passengerNumbers.infant > 0 && (
                      <span className='flex justify-start'>
                        <span className='mr-4 block w-1'>
                          {searchDetails.passengerNumbers.infant}{' '}
                        </span>{' '}
                        <span> Infant(s)</span>
                      </span>
                    )}
                  </p>
                </div>

                <Popover
                  id={customId}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <SelectPassenger
                    buttonWidth={'90px'}
                    handleClose={handleClose}
                    // passengerNumbers={passengerNumbers}
                    // setPassengerNumbers={setPassengerNumbers}
                    usedIn='homepage'
                  />
                </Popover>
              </div>
            </Grid>
          </Grid>

          <div className='flex justify-between items-end w-full mt-4'>
            <RadioBtnGrp
              value={attendantType}
              handleChange={setAttendantType}
              inputArray={ATTENDANT_TYPE_TOOLTIP}
              isRow={true}
            />
            <Button
              label={'SEARCH FLIGHT'}
              bgColor={primary500}
              color={white500}
              onClickHandler={navigateToResultPage}
              disabled={
                disableSearchFlight ||
                searchDetails.passengerNumbers.adult === 0
              }
            />
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <CheckInBox usedIn='checkin' />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <CheckInBox usedIn='manage-booking' />
        </TabPanel>
      </main>
    );
  }
);
