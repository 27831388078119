import { Container, Grid } from "@mui/material";
import React from "react";
import { colorPalette } from "../../theme/color-palette";
import { Button } from "../common/Button";
import drawer from "../../assets/svgs/drawer.svg";

export const MobileAmountFooter = ({
  onClickHandler,
  setCartDetailsDrawer,
  label,
  data,
  usedIn,
  total,
  isDisabled,
}) => {
  const { white500, primary500 } = colorPalette;
  return (
    <div className='shadow-md fixed bottom-0 w-full bg-white py-4 '>
      <Container>
        <Grid container>
          <Grid
            xs={6}
            onClick={() => {
              if (data) setCartDetailsDrawer({ bottom: true });
            }}
          >
            <div className='flex justify-between'>
              <p className='text-base'>
                ₹{" "}
                {data?.total?.total
                  ? data?.total?.total
                  : usedIn === "seat-anc"
                  ? total
                  : "-"}
              </p>
              <img src={drawer} alt='drawer' className='mr-4' />
            </div>
            {usedIn !== "seat-anc" && (
              <p className='text-xxs'>
                Total Fare x{" "}
                {data?.passengers?.noOfPassengers
                  ? data?.passengers?.noOfPassengers
                  : "-"}{" "}
                passengers
              </p>
            )}
          </Grid>
          <Grid xs={6}>
            <div className='px-1'>
              <Button
                label={label}
                color={white500}
                bgColor={primary500}
                size={"medium"}
                variant={"contained"}
                width={"100%"}
                onClickHandler={onClickHandler}
                disabled={isDisabled ? isDisabled : false}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
