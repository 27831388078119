import React, { useEffect, useState } from 'react';
import { ContactDeclarationForm } from './common/ContactDeclarationForm';
import { Button } from '../../common/Button';
import { colorPalette } from '../../../theme/color-palette';
import { useScrollTop } from '../../../customHooks/useScrollTop';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '../../../utils/utils';
import { getProfile } from '../../../Api/Profile';
import { destinationDetailsHandler } from '../../../Redux/CheckInReducer/checkIn.action';
import { PdfModal } from './PdfModal';

export const CheckInStepThree = () => {
  useScrollTop();
  // const [contactDeclarations, setContactDeclarations] = useState([]);
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState({
    readInfo: false,
    readTC: false,
  });
  const [detailsForm, setDetailsForm] = useState({
    phone: {
      countryCode: '91',
      number: '',
    },
    email: '',
    destination: {
      address: '',
      pin: '',
    },
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    checkIn: { checkInData },
  } = useSelector((state) => state);

  const { primary500, white500 } = colorPalette;
  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleAgreeNContinue = () => {
    dispatch(destinationDetailsHandler(detailsForm));
    navigate(`/seatMap?bookingId=${checkInData?.id}`);
  };

  useEffect(() => {
    getProfile().then(({ data }) => {
      (data?.email || data?.phone?.number) &&
        setDetailsForm({
          ...detailsForm,
          phone: data?.phone,
          email: data?.email,
        });
    });
  }, []);

  return (
    <div className='w-full m-auto'>
      <main className='checkIn-container w-max-desk m-auto'>
        <p className={`font350 text-xl mb-4 ${isMobile ? 'p-2' : 'p-6'}`}>
          Contact Declaration
        </p>
        <p
          className={`font325 text-xs gotham-book ${isMobile ? 'p-2' : 'pl-6'}`}
        >
          Add correct information below. Your boarding pass details will be sent
          to these mobile number and email.
        </p>

        <div className={isMobile ? 'bg-gray300 p-2 mt-3' : 'p-6'}>
          <p className='font350 text-base my-4'>Important notice for guests</p>

          <ul className='ml-8 mb-6 '>
            <li className='font325 text-xs mb-1 gotham-book'>
              Only travel with baggage that belongs to you
            </li>
            <li className='font325 text-xs mb-1 gotham-book'>
              Do not accept baggage from other / unknown people
            </li>
            <li className='font325 text-xs mb-1 gotham-book'>
              Do not leave your baggage unattended
            </li>
          </ul>
        </div>

        <p className={`flex items-center mb-4 ${isMobile ? 'mt-4' : 'ml-6'}`}>
          <span className='mr-3'>
            <Checkbox
              size='small'
              checked={isChecked?.readInfo}
              onChange={() => {
                setIsChecked((prev) => ({
                  ...prev,
                  readInfo: !isChecked?.readInfo,
                }));
              }}
              sx={{
                '&.Mui-checked': {
                  color: primary500,
                },
              }}
            />{' '}
          </span>
          <span className='gotham-book'>
            I have read & Understood above restrictions and Information.
          </span>
        </p>
        <p className={`flex items-center mb-4 ${isMobile ? 'mt-4' : 'ml-6'}`}>
          <span className='mr-3'>
            <Checkbox
              size='small'
              checked={isChecked?.readTC}
              onChange={() => {
                setIsChecked((prev) => ({
                  ...prev,
                  readTC: !isChecked?.readTC,
                }));
              }}
              sx={{
                '&.Mui-checked': {
                  color: primary500,
                },
              }}
            />{' '}
          </span>
          <span className='gotham-book'>
            By selecting this, you are confirming that you have read, understood
            and agree to flybig{' '}
            <a
              href='https://www.s9devflg.flybig.in/terms-and-condition'
              className='text-link500 cursor-pointer font-normal'
              target='_blank'
              rel='noreferrer'
            >
              {' '}
              terms and conditions
            </a>
            .
          </span>
        </p>

        <div className='mt-4 p-6'>
          <ContactDeclarationForm
            detailsForm={detailsForm}
            setDetailsForm={setDetailsForm}
          />
        </div>

        <div className='flex justify-end p-6'>
          <Button
            label={'agree & continue'}
            bgColor={primary500}
            color={white500}
            width={isMobile ? '100%' : '16rem'}
            onClickHandler={handleOpenModal}
            disabled={
              !isChecked?.readTC ||
              !isChecked?.readInfo ||
              !detailsForm?.phone?.number ||
              !detailsForm?.email ||
              detailsForm?.destination?.address?.length < 5 ||
              detailsForm?.destination?.pin?.length < 4
            }
          />
        </div>
        <PdfModal
          open={open}
          handleAgreeNContinue={handleAgreeNContinue}
        ></PdfModal>
      </main>
    </div>
  );
};
