import React from "react";
import styled from "styled-components";
import flight from "../../assets/svgs/crossMIniFlight.svg";
import destination from "../../assets/svgs/destination.svg";
import onestop from "../../assets/svgs/onestop.svg";
import "./index.scss";

export const FlightType = ({ type, noOfStops }) => {
  return (
    <div>
      <FlightRoute>
        <img src={flight} alt="flight" className="flight_start right-0" />
        {noOfStops > 0 && (
          <img src={onestop} alt="flight" className="flight_center" />
        )}
        <img src={destination} alt="flight" className="flight_end " />
      </FlightRoute>
    </div>
  );
};

const FlightRoute = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.15);
  width: 100%;
  position: relative;
`;
