export const SEARCH_ENDPOINTS = {
  ROUTE: "/search/routes",
  FARE: "/search/fares",
  POPULAR_ROUTES: "/search/routes/popular",
  FARE_DETAILS: "/search/faresDetails?fareNames=",
  UI_CONTENT: "/ui-content",
  UI_SECTIONS: "/uiSection",
  FARE_CALENDER: "/search/fareCalendar?origin=",
};

export const BOOKING_ENDPOINTS = {
  REVIEW_BOOKING: "/pricing",
  BOOKING: "/booking",
  PAYMENT: "/payment",
  EMAIL: "/email",
  SMS: "/sms",
  SEAT: "/seat",
  ANCILLARY: "/ancillary",
  PNR: "?pnr=",
  LASTNAME: "&lastName=",
  CHECKIN: "/checkin",
  GROUP_BY: "?stage=",
  CANCEL: "/cancel",
  UPDATE: "/update",
};

export const LOGIN_ENDPOINTS = {
  SIGN_UP: "/auth/register",
  SIGN_IN: "/auth/login",
  LOG_OUT: "/auth/logout",
  OTP_REGISTER: "auth/otpregister",
  OTP_LOGIN: "auth/otplogin",
  OTP_VERIFY: "auth/otpverify",
  VERIFY_TOKEN: "auth/verify-token",
  OTP_RESEND: "auth/otpresend",
  GOOGLE: "auth/google",
  FACEBOOK: "auth/facebook",
  REFRESH_TOKEN: "auth/refresh-tokens",
};

export const BOOKING_PROFILE_ENDPOINTS = {
  PROFILE: "/profile",
  PASSENGER: "/passenger",
};
