import { Box, Grid, Modal, TextField } from "@mui/material";
import React, { useState } from "react";
import { colorPalette } from "../../../../../theme/color-palette";
import { modalStyle } from "../../../../../utils/utils";
import { Button } from "../../../../common/Button";
import smsIcon from "../../../../../assets/svgs/smsIcon.svg";
import OtpInput from "react-otp-input";

export const ChangePhone = () => {
  const [phoneState, setPhoneState] = useState("");
  const [isPopup, setIsPopup] = useState(false);
  const [otp, setOtp] = useState("");
  const { primary500, white500, gray600 } = colorPalette;

  const handleCloseModal = () => {
    setIsPopup(false);
  };

  const handleVerifyOtp = () => {};

  return (
    <>
      <div className='border p-6 my-profile-form-div'>
        <p className='font350 text-xl mb-10'>Change Phone</p>

        <Grid container spacing={2}>
          <Grid item lg={10} md={10}>
            <div className='mr-4'>
              <p className='font-medium text-xs mb-2'>Old Phone</p>
              <TextField
                type='tel'
                variant='outlined'
                className='w-full'
                size='small'
                placeholder='Enter your old phone...'
                value={phoneState?.oldPhone}
                onChange={(e) =>
                  setPhoneState({
                    ...phoneState,
                    oldPhone: e.target.value,
                  })
                }
              />
            </div>
          </Grid>
          <Grid item lg={10} md={10}>
            <div className='mr-4'>
              <p className='font-medium text-xs mb-2'>New Phone</p>
              <TextField
                type='tel'
                variant='outlined'
                className='w-full'
                size='small'
                placeholder='Enter your new phone...'
                value={phoneState?.newPhone}
                onChange={(e) =>
                  setPhoneState({
                    ...phoneState,
                    newPassword: e.target.value,
                  })
                }
              />
            </div>
          </Grid>
        </Grid>

        <div className='mt-6'>
          <Button
            label={"change phone"}
            bgColor={primary500}
            color={white500}
            width={"12rem"}
            borderRadius={"4px"}
            onClickHandler={() => setIsPopup(true)}
          />
        </div>
      </div>
      <Modal open={isPopup} onClose={handleCloseModal}>
        <Box
          sx={modalStyle(400)}
          className='flex flex-col items-between justify-center text-center'
        >
          <img
            src={smsIcon}
            alt='sms'
            width={48}
            height={48}
            className='m-auto'
          />
          <p className='font350 text-base text-center mt-4'>
            Please check your email / SMS.
          </p>
          <p className='font325 text-xs text-center mt-4'>
            <span className='gotham-book'>We've sent a code to</span>{" "}
            <span>olivia@untitledui.com</span>
          </p>
          <div className='flex flex-col justify-center items-center my-4'>
            <OtpInput
              value={otp}
              className='otp-input'
              onChange={(otp) => setOtp(otp)}
              numInputs={4}
              separator={<span className='ml-2'></span>}
            />
            <p className='font325 text-xs mt-2'>
              Didn’t get a code?{" "}
              <span className='text-link500 cursor-pointer'>
                Click to resend.
              </span>
            </p>
          </div>
          <div className='flex justify-center mt-4'>
            <div className='mr-4 w-full'>
              <Button
                label={"cancel"}
                bgColor={white500}
                color={gray600}
                width={"100%"}
                variant={"outlined"}
                onClickHandler={handleCloseModal}
              />
            </div>
            <div className='ml-4 w-full'>
              <Button
                label={"verify"}
                bgColor={primary500}
                color={white500}
                width={"100%"}
                onClickHandler={handleVerifyOtp}
                disabled={otp?.length < 4}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
