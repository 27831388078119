export const colorPalette = {
  primary100: "#FFF6FF",
  primary200: "#fff6ff",
  primary500: "#b62079",
  primary900: "#6e1048",
  gray200: "#f2f2f2",
  gray300: "#f1f1f1",
  grey400: "#C3CBCD",
  gray500: "#d2d2d2",
  gray600: "#979797",
  gray700: "#787885",
  gray800: "#545454",
  blue500: "#0b2235",
  blue400: "#00266b",
  orange900: "#FF0000",
  orange500: "#d92d20",
  orange400: "#ff7272",
  grayColor: "#787885",
  link500: " #4285f4",
  grayBorderColor: "#d1d1d1",
  footerBorderColor: "#667085",
  white500: "#fff",
  white87: "rgba(255, 255, 255, 0.87)",
  black500: "#000",
  black400: "#0000001f",
};