import React from "react";
import Marquee from "react-fast-marquee";

const MarqueeAnimation = ({ content }) => {
  return (
    <>
      {content?.length ? (
        <Marquee play={true} pauseOnHover={true} speed={80}>
          {content.map((item) => {
            return (
              <p key={item?.title} className='marquee-text mr-8'>
                {item?.title}
              </p>
            );
          })}
        </Marquee>
      ) : (
        ""
      )}
    </>
  );
};

export default MarqueeAnimation;
