import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  CircularProgress,
  Grid,
  Popover,
  TextField,
} from '@mui/material';
import {
  DATE_FORMAT,
  SALUTATION_ADULT,
  emailValidate,
} from '../../../utils/constant';
import { colorPalette } from '../../../theme/color-palette';
import { RadioBtnGrp } from '../../common/radio-buttons/RadioBtnGrp';
import { Button } from '../../common/Button';
import { Country, State, City } from 'country-state-city';
import { getProfile, patchProfile } from '../../../Api/Profile';
import { getFormattedDate, useWindowSize } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from '../../../Redux/userReducer/user.action';
import { DateRange } from '../../common/DateRange';
import calenderSvg from '../../../assets/svgs/primaryCalenderSvg.svg';
import PhoneInput from 'react-phone-input-2';

export const Profile = () => {
  const [salutaion, setSalutation] = useState(SALUTATION_ADULT?.[0]);
  const [myProfileForm, setMyProfileForm] = useState({
    country: 'India',
  });
  const [isEditable, setIsEditable] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [date, setDate] = useState(new Date());
  const [anchorElDepartDate, setAnchorElDepartDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const {
    user: { openToast },
  } = useSelector((state) => state);
  const { primary500, white500 } = colorPalette;
  const { DATE10 } = DATE_FORMAT;

  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;

  useEffect(() => {
    getProfile()
      .then(({ data }) => {
        setMyProfileForm(data);
        data?.state && setSelectedState({ name: data?.state });
        data?.city && setSelectedCity({ name: data?.city });
        data?.country &&
          setSelectedCountry(
            Country.getAllCountries()?.find(
              (country) => country?.name === data?.country
            )
          );
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: 'error',
          })
        );
      });
  }, []);

  // state city countries
  useEffect(() => {
    const allCountries = Country.getAllCountries();
    allCountries && setAllCountries(allCountries);
  }, []);

  useEffect(() => {
    const allStates = State.getStatesOfCountry(selectedCountry?.isoCode);
    allStates && setAllStates(allStates);
  }, [selectedCountry]);

  useEffect(() => {
    setMyProfileForm({
      ...myProfileForm,
      dateOfBirth: date,
    });
  }, [date]);

  const handleSaveProfile = () => {
    const {
      firstName,
      lastName,
      dateOfBirth,
      address,
      country,
      state,
      city,
      pinCode,
      countryCode,
      email,
      phone,
    } = myProfileForm;
    const body = {
      firstName,
      lastName,
      dateOfBirth: getFormattedDate(dateOfBirth, 'YYYY-MM-DD'),
      address,
      country,
      state,
      city,
      pinCode,
      countryCode,
      email,
      phone,
    };
    setIsLoading(true);
    patchProfile(body)
      .then(() => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: 'Profile Saved Successfully.',
            severity: 'success',
          })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message || 'Something went wrong.',
            severity: 'error',
          })
        );
        setIsLoading(false);
      });
  };

  const handleSalutation = (e) => {
    setSalutation(e);
  };

  const handleClickDapartDate = (e) => {
    isEditable && setAnchorElDepartDate(e.currentTarget);
  };

  const anchorElDepartDateOpen = Boolean(anchorElDepartDate);

  const singleDateHandlerDepart = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    setDate(day);
    setAnchorElDepartDate(null);
  };

  return (
    <div className='border p-6 my-profile-form-div'>
      <section className='flex justify-between items-center'>
        <span className={`font350 ${isMobile ? 'text-base' : 'text-xl'} `}>
          Welcome, {myProfileForm?.name?.toUpperCase()}
        </span>
        <span
          className={`${
            isEditable ? 'text-gray600' : 'text-link500 cursor-pointer'
          } font350 text-xs`}
          onClick={() => {
            setIsEditable(true);
          }}
        >
          Edit Profile
        </span>
      </section>
      <div className='mt-4 mb-4'>
        <RadioBtnGrp
          isRow={true}
          inputArray={SALUTATION_ADULT}
          value={salutaion}
          handleChange={handleSalutation}
        />
      </div>

      <Grid container spacing={2}>
        <Grid item lg={6} md={6} xs={12}>
          <div className='mr-4'>
            <p className='font-medium text-xs mb-2'>First Name</p>
            <TextField
              type='text'
              variant='outlined'
              className='w-full'
              size='small'
              placeholder='Enter your firstname...'
              value={myProfileForm?.firstName || ''}
              onChange={(e) =>
                setMyProfileForm({
                  ...myProfileForm,
                  firstName: e.target.value,
                })
              }
              InputProps={{
                readOnly: !isEditable,
              }}
            />
          </div>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <div className='mr-4'>
            <p className='font-medium text-xs mb-2'>Last Name</p>
            <TextField
              type='text'
              variant='outlined'
              className='w-full'
              size='small'
              placeholder='Enter your lastname...'
              value={myProfileForm?.lastName || ''}
              onChange={(e) =>
                setMyProfileForm({
                  ...myProfileForm,
                  lastName: e.target.value,
                })
              }
              InputProps={{
                readOnly: !isEditable,
              }}
            />
          </div>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <div className='mr-4'>
            <p className='font-medium text-xs mb-2'>Mobile Number</p>
            <PhoneInput
              country={'in'}
              value={
                `${myProfileForm?.phone?.countryCode}${myProfileForm?.phone?.number}` ||
                ''
              }
              inputProps={{
                required: true,
                autoFocus: true,
              }}
              onChange={(phoneNumber, { dialCode }) => {
                setMyProfileForm({
                  ...myProfileForm,
                  phone: {
                    countryCode: dialCode,
                    number: phoneNumber?.slice(2),
                  },
                });
              }}
            />
          </div>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <div className='mr-4'>
            <p className='font-medium text-xs mb-2'>Email ID</p>

            <TextField
              type='email'
              variant='outlined'
              className='w-full'
              size='small'
              placeholder='Enter your email ...'
              value={myProfileForm?.email || ''}
              error={
                myProfileForm?.email &&
                !emailValidate.test(myProfileForm?.email)
              }
              helperText={
                myProfileForm?.email &&
                !emailValidate.test(myProfileForm?.email)
                  ? 'Please check email id'
                  : ''
              }
              onChange={(e) => {
                setMyProfileForm({
                  ...myProfileForm,
                  email: e.target.value,
                });
              }}
            />
          </div>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <div className='mr-4'>
            <p className='font-medium text-xs mb-2'>Date Of Birth</p>
            <div
              className='border m-auto p-3 cursor-pointer flex justify-between'
              onClick={handleClickDapartDate}
            >
              {getFormattedDate(myProfileForm?.dateOfBirth, DATE10)}
              <img src={calenderSvg} alt='calender' />
            </div>
            <Popover
              open={anchorElDepartDateOpen}
              anchorEl={anchorElDepartDate}
              onClose={() => setAnchorElDepartDate(null)}
            >
              <DateRange
                state={date}
                setState={setDate}
                handleDayClick={singleDateHandlerDepart}
                numberOfMonths={1}
                canChangeMonth={true}
                disabledDays={{ after: new Date() }}
              />
            </Popover>
          </div>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <div className='mr-4'>
            <p className='font-medium text-xs mb-2'>Address</p>
            <TextField
              type='text'
              variant='outlined'
              className='w-full'
              size='small'
              placeholder='Enter your address...'
              value={myProfileForm?.address || ''}
              onChange={(e) =>
                setMyProfileForm({
                  ...myProfileForm,
                  address: e.target.value,
                })
              }
              InputProps={{
                readOnly: !isEditable,
              }}
            />
          </div>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <div className='mr-4'>
            <p className='font-medium text-xs mb-2'>Country </p>
            <Autocomplete
              autoComplete
              id='combo-box-demo'
              options={allCountries}
              getOptionLabel={(option) => {
                return option?.name;
              }}
              openOnFocus={true}
              readOnly={!isEditable}
              includeInputInList
              value={selectedCountry}
              onChange={(event, newValue) => {
                setSelectedCountry(newValue);
                setMyProfileForm({
                  ...myProfileForm,
                  country: newValue?.name,
                });
                setSelectedState(null);
                setSelectedCity(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </div>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <div className='mr-4'>
            <p className='font-medium text-xs mb-2'>State </p>
            <Autocomplete
              autoComplete
              id='combo-box-demo'
              options={allStates}
              getOptionLabel={(option) => {
                return option?.name;
              }}
              openOnFocus
              readOnly={!isEditable}
              includeInputInList
              value={selectedState}
              onChange={(event, newValue) => {
                setAllCities(
                  City.getCitiesOfState(
                    selectedCountry?.isoCode,
                    newValue?.isoCode
                  )
                );
                setMyProfileForm({
                  ...myProfileForm,
                  state: newValue?.name,
                  city: '',
                });
                setSelectedState(newValue);
                setSelectedCity(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </div>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <div className='mr-4'>
            <p className='font-medium text-xs mb-2'>Town/City</p>
            <Autocomplete
              autoComplete
              id='combo-box-demo'
              options={allCities}
              getOptionLabel={(option) => {
                return option?.name;
              }}
              openOnFocus
              readOnly={!isEditable}
              includeInputInList
              value={selectedCity}
              onChange={(event, newValue) => {
                setMyProfileForm({
                  ...myProfileForm,
                  city: newValue?.name,
                });
                setSelectedCity(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </div>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <div className='mr-4'>
            <p className='font-medium text-xs mb-2'>Pincode </p>
            <TextField
              type='text'
              variant='outlined'
              className='w-full'
              size='small'
              placeholder='Enter your Pincode...'
              value={myProfileForm?.pinCode || ''}
              onChange={(e) =>
                setMyProfileForm({
                  ...myProfileForm,
                  pinCode: e.target.value,
                })
              }
              InputProps={{
                readOnly: !isEditable,
              }}
            />
          </div>
        </Grid>
      </Grid>

      <div className='flex justify-end mt-16'>
        <div className='cursor-pointer'>
          <Button
            label={
              <>
                Save Profile
                {isLoading && (
                  <span className='ml-4 mt-1'>
                    <CircularProgress color='inherit' size={20} />
                  </span>
                )}
              </>
            }
            bgColor={primary500}
            color={white500}
            width={'10rem'}
            borderRadius={'4px'}
            fontWeight={'700'}
            disabled={!isEditable}
            onClickHandler={handleSaveProfile}
          />
        </div>
      </div>
    </div>
  );
};
