import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material';
import React from 'react';
import { colorPalette } from '../../../theme/color-palette';

const { primary500, grey400 } = colorPalette;

export const RadioBtnGrp = ({ isRow, inputArray, handleChange, value }) => {
  return (
    <FormControl>
      <RadioGroup
        row={isRow}
        aria-labelledby='demo-row-radio-buttons-group-label'
        name='row-radio-buttons-group'
        value={value}
        onChange={(e) => handleChange(e.target.value)}
      >
        {inputArray?.map((item, index) => {
          return (
            <Tooltip
              title={
                typeof item === 'object' ? (
                  <div className='p-1'>
                    <h4 className='mb-1'>{item?.name}</h4>
                    {item?.tooltip}
                  </div>
                ) : (
                  ''
                )
              }
              arrow
              key={index}
            >
              <FormControlLabel
                value={typeof item === 'object' ? item?.name : item}
                control={
                  <Radio
                    sx={{
                      color: grey400,
                      '&.Mui-checked': {
                        color: primary500,
                      },
                    }}
                  />
                }
                label={typeof item === 'object' ? item?.name : item}
                key={index}
                className='text-gray800 text-xs'
              />
            </Tooltip>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
