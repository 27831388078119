import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { toastHandler } from "../../../Redux/userReducer/user.action";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function PositionedSnackbar() {
  const {
    user: { openToast },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const { vertical, horizontal, open, message, severity } = openToast;

  const handleClose = () => {
    dispatch(toastHandler({ ...openToast, open: false, message: "" }));
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={message}
        autoHideDuration={2000}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity={severity || "error"}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
