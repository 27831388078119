
import { Container } from "@mui/system";
import { Grid } from "@mui/material";
import './profressCard.scss';

export default function ProgressCard(props) {
  return (
    <Container>
      <Grid container>
        <p className="fleet_cardHeading" > {props.heading}</p>
        <p className="fleet_cardInnerText" sx={{ marginBottom: "60px" }}> {props.description}</p>
          <Grid
            container
            direction={{ xs: "row", md: "row" }}
            justifyContent="center"
            alignItems="center"
            spacing={{md: 3, xs: 1}}
            sx={{ maxWidth: "75rem", marginBottom: "65px" }}
          >
            {props.progress.map((progress, index) => {
              return <Grid item key={index} md={4} xs={12}>
                    <p className="fleet_Number">{progress.ranking}</p>
                    <p className="fleet_NumberDescription">{progress.details}</p>
                    </Grid>
              })}
          </Grid>
      </Grid>
    </Container>
  );
}
