import React, { useEffect, useRef } from "react";
import { colorPalette } from "../../../../../theme/color-palette";
import { CustomDrawer } from "../../../../common/Drawer";
import { Navbar } from "../../../../Navbar/Mobile";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";
import flightUpSvg from "../../../../../assets/svgs/flight-up.svg";
import flightDownSvg from "../../../../../assets/svgs/flight-down.svg";
import { Button } from "../../../../common/Button";

const Location = ({
  locationDrawer,
  setLocationDrawer,
  departureLocation,
  arrivalLocation,
  setDepartureLocation,
  setArrivalLocation,
  flightListState,
  setFlightListState,
  activeInput,
  setActiveInput,
  flightList,
}) => {
  const departureRef = useRef();
  const arrivalRef = useRef();
  const { primary500, white500 } = colorPalette;

  useEffect(() => {
    const indexArr = departureLocation?.destinations?.filter(
      (item) => item.code === arrivalLocation?.code
    );

    if (arrivalLocation?.code === departureLocation?.code) {
      setArrivalLocation({
        code: "",
        airport: "",
        city: "",
      });
    }
  }, [departureLocation?.code, arrivalLocation?.code]);

  const onClickHandler = (val) => {
    if (activeInput === 0) {
      setActiveInput(1);
      setDepartureLocation(val);
      setFlightListState(val?.destinations);
    } else {
      setArrivalLocation(val);
      closeLocationDrawerHandler();
    }
  };

  const closeLocationDrawerHandler = () => {
    setLocationDrawer({ bottom: false });
  };

  const onInputChange = (searchText, routesArr) => {
    if (searchText.length > 0) {
      const lowerCaseText = searchText.toLowerCase();

      let newRoutesData = [...routesArr];

      newRoutesData = newRoutesData.filter((obj) => {
        const codeMatches = obj.code.toLowerCase().includes(lowerCaseText);
        const cityMatches = obj.city.toLowerCase().includes(lowerCaseText);

        return codeMatches || cityMatches;
      });

      setFlightListState(newRoutesData);
    } else {
      setFlightListState(routesArr);
    }
  };

  return (
    <div>
      <CustomDrawer
        position={"bottom"}
        state={locationDrawer}
        setState={(val) => setLocationDrawer(val)}
        label={""}
      >
        <div className="fixed top-0 w-full bg-white">
          <Navbar
            color={white500}
            bgColor={primary500}
            label={"Select Departure"}
            showMenu={false}
            onClose={closeLocationDrawerHandler}
          />
          <div className="box-shadow">
            <div className="py-3">
              <Container
                onClick={() => {
                  departureRef?.current?.focus();

                  setFlightListState(flightList);

                  setActiveInput(0);
                }}
              >
                <Grid container>
                  <Grid item xs={1}>
                    <img alt="flight_up" src={flightUpSvg} />
                  </Grid>
                  <Grid item xs={11}>
                    <p className="text-xxxs mb-1 text-black">FROM</p>
                    {activeInput === 0 ? (
                      <input
                        ref={departureRef}
                        className="search_location_input"
                        placeholder="Enter City"
                        onChange={(e) =>
                          onInputChange(e.target.value, flightList)
                        }
                      />
                    ) : (
                      <p>
                        <span className="text-primary text-base">
                          {departureLocation?.city}
                        </span>
                        <span className="text-xxxs ml-1">
                          {departureLocation?.airport}
                        </span>
                      </p>
                    )}
                  </Grid>
                </Grid>
              </Container>
            </div>
            <div className="py-3 border-top">
              <Container
                onClick={() => {
                  arrivalRef?.current?.focus();

                  setFlightListState(departureLocation?.destinations);
                  setActiveInput(1);
                }}
              >
                <Grid container>
                  <Grid item xs={1}>
                    <img alt="flight_down" src={flightDownSvg} />
                  </Grid>
                  <Grid item xs={11}>
                    <p className="text-xxxs mb-1 text-black">To</p>
                    {activeInput === 1 ? (
                      <input
                        ref={arrivalRef}
                        className="search_location_input"
                        placeholder="Enter City"
                        onChange={(e) =>
                          onInputChange(
                            e.target.value,
                            departureLocation?.destinations
                          )
                        }
                      />
                    ) : (
                      <p>
                        <span className="text-primary text-base">
                          {arrivalLocation?.city}
                        </span>
                        <span className="text-xxxs ml-1">
                          {arrivalLocation?.airport}
                        </span>
                      </p>
                    )}
                  </Grid>
                </Grid>
              </Container>
            </div>
          </div>
        </div>
        <div className="bg-white h-screen mt-49 box-shadow mb-16 ">
          <div>
            {flightListState?.length > 0 ? (
              flightListState?.map((item, index) => (
                <div
                  key={index}
                  className="border-bottom py-2"
                  onClick={() => onClickHandler(item)}
                >
                  <Container>
                    <div className="flex items-center justify-between">
                      <p className="text-base ">{item?.city}</p>
                      <p className="bg-black text-white px-1 text-base">
                        {item?.code}
                      </p>
                    </div>
                    <p className="mt-1 pb-2 text-xxs text-gray800">
                      {item?.airport}
                    </p>
                  </Container>
                </div>
              ))
            ) : (
              <div className={"pt-10 text-center"}>No Result Found!</div>
            )}
          </div>
        </div>
        <div className="py-3 fixed bottom-0 w-full bg-white">
          <Container>
            <Button
              label={"Done"}
              color={white500}
              bgColor={primary500}
              size={"medium"}
              variant={"contained"}
              width={"100%"}
              onClickHandler={() => closeLocationDrawerHandler()}
            />
          </Container>
        </div>
      </CustomDrawer>
    </div>
  );
};

export default React.memo(Location);
