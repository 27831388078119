import { CircularProgress, Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { modalStyle, useWindowSize } from "../../../utils/utils";
import warningSvg from "../../../assets/svgs/warning.svg";
import { Button } from "../../common/Button";
import { colorPalette } from "../../../theme/color-palette";

const CancelModal = ({
  isCancelModal,
  handleCloseCancelModal,
  isCancelLoading,
  handleCancelBooking,
}) => {
  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;
  const { primary500, white500, gray600 } = colorPalette;

  const modalWidth = isMobile ? 350 : 400;

  return (
    <Modal open={isCancelModal} onClose={handleCloseCancelModal}>
      <Box
        sx={modalStyle(modalWidth)}
        className='flex flex-col items-between justify-center text-center'
      >
        <>
          <div className='flex justify-center items-center mb-4'>
            <img src={warningSvg} alt='warning' />
            <p className='font350 text-base'>Cancel Booking</p>
          </div>
          <p className={`font325 text-xxs`}>
            Are you sure you want to cancel the booking?
          </p>
          <div className='flex justify-center mt-8'>
            <div className='mr-4'>
              <Button
                label={"close"}
                bgColor={white500}
                color={gray600}
                onClickHandler={handleCloseCancelModal}
              />
            </div>
            <div className='ml-4'>
              <Button
                label={
                  isCancelLoading ? (
                    <>
                      cancel booking
                      <span className='ml-4 mt-1'>
                        <CircularProgress color='inherit' size={20} />
                      </span>
                    </>
                  ) : (
                    "cancel booking"
                  )
                }
                bgColor={primary500}
                color={white500}
                onClickHandler={handleCancelBooking}
              />
            </div>
          </div>{" "}
        </>
      </Box>
    </Modal>
  );
};

export default CancelModal;
