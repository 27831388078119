import moment from "moment";
import React, { useEffect, useState } from "react";
import calender from "../../../../assets/svgs/calender.svg";
import { ROUNDTRIP } from "../../../../utils/constant";
import { dateCompareHandler, dateScrollHandler } from "../../../../utils/utils";

const CalenderScroll = ({
  date,
  tripType,
  setDate,
  selectionStep,
  submitDataHandler,
  dateClone,
  selectedIndex,
  setSelectedIndex,
}) => {
  const [dateScroll, setDateScroll] = useState([]);

  useEffect(() => {
    if (dateClone?.from) {
      if (tripType === ROUNDTRIP) {
        let dateScrollClone = dateScrollHandler();
        setDateScroll(dateScrollClone);
      } else {
        let dateScrollClone = dateScrollHandler();
        setDateScroll(dateScrollClone);
      }
    }
  }, []);

  const dateMyHandler = (index, item) => {
    let scrollDateClone = { ...date };
    if (selectionStep === 1) {
      if (dateCompareHandler(item?.date, date?.to)) {
        scrollDateClone = {
          ...date,
          from: item.date,
          to: moment(item?.date).add(1, "days").toDate(),
          enteredTo: moment(item?.date).add(1, "days").toDate(),
        };
      } else {
        scrollDateClone = { ...date, from: item.date };
      }
    } else {
      scrollDateClone = { ...date, to: item.date, enteredTo: item.date };
    }
    setSelectedIndex(index);
    setDate({ ...scrollDateClone });
    submitDataHandler(scrollDateClone);
  };

  return (
    <div className='h-13 shadow-lg'>
      <div className='flex h-full relative'>
        <div className='bg-primary h-full flex items-center justify-center w-11 absolute left-0 shadow-lg'>
          <img src={calender} alt='calender' />
        </div>
        <div className='flex overflow-auto ml-13 mr-4 items-center'>
          {dateScroll?.map((item, index) => (
            <div
              onClick={() => dateMyHandler(index, item)}
              key={index}
              className={`min-w-22 ml-2 calender_scroll `}
            >
              <p
                className={`text-xxs ${
                  selectedIndex === index ? "font-black " : ""
                }`}
              >
                {item?.value}
              </p>
              <p className='text-xxs'>{item?.availability}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CalenderScroll;
