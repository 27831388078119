import React, { useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import flightUpSvg from "../../../assets/svgs/flight-up.svg";
import "./reviewPageDesk.scss";
import { BsClock } from "react-icons/bs";
import FareSummary from "../common/FareSummary";
import HeaderNavSearch from "../../searchResults/Desktop/headerResultPage/HeaderNavSearch";
import { getFormattedDate, useUrlExtract } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import { modifyBooking, reviewBookingData } from "../../../Api/Booking";
import { DATE_FORMAT } from "../../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import { LoginPage } from "../../Login-Signup/Login/LoginPage";
import { SkeletonComp } from "../../common/Skeleton/Skeleton";
import {
  bookingIdHandler,
  toastHandler,
} from "../../../Redux/userReducer/user.action";
import { verifyToken } from "../../../Api/Auth";
import { Footer } from "../../footer/Footer";

export function ReviewPageDesk() {
  const [reviewData, setReviewData] = useState("");
  const [scheduleState, setScheduleState] = useState("");
  const [returnScheduleState, setReturnScheduleState] = useState("");
  const [open, setOpen] = useState(false);
  const [signUpModal, setSignUpModal] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    user: { openToast },
  } = useSelector((state) => state);

  const sessionId = useUrlExtract("sessionId");
  const { bookingType, bookingId } = useUrlExtract("all");
  const { schedule, returnSchedule } = reviewData;

  const handleNavigateAddPassengerDetails = () => {
    navigate(`/passenger-info?sessionId=${sessionId}`);
  };

  useEffect(() => {
    if (reviewData?.schedule?.length > 0) {
      setScheduleState(schedule[0]);
    }
    if (reviewData?.returnSchedule?.length > 0) {
      setReturnScheduleState(returnSchedule[0]);
    }
  }, [reviewData]);

  useEffect(() => {
    reviewBookingData(sessionId)
      .then(({ data }) => {
        setReviewData(data);
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message || "Something went wrong ...",
            severity: "error",
          })
        );
      });
  }, []);

  /////data destructuring
  const {
    flightNumber,
    origin,
    destination,
    departureDate,
    arrivalDate,
    departureTime,
    arrivalTime,
    duration,
    aircraft,
    noOfStops,
    schedulePerSegment,
  } = scheduleState;

  const {
    flightNumber: flightNumberReturn,
    origin: originReturn,
    destination: destinationReturn,
    departureDate: departureDateReturn,
    arrivalDate: arrivalDateReturn,
    departureTime: departureTimeReturn,
    arrivalTime: arrivalTimeReturn,
    duration: durationReturn,
    aircraft: aircraftReturn,
    noOfStops: noOfStopsReturn,
    schedulePerSegment: schedulePerSegmentReturn,
  } = returnScheduleState;

  // conditional login
  const handleOpen = () => {
    setOpen(true);
    setSignUpModal(false);
  };
  const handleClose = () => setOpen(false);

  const userData = localStorage.getItem("user");

  useEffect(() => {
    verifyToken()
      .then(({ data }) => setIsAuth(data))
      .catch((err) => {
        setIsAuth(false);
      });
  }, [userData]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleContinueToPayment = () => {
    setIsLoading(true);
    modifyBooking(bookingId, { sessionId })
      .then(({ data }) => {
        setIsLoading(false);
        dispatch(bookingIdHandler(data));
        window.location.href = data;
      })
      .catch((err) => {
        setIsLoading(false);
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message || "Something went wrong...",
            severity: "error",
          })
        );
      });
  };

  return (
    <>
      <div className="container-review-page w-full">
        <>
          <HeaderNavSearch />
        </>

        <main className="w-full m-auto">
          <div className="review-your-flight mt-8 mb-8 flex justify-between">
            <div className="depart-heading">
              <img src={flightUpSvg} alt="flight up icon" />
              {schedule ? (
                <span className="text-base font350 ml-4">
                  <span className="font-semibold">Departure</span> -{" "}
                  <span>{origin?.city}</span> to{" "}
                  <span>{destination?.city}</span>
                </span>
              ) : (
                <SkeletonComp height="100%" width="100%" varient="text" />
              )}
              <p className="text-xs font-normal mt-2 ml-9">
                Review your Itinerary
              </p>
            </div>
          </div>
          <Grid
            container
            className="flight-details-summary flex justify-between relative mb-16"
          >
            {schedule?.length > 0 ? (
              <Grid
                item
                xl={7}
                lg={7}
                md={7}
                sm={7}
                className="flight-details-review border flex justify-between"
              >
                <div className="flight-details-review-header text-white">
                  <span className="text-xs font-normal ml-8">
                    FLIGHT DETAILS
                  </span>

                  <span className="text-xs font-light ml-10">
                    FLIGHT NO - {flightNumber?.slice(3)}
                  </span>

                  <span className="text-xs font-light ml-10">
                    AIRCRAFT - {aircraft}
                  </span>
                </div>
                <div className="fldtls ml-20 flex flex-col justify-between">
                  <div className="border-line-custom">
                    <span className="dot-before"></span>
                    <span className="line"></span>
                    <span className="dot-after"></span>
                  </div>
                  <p className="font350 text-sm text-primary">
                    {departureTime}
                  </p>

                  <div className="cityinfo-depart ml-14 mb-10">
                    <p className="font-350 text-base review-citycode text-white">
                      {origin?.code}
                    </p>
                    <p className="font-normal text-xs mb-1 review-cityname uppercase">
                      {origin?.city} AIRPORT
                    </p>
                    <p className="font-light text-xxs mb-1 gotham-book">
                      {getFormattedDate(departureDate, DATE_FORMAT?.DATE8)}
                    </p>
                  </div>

                  <p className="font-normal text-sm flex ml-6">
                    <BsClock />
                    <span className="ml-2">{duration}</span>
                    {noOfStops > 0 ? (
                      <span className="ml-3">
                        ({noOfStops} stops @
                        {schedulePerSegment?.length && (
                          <>
                            {schedulePerSegment?.map((item) => {
                              return (
                                item?.layover && (
                                  <span className="mr-2">
                                    {item?.destination?.city}
                                  </span>
                                )
                              );
                            })}
                          </>
                        )}
                        -
                        {schedulePerSegment?.length > 1 && (
                          <>
                            {schedulePerSegment?.map((item) => {
                              return (
                                item?.layover && (
                                  <span className="ml-2">{item?.layover}</span>
                                )
                              );
                            })}
                          </>
                        )}
                        )
                      </span>
                    ) : (
                      <span className="ml-3">(Non Stop)</span>
                    )}
                  </p>

                  <div className="cityinfo-arrive ml-14 mt-10">
                    <p className="font-350 text-base review-citycode text-white">
                      {destination?.code}
                    </p>
                    <p className="font-normal text-xs mb-1 review-cityname uppercase">
                      {destination?.city} AIRPORT
                    </p>
                    <p className="font-light text-xxs mb-1 gotham-book">
                      {getFormattedDate(arrivalDate, DATE_FORMAT?.DATE8)}
                    </p>
                  </div>
                  <p className="font350 text-sm text-primary">{arrivalTime}</p>
                </div>
                <div className="flight-details-review-footer">
                  {/* <p className='text-primary font350 text-xs ml-8'>
                    Changes and refunds are allowed with this ticket
                  </p> */}
                </div>
              </Grid>
            ) : (
              <SkeletonComp width="55%" height="20rem" varient="rectangular" />
            )}

            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={4}
              className="fare-summary border  flex"
            >
              <FareSummary />
            </Grid>
          </Grid>

          {returnSchedule && (
            <>
              <div className="depart-heading mb-6">
                <img src={flightUpSvg} alt="flight down icon" />
                {originReturn && destinationReturn ? (
                  <span className="text-base font350 ml-4">
                    <span className="font-semibold">Return</span> -{" "}
                    <span>{originReturn?.city}</span> to{" "}
                    <span>{destinationReturn?.city}</span>
                  </span>
                ) : (
                  <SkeletonComp
                    width={"80%"}
                    variant="text"
                    customClass="skeleton-review"
                  />
                )}
                <p className="text-xs font-normal mt-2 ml-9">
                  Review your Itinerary
                </p>
              </div>

              {returnScheduleState ? (
                <Grid
                  container
                  className="flight-details-summary flex justify-between relative"
                >
                  <Grid
                    item
                    xl={7}
                    lg={7}
                    md={7}
                    sm={7}
                    className="flight-details-review border flex justify-between"
                  >
                    <div className="flight-details-review-header text-white">
                      <span className="text-xs font-normal ml-8">
                        FLIGHT DETAILS
                      </span>
                      <span className="text-xs font-light ml-10">
                        FLIGHT NO - {flightNumberReturn?.slice(3)}
                      </span>
                      <span className="text-xs font-light ml-10">
                        AIRCRAFT - {aircraftReturn}
                      </span>
                    </div>
                    <div className="fldtls ml-20 flex flex-col justify-between">
                      <div className="border-line-custom">
                        <span className="dot-before"></span>
                        <span className="line"></span>
                        <span className="dot-after"></span>
                      </div>
                      <p className="font350 text-sm text-primary">
                        {departureTimeReturn}
                      </p>
                      {originReturn && (
                        <div className="cityinfo-depart ml-14 mb-10">
                          <p className="font-350 text-base review-citycode text-white">
                            {originReturn?.code}
                          </p>
                          <p className="font-normal text-xs mb-1 review-cityname uppercase">
                            {originReturn?.city} AIRPORT
                          </p>
                          <p className="font-light text-xxs mb-1 gotham-book">
                            {getFormattedDate(
                              departureDateReturn,
                              DATE_FORMAT?.DATE8
                            )}
                          </p>
                        </div>
                      )}

                      <p className="font-normal text-sm flex ml-6">
                        <BsClock />
                        <span className="ml-3">{durationReturn}</span>
                        {noOfStopsReturn > 0 ? (
                          <span className="ml-3">
                            ({noOfStopsReturn} stops @
                            {schedulePerSegmentReturn?.length && (
                              <>
                                {schedulePerSegmentReturn?.map((item) => {
                                  return (
                                    item?.layover && (
                                      <span className="mr-2">
                                        {item?.destination?.city}
                                      </span>
                                    )
                                  );
                                })}
                              </>
                            )}
                            -
                            {schedulePerSegmentReturn?.length > 1 && (
                              <>
                                {schedulePerSegmentReturn?.map((item) => {
                                  return (
                                    item?.layover && (
                                      <span className="ml-2">
                                        {item?.layover}
                                      </span>
                                    )
                                  );
                                })}
                              </>
                            )}
                            )
                          </span>
                        ) : (
                          <span className="ml-3">(Non Stop)</span>
                        )}
                      </p>

                      <div className="cityinfo-arrive ml-14 mt-10">
                        <p className="font-350 text-base review-citycode text-white ">
                          {destinationReturn?.code}
                        </p>
                        <p className="font-normal text-xs mb-1 review-cityname uppercase">
                          {destinationReturn?.city} AIRPORT
                        </p>
                        <p className="font-light text-xxs mb-1 gotham-book">
                          {getFormattedDate(
                            arrivalDateReturn,
                            DATE_FORMAT?.DATE8
                          )}
                        </p>
                      </div>
                      <p className="font350 text-sm text-primary">
                        {arrivalTimeReturn}
                      </p>
                    </div>
                    <div className="flight-details-review-footer">
                      {/* <p className='text-primary font350 text-xs ml-8'>
                      Changes and refunds are allowed with this ticket
                    </p> */}
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <SkeletonComp
                  width="55%"
                  height="20rem"
                  varient="rectangular"
                />
              )}
            </>
          )}
          <div className="bottom-buttons flex justify-center items-center mt-8 pr-50">
            <button className="btn-cancel cursor-pointer" onClick={handleBack}>
              BACK
            </button>
            {isAuth && bookingType !== "modify" ? (
              <button
                className="btn-add-psngr-dtls ml-6 cursor-pointer"
                onClick={handleNavigateAddPassengerDetails}
              >
                ADD GUEST DETAILS
              </button>
            ) : isAuth && bookingType === "modify" ? (
              <button
                className="btn-add-psngr-dtls ml-6 cursor-pointer"
                onClick={handleContinueToPayment}
              >
                CONTINUE TO PAYMENT
                {isLoading && (
                  <span className="ml-4">
                    <CircularProgress color="inherit" size={20} />
                  </span>
                )}
              </button>
            ) : (
              <button
                className="btn-add-psngr-dtls ml-6 cursor-pointer"
                onClick={handleOpen}
              >
                LOGIN TO CONTINUE
              </button>
            )}
          </div>
        </main>
      </div>

      <Footer />

      <LoginPage
        open={open}
        signUpModal={signUpModal}
        setSignUpModal={setSignUpModal}
        handleClose={handleClose}
      />
    </>
  );
}
