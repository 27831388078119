import React, { useState } from "react";
import { MenuItem, Select } from "@mui/material";

const SelectComp = ({ list, clickHandler, openPax }) => {
  const [selectedVal, setSelectedVal] = useState("");

  const handleChange = (event) => {
    setSelectedVal(event?.target?.value);
    clickHandler(openPax, event.target.value);
  };

  return (
    <div>
      <Select
        id='demo-simple-select'
        value={selectedVal}
        onChange={handleChange}
        size={"small"}
        className={"w-full"}
      >
        {list?.length ? (
          list?.map((item) => {
            return (
              <MenuItem value={item} key={item?.id}>
                {item?.firstName} {item?.lastName}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem value={""}>No travelers found</MenuItem>
        )}
      </Select>
    </div>
  );
};

export default SelectComp;
