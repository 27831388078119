import { Grid } from "@mui/material";
import React from "react";
import "./showFlightDetails.scss";

export default function ShowFlightDetails({ faresDetails }) {
  return (
    <div className="w-full border flight-details m-auto ">
      <Grid container className="py-3 justify-between sub-header">
        <Grid item lg={1} md={1}></Grid>
        {faresDetails?.[0]?.details?.map((item) => {
          return (
            <Grid
              item
              lg={1.8}
              md={1.8}
              key={item?.name}
              className="text-center"
            >
              {item?.name}
            </Grid>
          );
        })}
      </Grid>
      <div>
        {faresDetails?.map((item,index) => {
          return (
            <Grid container className="mt-1 justify-between" key={index}>
              <Grid
                item
                lg={1}
                md={1}
                className={"bg-primary text-white  text-center py-2"}
              >
                {item?.fareName}
              </Grid>
              {item?.details?.map((val,index) => {
                return (
                  <Grid
                    item
                    lg={1.8}
                    md={1.8}
                    style={{ fontSize: "0.75rem" }}
                    key={index}
                    className={"text-center text-xs gotham-book font-thin py-2"}
                  >
                    {" "}
                    {["Cabin Baggage", "Check-in Baggage"].includes(val?.name)
                      ? `${val?.description} (1 piece only)`
                      : val?.description}
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </div>
    </div>
  );
}
