import React from "react";
import { OfferDetails } from "../components/OfferDetails/offerDetails";

export const OfferDetailView = () => {

  return (
    <div>
      <OfferDetails />
    </div>
  );
};
