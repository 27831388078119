import React, { useEffect, useState } from "react";
import { PASSENGER_TYPE } from "../../../../utils/constant";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Container, Grid, IconButton } from "@mui/material";
import { Button } from "../../../common/Button";
import { colorPalette } from "../../../../theme/color-palette";
import { handlePassengerValue } from "../../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { updatePassengerNumbers } from "../../../../Redux/searchReducer/search.action";

export const SelectPassenger = ({
  buttonWidth,
  handleClose,
  // passengerNumbers,
  // setPassengerNumbers,
  // handlePassengerValue,
  setModifyBtnState,
  usedIn,
}) => {
  // const [disabledMinus, setDisabledMinus] = useState({
  //   adult: false,
  //   children: false,
  //   infant: false,
  // });
  // const [disabledPlus, setDisabledPlus] = useState({
  //   adult: false,
  //   children: false,
  //   infant: false,
  // });

  const dispatch = useDispatch();

  const {
    search: { searchDetails },
  } = useSelector((state) => state);

  const { passengerNumbers } = searchDetails;

  const [tempPassengerList, setTempPassengerList] = useState(passengerNumbers);

  // useEffect(() => {
  //   const { adult, children, infant } = tempPassengerList;
  //
  //   setDisabledMinus({ adult: false, children: false, infant: false });
  //   setDisabledPlus({
  //     adult: false,
  //     children: false,
  //     infant: false,
  //   });
  //   if (adult <= 1) {
  //     setDisabledMinus((prev) => ({ ...prev, adult: true }));
  //     setDisabledPlus((prev) => ({ ...prev, adult: false }));
  //   } else if (adult >= 9) {
  //     setDisabledPlus((prev) => ({ ...prev, adult: true }));
  //     setDisabledMinus((prev) => ({ ...prev, adult: false }));
  //   }
  //   if (children <= 0) {
  //     setDisabledMinus((prev) => ({ ...prev, children: true }));
  //     setDisabledPlus((prev) => ({ ...prev, children: false }));
  //   } else if (children >= 9) {
  //     setDisabledPlus((prev) => ({ ...prev, children: true }));
  //     setDisabledMinus((prev) => ({ ...prev, children: false }));
  //   }
  //   if (infant <= 0) {
  //     setDisabledMinus((prev) => ({ ...prev, infant: true }));
  //     setDisabledPlus((prev) => ({ ...prev, infant: false }));
  //   } else if (infant >= adult) {
  //     setDisabledPlus((prev) => ({ ...prev, infant: true }));
  //     setDisabledMinus((prev) => ({ ...prev, infant: false }));
  //   }
  // }, [adult, children, infant]);

  const { white500, primary500 } = colorPalette;

  const handleDone = () => {
    dispatch(updatePassengerNumbers(tempPassengerList));

    handleClose();
  };

  useEffect(() => {
    if (tempPassengerList.infant > tempPassengerList.adult) {
      setTempPassengerList((curr) => {
        return { ...curr, infant: curr.adult };
      });
    }
  }, [tempPassengerList]);

  return (
    <Container>
      <div className="flex flex-col">
        {PASSENGER_TYPE.map((item) => {
          return (
            <Grid container className="my-2" key={item?.ageCategory}>
              <Grid item xs={8} md={8} lg={8} xl={8}>
                <div className="passenger-age-info">
                  <p className="age-category text-sm">{item?.ageCategory}</p>{" "}
                  <p className="age-range text-xs text-gray600">
                    {item?.ageRange}
                  </p>
                </div>
              </Grid>
              <Grid item xs={4} md={4} lg={4} xl={4}>
                <div className="btn-div-passenger-qty text-right flex items-center">
                  <IconButton
                    variant="outlined"
                    component="span"
                    // disabled={disabledMinus?.[item?.ageCategory]}
                    onClick={() => {
                      handlePassengerValue(
                        tempPassengerList,
                        item?.key,
                        false,
                        setTempPassengerList,
                        setModifyBtnState,
                        usedIn
                      );
                    }}
                  >
                    <AiOutlineMinusCircle
                    // className={
                    //   disabledMinus[item?.ageCategory]
                    //     ? "text-gray600"
                    //     : "text-black"
                    // }
                    />
                  </IconButton>
                  <span className="inline-block passenger-quantity">
                    {tempPassengerList[item?.key]}
                  </span>
                  <IconButton
                    variant="outlined"
                    component="span"
                    color="primary"
                    // disabled={disabledPlus?.[item?.ageCategory]}
                    onClick={() => {
                      handlePassengerValue(
                        tempPassengerList,
                        item?.key,
                        true,
                        setTempPassengerList,
                        setModifyBtnState,
                        usedIn
                      );
                    }}
                  >
                    <AiOutlinePlusCircle
                    // className={
                    //   disabledPlus[item?.ageCategory]
                    //     ? "text-gray600"
                    //     : "text-link500"
                    // }
                    />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          );
        })}
        <div className="py-2">
          <Button
            label={"DONE"}
            color={white500}
            bgColor={primary500}
            size={"medium"}
            variant={"contained"}
            width={buttonWidth}
            onClickHandler={handleDone}
          />
        </div>
      </div>
    </Container>
  );
};
