import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Modal,
  TextField,
} from "@mui/material";
import closeSvg from "../../../assets/svgs/closeSvg.svg";
import {
  getFormattedDate,
  modalStyle,
  useWindowSize,
} from "../../../utils/utils.js";
import {
  Adult,
  ageType,
  Child,
  Infant,
  PREFIX_RADIO,
} from "../../../utils/constant";
import { colorPalette } from "../../../theme/color-palette";
import { Button } from "../../common/Button";
import { RadioBtnGrp } from "../../common/radio-buttons/RadioBtnGrp";
import {
  addPassenger,
  deletePassenger,
  editPassenger,
  getAllPassengers,
} from "../../../Api/Profile";
import { toastHandler } from "../../../Redux/userReducer/user.action";
import { useDispatch, useSelector } from "react-redux";
import { AiFillEdit } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import DatePicker from "react-datepicker";
import moment from "moment";

export const Travellers = () => {
  const [isPopup, setIsPopup] = useState(false);
  const [modalType, setModalType] = useState("");
  const [allTravellers, setAllTravellers] = useState("");
  const [traveller, setTraveller] = useState("");
  const [selectedTravellerForEdit, setSelectedTravellerForEdit] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllPassengers().then(({ data }) => {
      setAllTravellers(data);
    });
  }, []);

  const dispatch = useDispatch();
  const {
    user: { openToast },
  } = useSelector((state) => state);

  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;
  const { primary500, white500 } = colorPalette;

  const modalWidth = isMobile ? "100%" : 600;

  const handleTravellerModal = (actionType, item) => {
    setTraveller({
      ...item,
      birthdate: getFormattedDate(item?.birthdate, "YYYY-MM-DD"),
    });
    setIsPopup(true);
    actionType === "add" && setModalType("add");
    if (actionType === "edit") {
      setModalType("edit");
      setSelectedTravellerForEdit(item?.id);
    } else if (actionType === "delete") {
      setModalType("delete");
      setSelectedTravellerForEdit(item?.id);
    }
  };

  const handleCloseModal = () => {
    setIsPopup(false);
  };

  const handleSalutation = (e) => {
    setTraveller({
      ...traveller,
      salutation: e,
    });
  };

  const handleAgeType = (e) => {
    setTraveller({
      ...traveller,
      type: e,
    });
  };

  const handleAddTraveller = () => {
    setIsLoading(true);
    addPassenger(traveller)
      .then(() => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: "Guest added Successfully.",
            severity: "success",
          })
        );
        setIsLoading(false);
        setIsPopup(false);
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message || "Something went wrong.",
            severity: "error",
          })
        );
        setIsLoading(false);
      });
  };

  const handelEditTraveller = (selectedTravellerForEdit) => {
    setIsLoading(true);
    editPassenger(traveller, selectedTravellerForEdit)
      .then(() => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: "Guest edited Successfully.",
            severity: "success",
          })
        );
        setIsLoading(false);
        setIsPopup(false);
      })
      .catch((err) => {
        setIsLoading(false);
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message || "Something went wrong.",
            severity: "error",
          })
        );
      });
  };

  const handelDeleteTraveller = (selectedTravellerForEdit) => {
    setIsLoading(true);
    deletePassenger(selectedTravellerForEdit)
      .then(() => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: "Guest deleted Successfully.",
            severity: "success",
          })
        );
        setIsLoading(false);
        setIsPopup(false);
        getAllPassengers().then(({ data }) => {
          setAllTravellers(data);
        });
      })
      .catch((err) => {
        setIsLoading(false);
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message || "Something went wrong.",
            severity: "error",
          })
        );
      });
  };

  const includeDateIntervals =
    traveller?.type === Infant
      ? [
          {
            start: new Date(moment().subtract(2, "Y").toString()),
            end: new Date(),
          },
        ]
      : traveller?.type === Adult
      ? [
          {
            start: new Date(moment().subtract(100, "Y").toString()),
            end: new Date(moment().subtract(12, "Y").toString()),
          },
        ]
      : traveller?.type === Child
      ? [
          {
            start: new Date(moment().subtract(12, "Y").toString()),
            end: new Date(moment().subtract(2, "Y").toString()),
          },
        ]
      : "";

  return (
    <div className='border my-profile-form-div'>
      <p className='font350 text-xl p-6 mb-4'>Guests</p>
      <Divider />
      {allTravellers &&
        allTravellers?.map((item, index) => {
          return (
            <>
              <div
                className='flex justify-between items-center p-4'
                key={index}
              >
                <p>
                  <span className='text-gray600 mr-6'>{index + 1}</span>
                  <span className='font350 text-xs mr-2'>
                    {item?.salutation}
                  </span>
                  <span className='font350 text-xs mr-4'>
                    {item?.firstName} {item?.lastName}
                  </span>
                  <span className='font350 text-xs text-gray600'>
                    {item?.type}
                  </span>
                </p>
                <p>
                  <span
                    className='text-link500 font350 text-xs cursor-pointer'
                    onClick={() => handleTravellerModal("edit", item)}
                  >
                    <AiFillEdit size={20} />
                  </span>
                  <span
                    className='text-link500 font350 text-xs cursor-pointer ml-4'
                    onClick={() => handleTravellerModal("delete", item)}
                  >
                    <AiFillDelete size={20} />
                  </span>
                </p>
              </div>
              <Divider />
            </>
          );
        })}

      <div className='mt-6 ml-4 mb-6'>
        <Button
          label={"add guests"}
          bgColor={primary500}
          color={white500}
          borderRadius={"4px"}
          onClickHandler={() => handleTravellerModal("add")}
        />
      </div>

      <Modal open={isPopup} onClose={handleCloseModal}>
        <Box sx={modalStyle(modalWidth)}>
          {modalType === "delete" && (
            <p className='text-center uppercase'>{modalType} Guest</p>
          )}
          {modalType !== "delete" && (
            <>
              <p className='font350 text-base flex justify-between'>
                <span className='text-center uppercase'>{modalType} Guest</span>
                <span className='cursor-pointer' onClick={handleCloseModal}>
                  <img
                    src={closeSvg}
                    alt='close modal'
                    width={20}
                    height={20}
                  />
                </span>
              </p>
              <div className='mt-4 mb-4'>
                <RadioBtnGrp
                  isRow={true}
                  inputArray={PREFIX_RADIO}
                  value={traveller?.salutation}
                  handleChange={handleSalutation}
                />
              </div>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} xs={12}>
                  <div className='mr-4'>
                    <p className='font-medium text-xs mb-2'>First Name</p>
                    <TextField
                      type='text'
                      variant='outlined'
                      className='w-full'
                      size='small'
                      placeholder='Enter your firstname...'
                      value={traveller?.firstName}
                      onChange={(e) =>
                        setTraveller({
                          ...traveller,
                          firstName: e.target.value,
                        })
                      }
                    />
                  </div>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <div className='mr-4'>
                    <p className='font-medium text-xs mb-2'>Last Name</p>
                    <TextField
                      type='text'
                      variant='outlined'
                      className='w-full'
                      size='small'
                      placeholder='Enter your lastname...'
                      value={traveller?.lastName}
                      onChange={(e) =>
                        setTraveller({
                          ...traveller,
                          lastName: e.target.value,
                        })
                      }
                    />
                  </div>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <div className='mr-4'>
                    <p className='font-medium text-xs mb-2'>Date Of Birth</p>
                    <DatePicker
                      selected={
                        traveller?.birthdate && new Date(traveller?.birthdate)
                      }
                      onChange={(date) => {
                        setTraveller({
                          ...traveller,
                          birthdate: getFormattedDate(date, "YYYY-MM-DD"),
                        });
                      }}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={100}
                      dateFormat='dd-MM-yyyy'
                      includeDateIntervals={includeDateIntervals}
                      className='h-9 w-full px-4'
                    />
                  </div>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <div>
                    <RadioBtnGrp
                      isRow={true}
                      inputArray={ageType}
                      value={traveller?.type}
                      handleChange={handleAgeType}
                    />
                  </div>
                </Grid>
              </Grid>
            </>
          )}

          <div className='m-6 flex justify-center'>
            <Button
              label={
                <>
                  {isLoading && (
                    <span className='ml-4'>
                      <CircularProgress color='inherit' size={20} />
                    </span>
                  )}
                  <span>{`${modalType}`}</span>
                </>
              }
              bgColor={primary500}
              color={white500}
              borderRadius={"4px"}
              disabled={
                modalType === "delete"
                  ? false
                  : !(
                      traveller?.firstName &&
                      traveller?.lastName &&
                      traveller?.type
                    )
              }
              onClickHandler={
                modalType === "add"
                  ? handleAddTraveller
                  : modalType === "delete"
                  ? () => handelDeleteTraveller(selectedTravellerForEdit)
                  : () => handelEditTraveller(selectedTravellerForEdit)
              }
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
};
