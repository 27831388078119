import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import { store, persistor } from "./Redux/store";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "react-toastify/dist/ReactToastify.css";

import "./assets/font/GothamRounded-Bold.woff";
import "./assets/font/GothamRounded-Book.woff";
import "./assets/font/GothamRounded-Light.woff";
import "./assets/font/GothamRounded-Medium.woff";

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_API_KEY,
//   integrations: [new BrowserTracing()],
//   environment: process.env.REACT_APP_SENTRY_ENV,
//   release: "flybig@" + process.env.REACT_APP_VERSION,

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
