import {
  CHECK_IN,
  CHECK_IN_BY_PNR,
  DESTINATION_DETAILS,
  PAX_CHECKIN,
  SEAT_CHECKIN,
} from "./checkIn.types";

const initialState = {
  checkInStep: 1,
  checkInData: {},
  paxCheckIn: {},
  seatCheckIn: {},
};

export const checkInReducer = (state = initialState, action) => {
  switch (action?.type) {
    case CHECK_IN:
      return { ...state, checkInStep: action?.payload };
    case CHECK_IN_BY_PNR:
      return { ...state, checkInData: action?.payload };
    case PAX_CHECKIN:
      return { ...state, paxCheckIn: action?.payload };
    case SEAT_CHECKIN:
      return { ...state, seatCheckIn: action?.payload };
    case DESTINATION_DETAILS:
      return { ...state, destinationDetail: action?.payload };
    default:
      return state;
  }
};
