import React, { useEffect, useState } from "react";
import { useUrlExtract, useWindowSize } from "../../utils/utils";
import { PassengerInfo } from "./Desktop/PassengerInfo";
import { MobilePassengerDetails } from "./Mobile";
import "./index.scss";
import { DEFAULT_INFO } from "../../utils/constant";
import { Loader } from "../MobileLoader/index";
import { useDispatch, useSelector } from "react-redux";
import { toastHandler } from "../../Redux/userReducer/user.action";
import { Country } from "country-state-city";
import { useScrollTop } from "../../customHooks/useScrollTop";
import { reviewBookingData } from "../../Api/Booking";
import { getAllPassengers } from "../../Api/Profile";
import { verifyToken } from "../../Api/Auth";

export function PassengerInfoPage() {
  useScrollTop();

  const [passengersList, setPassengersList] = useState([]);
  const [reviewData, setReviewData] = useState("");
  const [allCountries, setAllCountries] = useState("");
  const [contactInfo, setContactInfo] = useState({
    contact: {
      countryCode: "91",
      number: "",
    },
    email: "",
    whatsappNotification: true,
  });
  const [travellerList, setTravellerList] = useState([]);
  const [travellerListClone, setTravellerListClone] = useState([]);
  const [isAuth, setIsAuth] = useState(false);
  const [innerWidth] = useWindowSize();
  const isMobile = innerWidth < 640 && innerWidth !== 0;
  const sessionId = useUrlExtract("sessionId");
  const userData = localStorage.getItem("user");
  const dispatch = useDispatch();
  const {
    user: { openToast },
    cart: { ancillaryStep, cartData },
  } = useSelector((state) => state);

  useEffect(() => {
    verifyToken()
      .then(({ data }) => setIsAuth(data))
      .catch((err) => {
        setIsAuth(false);
      });
  }, [userData]);

  useEffect(() => {
    isAuth &&
      getAllPassengers().then(({ data }) => {
        setTravellerList(data);
        setTravellerListClone(data);
      });
  }, [isAuth]);

  useEffect(() => {
    reviewBookingData(sessionId)
      .then(({ data }) => setReviewData(data))
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          }),
        );
      });
  }, []);

  useEffect(() => {
    let allCountries = Country.getAllCountries();
    setAllCountries(allCountries);
  }, []);

  const addNewPassenger = (type) => {
    let passengerListClone = JSON.parse(JSON.stringify(passengersList));
    passengerListClone.push({ ...DEFAULT_INFO, type, id: Math.random() });
    setPassengersList(passengerListClone);
  };

  if (!reviewData?.sessionId) {
    return <Loader />;
  }

  return isMobile ? (
    <MobilePassengerDetails
      passengersList={passengersList}
      setPassengersList={setPassengersList}
      sessionId={sessionId}
      reviewData={reviewData}
      contactInfo={contactInfo}
      setContactInfo={setContactInfo}
      allCountries={allCountries}
      addNewPassenger={addNewPassenger}
      ancillaryStep={ancillaryStep}
      cartData={cartData}
      travellerList={travellerList}
      setTravellerList={setTravellerList}
    />
  ) : (
    <PassengerInfo
      passengersList={passengersList}
      setPassengersList={setPassengersList}
      sessionId={sessionId}
      contactInfo={contactInfo}
      setContactInfo={setContactInfo}
      addNewPassenger={addNewPassenger}
      reviewData={reviewData}
      ancillaryStep={ancillaryStep}
      isAuth={isAuth}
      travellerList={travellerList}
      setTravellerList={setTravellerList}
      travellerListClone={travellerListClone}
    />
  );
}
