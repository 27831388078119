import React, { useEffect, useState } from "react";
import { colorPalette } from "../../../theme/color-palette";
import { Navbar } from "../../Navbar/Mobile";
import FlightUp from "../../../assets/svgs/flight-up.svg";
import FlighDown from "../../../assets/svgs/flight-down.svg";
import clock from "../../../assets/svgs/clock.svg";
import { Grid, Container } from "@mui/material";
import "./index.scss";
import { MobileAmountFooter } from "../../MobileAmountFooter";
import { CartDetails } from "../../CartDetailsDrawer";
import { useNavigate } from "react-router-dom";
import MobileLogin from "./userLoginDrawer/index";
import { verifyToken } from "../../../Api/Auth";
import { useUrlExtract } from "../../../utils/utils";
import { modifyBooking } from "../../../Api/Booking";
import { useDispatch } from "react-redux";
import { bookingIdHandler } from "../../../Redux/userReducer/user.action";

export const MobileReview = ({ reviewData }) => {
  const [CartDetailsDrawer, setCartDetailsDrawer] = useState({
    bottom: false,
  });
  const [loginDrawer, setLoginDrawer] = useState({ bottom: false });
  const [isAuth, setIsAuth] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { bookingType, bookingId, sessionId } = useUrlExtract("all");

  const { white500, primary500 } = colorPalette;

  const arrival = reviewData?.returnSchedule?.[0];
  const departure = reviewData?.schedule?.[0];

  const onClickHandler = () => {
    if (isAuth && bookingType !== "modify") {
      navigate("/passenger-info?sessionId=" + reviewData?.sessionId);
    } else if (isAuth && bookingType === "modify") {
      modifyBooking(bookingId, { sessionId }).then(({ data }) => {
        dispatch(bookingIdHandler(data));
        window.location.href = data;
      });
    } else {
      setLoginDrawer({ bottom: true });
    }
  };

  const userData = localStorage.getItem("user");

  useEffect(() => {
    verifyToken()
      .then(({ data }) => setIsAuth(data))
      .catch((err) => {
        setIsAuth(false);
      });
  }, [userData]);

  return (
    <div>
      <Navbar
        color={white500}
        bgColor={primary500}
        label={"Review your Itinerary"}
        showMenu={false}
        onClose={() => navigate(-1)}
      />
      <div className='py-2 border-bottom'>
        <Container>
          <Grid container>
            <Grid item xs={1}>
              <img src={FlightUp} alt='flightup' />
            </Grid>
            <Grid item xs={11}>
              {departure?.origin?.city} to {departure?.destination?.city}
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container className='border-bottom'>
        <FlightDetails
          aircraft={departure?.aircraft}
          departureDate={departure?.departureDate}
          departureTime={departure?.departureTime}
          departureTerminal={departure?.terminals}
          arrivalDate={departure?.arrivalDate}
          arrivalTime={departure?.arrivalTime}
          duration={departure?.duration}
          flightNumber={departure?.flightNumber}
          departureCity={departure?.origin?.city}
          arrivalCity={departure?.destination?.city}
          subclass={departure?.subclass}
        />
      </Container>
      {arrival && (
        <div className='py-2 border-bottom'>
          <Container>
            <Grid container>
              <Grid item xs={1}>
                <img src={FlighDown} alt='flightup' />
              </Grid>
              <Grid item xs={11}>
                {arrival?.origin?.city} to {arrival?.destination?.city}
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
      {arrival && (
        <Container className='border-bottom'>
          <FlightDetails
            aircraft={arrival?.aircraft}
            departureDate={arrival?.departureDate}
            departureTime={arrival?.departureTime}
            departureTerminal={arrival?.terminals}
            arrivalDate={arrival?.arrivalDate}
            arrivalTime={arrival?.arrivalTime}
            duration={arrival?.duration}
            flightNumber={arrival?.flightNumber}
            departureCity={arrival?.origin.city}
            arrivalCity={arrival?.destination?.city}
            subclass={arrival?.subclass}
          />
        </Container>
      )}
      <div>
        <MobileAmountFooter
          label={isAuth ? "Continue" : "Login"}
          setCartDetailsDrawer={setCartDetailsDrawer}
          onClickHandler={onClickHandler}
          data={reviewData}
        />
        <CartDetails
          CartDetailsDrawer={CartDetailsDrawer}
          setCartDetailsDrawer={setCartDetailsDrawer}
          data={reviewData}
          usedIn={"review-page"}
        />
        <div className='login_drawer_container'>
          <MobileLogin
            loginDrawer={loginDrawer}
            setLoginDrawer={setLoginDrawer}
          />
        </div>
      </div>
    </div>
  );
};

const Divider = () => {
  return (
    <div className='grid'>
      <div className='middle'>
        {" "}
        <span>Change of Planes | 7h 35m layover in New Delhi</span>
      </div>
    </div>
  );
};

const FlightDetails = ({
  aircraft,
  departureDate,
  departureTime,
  departureTerminal,
  arrivalDate,
  arrivalTime,
  duration,
  flightNumber,
  departureCity,
  arrivalCity,
  subclass,
}) => {
  return (
    <div className='my-4'>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <p className='text-xxxs pt-1'>{aircraft}</p>
          <p className='text-xxxs pt-1'>{flightNumber?.slice(3)}</p>
          <p className='text-xxxs pt-1'>{subclass}</p>
        </Grid>
        <Grid item xs={3} className='pl-2'>
          <p className='text-xxxs pt-1'>{departureCity}</p>
          <p className='text-xxxs pt-1'>{departureTime}</p>
        </Grid>
        <Grid item xs={3}>
          <div className='text-center'>
            <img className='pt-1' src={clock} alt='clock' />
            <p className='text-xxxs pt-1'>{duration}</p>
          </div>
        </Grid>
        <Grid item xs={3} className='pl-2'>
          <p className='text-xxxs pt-1'>{arrivalCity}</p>
          <p className='text-xxxs pt-1'>{arrivalTime}</p>
        </Grid>
      </Grid>
    </div>
  );
};
