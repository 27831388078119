import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import searchReducer from "./searchReducer/search.reducer";
import { reducer as CartReducer } from "./CartReducer/cart.reducer";
import { reducer as UserReducer } from "./userReducer/user.reducer";
import { checkInReducer } from "./CheckInReducer/checkIn.reducer";

const persistConfig = {
  key: "FlyBig",
  storage: storageSession,
  whitelist: ["user", "checkIn"],
};

const appReducer = combineReducers({
  search: searchReducer,
  cart: CartReducer,
  user: UserReducer,
  checkIn: checkInReducer,
});

export default persistReducer(persistConfig, appReducer);
