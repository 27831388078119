import React, { useState } from "react";
import { Box } from "@mui/material";
import { LoginOtp } from "../../SignUp/Common/LoginOtp";
import { useWindowSize } from "../../../../utils/utils";

const Login = ({
  handleSignUp,
  submitDataHandler,
  type,
  otp,
  setOtp,
  verifyOtpHandler,
  loginForm,
  setLoginForm,
  showLoginOtp,
  setShowLoginOtp,
  sendOtpTime,
  resendOtpHandler,
  enableResendotp,
  handleClose,
}) => {
  const [timer, setTimer] = useState(120000);

  const [innerWidth] = useWindowSize();
  const isMobile = innerWidth < 640 && innerWidth !== 0;

  const customClass =
    type === "desktop"
      ? "login-form-section h-full flex flex-col justify-center bg-white p-8"
      : "login-form-section h-full flex flex-col justify-center bg-white";
  return (
    <>
      <section className={customClass}>
        <p
          className={`text-xl font-350 ${!isMobile ? "" : "text-center"}  mt-4`}
        >
          Login
        </p>

        <Box
          component="form"
          sx={{
            "& > :not(style)": {},
          }}
          noValidate
          autoComplete="off"
        >
          <LoginOtp
            loginForm={loginForm}
            setLoginForm={setLoginForm}
            otp={otp}
            setOtp={setOtp}
            loginHandler={submitDataHandler}
            verifyOtpHandler={verifyOtpHandler}
            timer={timer}
            setTimer={setTimer}
            showLoginOtp={showLoginOtp}
            setShowLoginOtp={setShowLoginOtp}
            sendOtpTime={sendOtpTime}
            resendOtpHandler={resendOtpHandler}
            enableResendotp={enableResendotp}
            type={type}
            handleClose={handleClose}
          />
        </Box>

        <p className="text-center mt-8 xs-mt-4">
          <span className="font-normal text-sm text-gray900 ">
            New to flybig?
          </span>
          <span
            className="font-normal text-base text-primary-700 ml-2 cursor-pointer pb-4"
            onClick={handleSignUp}
          >
            Sign Up
          </span>
        </p>
      </section>
    </>
  );
};

export default Login;
