import { Box, Divider, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fareDetailsCheckin } from "../../../../Api/Booking";
import { toastHandler } from "../../../../Redux/userReducer/user.action";
import { useUrlExtract } from "../../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { BsInfoCircle } from "react-icons/bs";

const FareDetailsCheckin = () => {
  const [faresData, setFaresData] = useState({});

  const { bookingId } = useUrlExtract("bookingId");
  const dispatch = useDispatch();
  const {
    user: { openToast },
  } = useSelector((state) => state);

  useEffect(() => {
    fareDetailsCheckin(bookingId)
      .then(({ data }) => {
        setFaresData(data);
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
      });
  }, []);

  return (
    <>
      <p className='mt-6 pl-6'>
        <span className='font350 text-base'>Fare Details</span>
        {/* <span className="text-xs">flyeasy</span> */}
      </p>

      <div className='fare-details-my-booking border p-4 ml-6 mt-4'>
        <p className='flex justify-between font325 text-xs mb-2 gotham-book'>
          <span>Fares Total</span>{" "}
          <span>
            {faresData?.fareDetails?.total -
              (faresData?.fareDetails?.convenienceFee +
                faresData?.fareDetails?.totalTaxAndFees?.total)}
          </span>
        </p>
        <p className='flex justify-between font325 text-xs mb-2 gotham-book'>
          <span>Convenience Fee</span>{" "}
          <span>{faresData?.fareDetails?.convenienceFee}</span>
        </p>
        <p className='flex justify-between font325 text-xs mb-2 gotham-book'>
          <span>
            Total Tax and Fees
            <Tooltip
              title={
                <Box>
                  {faresData?.fareDetails?.totalTaxAndFees &&
                    Object?.entries(faresData?.fareDetails?.totalTaxAndFees)
                      ?.filter((value) => value[1] !== 0)
                      ?.map((item) => {
                        return (
                          <p>{`${
                            item?.[0] === "terminalFee"
                              ? "Aviation security fee"
                              : item?.[0]
                          } : ${item?.[1]}`}</p>
                        );
                      })}
                </Box>
              }
              arrow
            >
              <span className='ml-2 mt-1 cursor-pointer'>
                <BsInfoCircle />
              </span>
            </Tooltip>
          </span>{" "}
          <span>{faresData?.fareDetails?.totalTaxAndFees?.total}</span>
        </p>
        <p className='flex justify-between font325 text-xs mb-2 gotham-book'>
          <span>Ancillary items</span>{" "}
          <span>{faresData?.webCheckinPayments}</span>
        </p>
        <Divider />
        <p className='flex justify-between font325 text-xs py-2'>
          <span>Total</span> <span>{faresData?.total}</span>
        </p>
      </div>
    </>
  );
};

export default FareDetailsCheckin;
