import React from "react";
import { Fleet } from "../components/Fleet/fleet";

export const FleetView = () => {

  return (
    <div>
      <Fleet />
    </div>
  );
};
