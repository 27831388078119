import React, { useEffect, useState } from 'react';
import { DATE_FORMAT } from '../../../utils/constant';
import Service from '../Common/Services';
import HeaderNavSearch from '../../searchResults/Desktop/headerResultPage/HeaderNavSearch';
import './DesktopAncillary.scss';
import flightUpSvg from '../../../assets/svgs/flight-up.svg';
import crossFlight from '../../../assets/svgs/crossMIniFlight.svg';
import { Checkbox, CircularProgress, Grid } from '@mui/material';
import { SelectedSeat } from '../../seatMap/Desktop/common/SelectedSeat';
import { postWebCheckIn, sendPayment } from '../../../Api/Booking';
import { getFormattedDate, useUrlExtract } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { SkeletonComp } from '../../common/Skeleton/Skeleton';
import { colorPalette } from '../../../theme/color-palette';
import { verifyToken } from '../../../Api/Auth';
import { LoginPage } from '../../Login-Signup/Login/LoginPage';
import { toastHandler } from '../../../Redux/userReducer/user.action';

export const DesktopAncillary = ({
  tripType,
  ancillaryHandler,
  allAncillaries,
  newItmAnc,
  allBookingData,
  webCheckInBody,
}) => {
  const [scheduleState, setScheduleState] = useState('');
  const [returnScheduleState, setReturnScheduleState] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [open, setOpen] = useState(false);
  const [signUpModal, setSignUpModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ancillaryFee, setAncillaryFee] = useState([]);

  const {
    user: { openToast },
    checkIn: { seatCheckIn, paxCheckIn },
  } = useSelector((state) => state);

  const { primary500 } = colorPalette;

  const userData = localStorage.getItem('user');
  const { bookingId } = useUrlExtract('bookingId');

  useEffect(() => {
    verifyToken()
      .then(({ data }) => setIsAuth(data))
      .catch((err) => {
        setIsAuth(false);
      });
  }, [userData]);

  useEffect(() => {
    if (allBookingData?.selectedFare?.schedule?.length > 0) {
      setScheduleState(allBookingData?.selectedFare?.schedule?.[0]);
    }
    if (allBookingData?.selectedFare?.returnSchedule?.length > 0) {
      setReturnScheduleState(allBookingData?.selectedFare?.returnSchedule?.[0]);
    }
  }, [allBookingData]);

  useEffect(() => {
    if (allAncillaries?.length) {
      const ancFee = allAncillaries?.filter((item) => item?.qty > 0);
      setAncillaryFee(ancFee);
    }
  }, [allAncillaries]);

  const {
    origin,
    destination,
    flightNumber,
    aircraft,
    departureTime,
    arrivalTime,
    departureDate,
    arrivalDate,
  } = scheduleState;

  const dispatch = useDispatch();

  const continueToReturnOrPayment = () => {
    setIsLoading(true);
    postWebCheckIn(bookingId, webCheckInBody)
      .then((data) => {
        window.location.href = data?.data;
      })
      .catch((err) => {
        setIsLoading(false);
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: 'error',
          })
        );
      });
  };

  const skipAncillary = () => {
    if (tripType === 'OneWay') {
      sendPayment(bookingId)
        .then(({ data }) => {
          window.location.href = data?.url;
        })
        .catch((err) =>
          dispatch(
            toastHandler({
              ...openToast,
              open: true,
              message: err?.response?.data?.message,
              severity: 'error',
            })
          )
        );
    }
  };

  const handleOpen = () => {
    setOpen(true);
    setSignUpModal(false);
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      {!allBookingData ? (
        <SkeletonComp
          width='80vw'
          height='80vh'
          varient='rectangular'
          customClass='w-full m-auto'
        />
      ) : (
        <>
          <HeaderNavSearch />
          <div className='container-seat-map-desk max-width w-full m-auto p-4'>
            <div className='onward-heading mt-4 mb-6'>
              <img src={flightUpSvg} alt='flight up' />
              <span className='text-base font350 ml-4'>
                {scheduleState ? (
                  <>
                    <span className='gotham-rounded'>Departure</span> -{' '}
                    <span className='gotham-book'>
                      {origin?.city} to {destination?.city}
                    </span>
                  </>
                ) : (
                  <SkeletonComp />
                )}
              </span>
            </div>

            <div className='flight-details-box w-full m-auto max-width border'>
              <div className='flight-details-header border uppercase flex bg-black text-white'>
                <span className='font-normal text-xs ml-6'>Flight Details</span>
                <span className='text-xs font-light ml-8'>
                  FLIGHT NO - {flightNumber}
                </span>
                <span className='text-xs font-light ml-8'>{aircraft}</span>
              </div>

              <div className='depart-arrive-info mt-2'>
                <p className='text-sm font350 flex justify-between pl-20 pr-43'>
                  <span>DEPARTURE</span>
                  <span>ARRIVAL</span>
                </p>
                <p className='text-sm font350 text-primary flex justify-between pl-20 pr-43'>
                  <span>{departureTime}</span>
                  <span>{arrivalTime}</span>
                </p>

                <section className='relative flght-dtls-cross-svg-section'>
                  <div className='border-line flex justify-between'>
                    <span className='dot-before absolute'></span>
                    <span className='line'></span>
                    <img
                      src={crossFlight}
                      alt='flight icon'
                      className='absolute flght-dtls-cross-svg z-1'
                    />
                    <span className='dot-after absolute'></span>
                  </div>
                </section>

                <div className='cities-dtls flex justify-between mt-4 pl-20 pr-20'>
                  <section>
                    <p className='font-350 text-base review-citycode text-white bg-black text-center'>
                      {origin?.code}
                    </p>

                    <p className='font-normal text-xs mb-1 review-cityname uppercase'>
                      {origin?.city} airport
                    </p>

                    <p className='font-light text-xxs mb-1 gotham-book'>
                      {getFormattedDate(departureDate, DATE_FORMAT?.DATE8)}
                    </p>
                  </section>
                  <section>
                    <p className='font-350 text-base review-citycode text-white bg-black text-center'>
                      {destination?.code}
                    </p>
                    <p className='font-normal text-xs mb-1 review-cityname uppercase'>
                      {destination?.city} airport
                    </p>
                    <p className='font-light text-xxs mb-1 gotham-book'>
                      {getFormattedDate(arrivalDate, DATE_FORMAT?.DATE8)}
                    </p>
                  </section>
                </div>
              </div>
            </div>

            <div className='select-seat-sub-headborder font350 mt-8 mb-8'>
              <p className=' text-base'>Select Your Ancillaries</p>
              {/* <p className=' text-xxs'>Add passenger</p> */}
            </div>

            <Grid container className='flex justify-between'>
              {allBookingData ? (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  className='selected-seat-info-box border'
                >
                  <div>
                    <SelectedSeat
                      flightSvg={flightUpSvg}
                      origin={origin?.city}
                      destination={destination?.city}
                      data={paxCheckIn}
                    />
                  </div>
                </Grid>
              ) : (
                <skeletonComp />
              )}
              {allAncillaries?.length ? (
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={4}
                  className='meal-options-layout-container'
                >
                  {allAncillaries?.map((item, index) => (
                    <Service
                      item={item}
                      index={index}
                      key={index}
                      ancillaryHandler={ancillaryHandler}
                    />
                  ))}
                </Grid>
              ) : (
                <SkeletonComp />
              )}
              <div>
                <Grid item lg={3} md={3} sm={3}>
                  <div className='fare-summary-box-st border w-full h-full'>
                    <section className='fee-surcharge-div flex items-center pl-2'>
                      <span className='font350 text-sm ml-2'>
                        Ancillary Services
                      </span>
                    </section>
                    <p className='font350 text-xxs flex justify-between line-height17 m-2'>
                      <span className='pl-6'>Seats</span>
                      <span className='pr-6'>₹ {seatCheckIn?.[0]?.price}</span>
                    </p>
                    <p>
                      {ancillaryFee?.map((item, index) => {
                        return (
                          <p className='font350 text-xxs flex justify-between line-height17 m-2'>
                            <span className='pl-6'>
                              {item?.name} {'    '}({item?.qty})
                            </span>
                            <span className='pr-6'>
                              ₹ {item?.fee * item?.qty}
                            </span>
                          </p>
                        );
                      })}
                    </p>
                  </div>
                </Grid>
              </div>
            </Grid>

            <div className='w-full max-width m-auto mt-16'>
              <div className='flex items-center justify-center mb-4'>
                <Checkbox
                  size='small'
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                  sx={{
                    '&.Mui-checked': {
                      color: primary500,
                    },
                  }}
                />
                <p className='text-xxs font-normal text-center line-full'>
                  By clicking "Continue" you agree to our Terms and Conditions :{' '}
                  <a
                    href='https://www.s9devflg.flybig.in/terms-and-condition'
                    className='text-link500 cursor-pointer text-xxs font-normal'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {' '}
                    Terms Conditions
                  </a>
                </p>
              </div>
              <div className='btn-group-seatmap flex justify-center items-center'>
                {/* <button
                  className={`uppercase btn-skip ${
                    ancillaryStep === 4 && !isChecked
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                  }`}
                  onClick={skipAncillary}
                  disabled={ancillaryStep === 4 && !isChecked}
                >
                  Skip
                </button> */}
                <button
                  className='uppercase btn-continue ml-6 cursor-pointer'
                  onClick={isAuth ? continueToReturnOrPayment : handleOpen}
                  disabled={!isChecked}
                >
                  {isAuth ? 'Continue' : 'Login to continue'}
                  {isLoading && (
                    <span className='ml-4'>
                      <CircularProgress color='inherit' size={20} />
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <LoginPage
        open={open}
        signUpModal={signUpModal}
        setSignUpModal={setSignUpModal}
        handleClose={handleClose}
      />
    </>
  );
};
