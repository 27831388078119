import React from "react";
import MuiButton from "@mui/material/Button";
import styled from "styled-components";
import { colorPalette } from "../../../theme/color-palette";

const { gray600 } = colorPalette;

export const Button = ({
  label,
  bgColor,
  color,
  size,
  variant,
  width,
  onClickHandler,
  disabled,
  fontWeight,
  borderRadius,
  height,
  border,
}) => {
  return (
    <CustomButton
      onClick={onClickHandler}
      fontWeight={fontWeight}
      width={width}
      textcolor={color}
      bgcolor={disabled ? gray600 : bgColor}
      variant={variant}
      size={size}
      disabled={disabled}
      borderRadius={borderRadius}
      height={height}
    >
      {label}
    </CustomButton>
  );
};

const CustomButton = styled(MuiButton)`
  background: ${({ bgcolor }) => bgcolor} !important;
  color: ${({ textcolor }) => textcolor} !important;
  width: ${({ width }) => width} !important;
  font-weight: ${({ fontWeight }) => fontWeight} !important;
  border-radius: 2px;
`;
// font-family: gotham rounded !important;
// border-radius: ${(borderRadius) => borderRadius};
// box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
// border: 1px solid rgba(0, 0, 0, 0.12);
