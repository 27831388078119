import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { verifyToken } from "../../../../Api/Auth";
import { getBookingByGroup } from "../../../../Api/Booking";
import { useNavigate } from "react-router-dom";
import { toastHandler } from "../../../../Redux/userReducer/user.action";
import { BookingListItem } from "./BookingListItem";
import "../MobileProfile.scss";
import { MOBILE_MYBOOKINGS_TAB } from "../../../../utils/constant";
import styled from "styled-components";
import { colorPalette } from "../../../../theme/color-palette";
import { Navbar } from "../../../Navbar/Mobile";
import logo from "../../../../assets/svgs/logo.svg";

const { black500, primary500, gray600, black400, white500 } = colorPalette;

const MyBooking = () => {
  const userData = localStorage.getItem("user");
  const [isAuth, setIsAuth] = useState(() => (userData ? true : false));
  const [bookingDataByStage, setBookingDataByStage] = useState([]);
  const [selectionStep, setSelectionStep] = useState(1);

  const dispatch = useNavigate();
  const navigate = useNavigate();
  const {
    user: { openToast },
  } = useSelector((state) => state);

  const stageName =
    selectionStep === 1
      ? "upcoming"
      : selectionStep === 2
      ? "completed"
      : selectionStep === 3
      ? "cancelled"
      : "";

  useEffect(() => {
    verifyToken()
      .then(({ data }) => setIsAuth(data))
      .catch((err) => {
        setIsAuth(false);
      });
  }, [userData]);

  useEffect(() => {
    isAuth &&
      getBookingByGroup(stageName)
        .then(({ data }) => {
          setBookingDataByStage(data);
        })
        .catch((err) => {
          dispatch(
            toastHandler({
              ...openToast,
              open: true,
              message:
                err?.response?.data?.message || "Something went wrong...",
              severity: "error",
            })
          );
        });
  }, []);

  const handleChangeTabs = (newValue) => {
    setSelectionStep(newValue);
    const stageName =
      newValue === 1
        ? "upcoming"
        : newValue === 2
        ? "completed"
        : newValue === 3
        ? "cancelled"
        : "";
    getBookingByGroup(stageName)
      .then(({ data }) => {
        setBookingDataByStage(data);
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message || "Something went wrong...",
            severity: "error",
          })
        );
      });
  };

  return (
    <>
      <Navbar
        color={white500}
        bgColor={primary500}
        showMenu={true}
        icon={logo}
        onClose={() => navigate(-1)}
      />
      <Grid container>
        {MOBILE_MYBOOKINGS_TAB?.map((item, index) => (
          <Tab
            onClick={() => handleChangeTabs(index + 1)}
            item
            key={index}
            selected={index + 1 === selectionStep}
            xs={4}
          >
            <Container>
              <div className='flex items-center'>
                <span className='ml-2'>{item}</span>
              </div>
            </Container>
          </Tab>
        ))}
        <Grid item xs={12} className='p-4'>
          <div>
            {bookingDataByStage?.length ? (
              bookingDataByStage?.map((item) => {
                return (
                  <div key={item?.id} className='mb-4'>
                    <BookingListItem data={item} />
                  </div>
                );
              })
            ) : (
              <div>
                <p>Nothing to show</p>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default MyBooking;

const Tab = styled(Grid)`
  color: ${({ selected }) => (selected ? black500 : gray600)};
  text-align: center;
  font-size: 14px;
  border-bottom: ${({ selected }) =>
    selected ? "3px solid" + primary500 : "1px solid" + black400};
  padding: 14px 0px;
  &:nth-child(2),
  &:nth-child(3) {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
`;
