import React from "react";
import { NoresultsPage } from "../components/NoResultsPage/NoresultsPage";

export function NoResultView() {
  return (
    <>
      <NoresultsPage />
    </>
  );
}
