import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { BookingsTable } from "../common/BookingsTable";

export const MyBooking = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTabs = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    if (tabValue === index) {
      return {
        id: `simple-tab-${index}-bookings`,
        className: `active-tab-custom-booking`,
      };
    } else {
      return { id: `simple-tab-${index}-bookings` };
    }
  }

  function TabPanel(props) {
    const { children, value, index } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <>{children}</>
          </Box>
        )}
      </div>
    );
  }

  return (
    <div>
      <div className='my-bookings-tab-wrapper'>
        <p className='font350 text-xl mb-4'>My Bookings</p>
      </div>

      <Box className='w-max-desk m-auto mt-8 tabs-box-bookings'>
        <Box className='tabs-box-booking-tabs m-auto'>
          <Tabs
            value={tabValue}
            onChange={handleChangeTabs}
            indicatorColor='none'
          >
            <Tab label='Upcoming Bookings' {...a11yProps(0)} />
            <Tab label='Completed Bookings' {...a11yProps(1)} />
            <Tab label='Cancelled Bookings' {...a11yProps(2)} />
          </Tabs>
        </Box>
        <main className='my-booking-tabpanel'>
          <TabPanel value={tabValue} index={0}>
            <BookingsTable bookingType='upcoming' />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <BookingsTable bookingType='completed' />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <BookingsTable bookingType='cancelled' />
          </TabPanel>
        </main>
      </Box>
    </div>
  );
};
