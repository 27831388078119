import "./App.scss";
import "./theme/stylesheet.scss";

import { HomeView } from "./views/HomeView";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PassengerInfoView } from "./views/PassengerInfoView";
import { SearchView } from "./views/SearchView";
import { ReviewPageView } from "./views/ReviewPageView";
import { NoResultView } from "./views/NoResultView";
import { BookingConfirmView } from "./views/BookingConfirmView";
import { AboutUsView } from "./views/AboutUsView";
import { FAQView } from "./views/faqView";
import { ContactView } from "./views/contactView";
import { FleetView } from "./views/fleetView";
import { OffersView } from "./views/OffersView";
import { OfferDetailView } from "./views/OfferDetailView";
import * as Sentry from "@sentry/react";
import { ErrorFallback } from "./views/ErrorBoundaryView";
import { CheckInView } from "./views/CheckInView";
import { SeatMapView } from "./views/seatMapView";
import { AncillaryView } from "./views/AncillaryView";
import { MyBookingsView } from "./views/MyBookingsView";
import { MyProfileView } from "./views/MyProfileView";
import { BookingItinerary } from "./views/BookingItinerary";
import CancelBookingView from "./views/CancelBookingView";
import ModifyBookingView from "./views/ModifyBookingView";
import { gapi } from "gapi-script";
import TermsConditionView from "./views/TermsConditionView";
import PrivacyView from "./views/PrivacyView";
import { ASSISTANCE_DATA, ASSISTANCE_SIDEDRAW } from "./utils/constant";
import CocView from "./views/CocView";
import DisabilityView from "./views/DisabilityView";
import WeatherAdvisory from "./views/WeatherAdvisory";

function App() {
  gapi.load("client:auth2", () => {
    gapi.client.init({
      clientId: process.env.REACT_APP_GOOGLE_ID,
      plugin_name: "chat",
    });
  });

  return (
    <BrowserRouter>
      <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
        {/* <Header/> */}
        <Routes>
          {/* <Route path="/HeaderFrame" exact element={<Header />} /> */}
          {/* <Route path="/FooterFrame" exact element={<Footer />} /> */}
          <Route path="/" exact element={<HomeView />} />
          <Route path="/search-result" element={<SearchView />} />
          <Route path="/no-search-result" element={<NoResultView />} />
          <Route path="/review-flight" element={<ReviewPageView />} />
          <Route path="/passenger-info" element={<PassengerInfoView />} />
          <Route path="/confirmation-page" element={<BookingConfirmView />} />
          <Route path="/about-us" element={<AboutUsView />} />
          <Route path="/faq" element={<FAQView />} />
          <Route path="/contact" element={<ContactView />} />
          <Route path="/fleet" element={<FleetView />} />
          <Route path="/offers" element={<OffersView />} />
          <Route path="/OfferDetails" element={<OfferDetailView />} />
          <Route path="/check-in" element={<CheckInView />} />
          <Route path="/seatMap" element={<SeatMapView />} />
          <Route path="/ancillary" element={<AncillaryView />} />
          <Route path="/my-bookings" element={<MyBookingsView />} />
          <Route path="/my-profile" element={<MyProfileView />} />
          <Route path="/my-bookings-itinerary" element={<BookingItinerary />} />
          <Route path="/cancel-booking" element={<CancelBookingView />} />
          <Route path="/modify-booking" element={<ModifyBookingView />} />
          <Route path="/privacy" element={<PrivacyView />} />
          <Route path="/coc" element={<CocView />} />
          <Route path="/terms-and-condition" element={<TermsConditionView />} />
          <Route
            path="/special-disability-asstistance"
            element={<DisabilityView />}
          />
          <Route path="/weather-advisory" element={<WeatherAdvisory />} />
          <Route
            path="/assistance"
            element={
              <TermsConditionView
                heading={ASSISTANCE_SIDEDRAW}
                data={ASSISTANCE_DATA}
              />
            }
          />
        </Routes>

        {/*<Footer />*/}
      </Sentry.ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
