import React from "react";
import Login from "../Common/Login";
import "../LoginPage.scss";

const MobileLogin = ({
  signUpHandler,
  userData,
  setUserData,
  submitDataHandler,
  otp,
  setOtp,
  verifyOtpHandler,
  loginForm,
  setLoginForm,
  showLoginOtp,
  countryCodeList,
  handleClose
}) => {
  return (
    <div>
      <Login
        userData={userData}
        setUserData={setUserData}
        handleSignUp={signUpHandler}
        submitDataHandler={submitDataHandler}
        verifyOtpHandler={verifyOtpHandler}
        otp={otp}
        setOtp={setOtp}
        loginForm={loginForm}
        setLoginForm={setLoginForm}
        showLoginOtp={showLoginOtp}
        countryCodeList={countryCodeList}
        handleClose={handleClose}
      />
    </div>
  );
};

export default MobileLogin;
