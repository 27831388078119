import React from "react";
import "./footer.scss";
import footerFlybigLogoSvg from "../../assets/svgs/footer-flybig-logo.svg";
import footerFlybigLogo from "../../assets/images/flybig-logo.png";
import { FOOTERLINKS, SOCIAL_LINKS } from "../../utils/constant";
import { useSelector } from "react-redux";
import { Divider } from "@mui/material";
import { colorPalette } from "../../theme/color-palette";
import { useWindowSize } from "../../utils/utils";

export const Footer = () => {
  const [innerWidth] = useWindowSize();
  const isMobile = innerWidth < 640 && innerWidth !== 0;
  let pathname = window.location.pathname;

  const footerHeading = (name) => {
    switch (name) {
      case "Services":
        return "Services";
      case "travel_inforamtion":
        return "Travel Information";
      case "contact_us":
        return "Contact Us";
      case "Forms":
        return "Forms";
      default:
        return name;
    }
  };

  const loaderState = useSelector((state) => state?.cart?.loader);

  const { footerBorderColor } = colorPalette;

  const dividerStyle = {
    borderColor: footerBorderColor,
    borderStyle: "dotted",
  };

  if (isMobile && pathname !== "/") {
    return;
  }

  return (
    <>
      {!loaderState && (
        <footer className="w-full m-auto mt-10">
          <div className="footer-logo-div">
            <img
              src={footerFlybigLogo}
              alt="flybig logo"
              className="footer-logo"
              width="140"
            />
          </div>
          <Divider sx={dividerStyle} />
          <div className="footer-content">
            {Object.keys(FOOTERLINKS)?.map((item, index) => (
              <div key={index} className="footer-column">
                <h4 className="font-semibold">{footerHeading(item)}</h4>
                {FOOTERLINKS[item]?.map((val, index) => (
                  <a
                    key={index}
                    className="text-base font-thin gotham-book"
                    href={val?.url}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {val?.name}
                  </a>
                ))}
              </div>
            ))}
            <div className="footer-column">
              <h4 className="font-semibold">Contact Us</h4>
              <p className="text-base font-thin gotham-book font-100 mt-2 text-xs">
                Guest Helpline
              </p>
              <p className="mt-2">
                <a
                  className="text-base font-thin gotham-book font-100  text-xs"
                  href="tel://+919910655655"
                >
                  +91 9910 655 655
                </a>
              </p>
              <p className="mt-2">
                <a
                  className="text-base font-thin gotham-book font-100  text-xs"
                  href="/privacy"
                >
                  Privacy Policy
                </a>
              </p>
              <p className="mt-2">
                <a
                  className="text-base font-thin gotham-book font-100 text-xs"
                  href="/terms-and-condition"
                >
                  Terms & Conditions
                </a>
              </p>
            </div>
          </div>
          <Divider sx={{ borderColor: footerBorderColor }} />
          <div className="copyright-info">
            <p className="text-center">© 2024 flybig. All rights reserved.</p>

            <div className="footer-social-icons">
              {SOCIAL_LINKS.map((item) => {
                return (
                  <a
                    key={item.id}
                    href={item.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={item.img} alt={item.alt} />
                  </a>
                );
              })}
            </div>
          </div>
        </footer>
      )}
    </>
  );
};
