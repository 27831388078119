import React from "react";
import { useSelector } from "react-redux";

export const DesktopToast = ({ type, toastMessage }) => {
  const {
    user: { cancelledBooking },
  } = useSelector((state) => state);

  return (
    <div
      className={`w-full flex justify-center items-center h-12 ${
        type === "success" ? "bg-green200" : "bg-red100"
      }`}
    >
      <section className='bg-white w-20 text-center py-1 h-6 border-16 mr-4'>
        <p
          className={`font-350 text-xs ${
            type === "success" ? "text-green900" : "text-red900"
          }`}
        >
          {type === "success" ? "Success" : "Error"}
        </p>
      </section>
      <section className={type === "success" ? "text-green900" : "text-red900"}>
        {type === "success" &&
        (toastMessage === "email" || toastMessage === "sms")
          ? `${toastMessage} Sent Successfully. Please Check Your ${toastMessage}`
          : type === "failure" &&
            (toastMessage === "email" || toastMessage === "sms")
          ? "There was a problem with that action"
          : ""}
        {type === "success" && toastMessage === "cancelBooking"
          ? `Your booking has been cancelled. The refund amount of ${cancelledBooking?.data?.refundAmount} will be credited into your source payment method in 5-7 working days.`
          : toastMessage === "cancelBooking"
          ? "There was a problem please try after some time."
          : ""}
      </section>
    </div>
  );
};
