import { useEffect, useState } from "react";
import { colorPalette } from "../../../../theme/color-palette";
import { CustomDrawer } from "../../../common/Drawer";
import SignUp from "../../../Login-Signup/SignUp/Mobile";
import { Navbar } from "../../../Navbar/Mobile";
import MobileLogin from "../../../Login-Signup/Login/Mobile";
import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  successHandler,
  toastHandler,
} from "../../../../Redux/userReducer/user.action";
import { loginOtp, registerOtp, verifyOtp } from "../../../../Api/Auth";
import { Country } from "country-state-city";

const MobileLoginDrawer = ({ loginDrawer, setLoginDrawer }) => {
  const dispatch = useDispatch();
  const {
    user: { openToast },
  } = useSelector((state) => state);
  const { white500, primary500 } = colorPalette;
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState({
    mobileNumber: "",
    password: "",
    confirmPassword: "",
    title: "Mr",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    email: "",
    countryCode: "91",
  });
  const [otp, setOtp] = useState("");
  const [sendOtpData, setOtpData] = useState();
  const [showLoginOtp, setShowLoginOtp] = useState(false);
  const [showSignUpOtp, setShowSignUpOtp] = useState(false);
  const [loginForm, setLoginForm] = useState({
    countryCode: "+91",
    mobileNumber: "",
  });
  const [countryCodeList, setCountryCodeList] = useState([]);

  useEffect(() => {
    let countryCode = Country.getAllCountries()?.map((item) => item.phonecode);
    setCountryCodeList(countryCode);
  }, []);

  const loginHandler = () => {
    loginOtp({
      phone: {
        countryCode: loginForm.countryCode,
        number: loginForm?.mobileNumber,
      },
    })
      .then(({ data }) => {
        setOtpData(data);
        setShowLoginOtp(true);
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
      });
  };

  const registerOtpHandler = () => {
    registerOtp({
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.email,
      phone: {
        countryCode: userData?.countryCode,
        number: userData?.mobileNumber,
      },
    })
      .then(({ data }) => {
        setOtpData(data);
        setShowSignUpOtp(true);
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
      });
  };

  const submitDataHandler = (e) => {
    e.preventDefault();
    if (step === 1) {
      loginHandler();
    } else {
      registerOtpHandler();
    }
  };

  const verifyOtpHandler = () => {
    verifyOtp({
      id: sendOtpData?.otp?.id,
      otp: otp.toString(),
      phone: {
        countryCode: userData?.countryCode || loginForm.countryCode,
        number: userData?.mobileNumber || loginForm?.mobileNumber,
      },
    })
      .then(({ data }) => {
        localStorage.setItem("user", JSON.stringify(data));
        dispatch(successHandler());
        setLoginDrawer({ bottom: false });
        setOtp("");
      })
      .catch((err) => {
        setOtp("");
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
      });
  };

  return (
    <div>
      <CustomDrawer
        position={"bottom"}
        state={loginDrawer}
        setState={(val) => setLoginDrawer(val)}
        label={""}
      >
        <div>
          <Navbar
            color={white500}
            bgColor={primary500}
            label={step === 1 ? "Login" : "SignUp"}
            showMenu={false}
            onClose={() => {
              setStep(1);
              setLoginDrawer({ bottom: false });
              setShowLoginOtp(false);
            }}
          />
        </div>
        <Container>
          <div className='login_drawer_container'>
            {step === 1 ? (
              <MobileLogin
                userData={userData}
                setUserData={setUserData}
                submitDataHandler={submitDataHandler}
                signUpHandler={() => setStep(2)}
                verifyOtpHandler={verifyOtpHandler}
                otp={otp}
                setOtp={setOtp}
                loginForm={loginForm}
                setLoginForm={setLoginForm}
                showLoginOtp={showLoginOtp}
                countryCodeList={countryCodeList}
                handleClose={()=>setLoginDrawer({bottom:false})}
              />
            ) : (
              <SignUp
                userData={userData}
                setUserData={setUserData}
                sendOtp={registerOtpHandler}
                verifyOtpHandler={verifyOtpHandler}
                otp={otp}
                setOtp={setOtp}
                showSignUpOtp={showSignUpOtp}
                setShowSignUpOtp={setShowSignUpOtp}
                countryCodeList={countryCodeList}
              />
            )}
          </div>
        </Container>
      </CustomDrawer>
    </div>
  );
};

export default MobileLoginDrawer;
