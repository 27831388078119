import { Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getProfile } from "../../../Api/Profile";
import { emailValidate } from "../../../utils/constant";

const PassengerContact = ({ contactInfo, setContactInfo }) => {
  const [isValidEmail, setIsValidEmail] = useState(false);
  useEffect(() => {
    setIsValidEmail(emailValidate.test(contactInfo?.email));
  }, [contactInfo]);

  useEffect(() => {
    getProfile()
      .then(({ data }) => {
        (data?.email || data?.phone?.number) &&
          setContactInfo({
            ...contactInfo,
            email: data?.email,
            contact: data?.phone,
          });
      })
  }, []);

  return (
    <div>
      <p className='font-semibold text-base mt-6 mb-4'>
        Booking details will be sent to
      </p>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} xs={12} sm={12} className='w-full'>
          <p className='text-xxs font350 text-gray700 mb-2'>Mobile Number</p>
          <PhoneInput
            country={"in"}
            value={
              `${contactInfo?.contact?.countryCode}${contactInfo?.contact?.number}`
            }
            inputProps={{
              required: true,
              autoFocus: true,
            }}
            onChange={(value, data, event, formattedValue) => {
              setContactInfo({
                ...contactInfo,
                contact: {
                  countryCode: data?.dialCode,
                  number: value?.slice(data?.dialCode?.length),
                },
              });
            }}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={4} xs={12}>
          <p className='text-xxs font350 text-gray700 mb-2'>Email ID</p>
          <TextField
            type='email'
            variant='outlined'
            className='w-full'
            size='small'
            placeholder='Enter your email ...'
            value={contactInfo?.email}
            error={contactInfo?.email && !isValidEmail}
            helperText={
              contactInfo?.email && !isValidEmail ? "Please check email id" : ""
            }
            onChange={(e) => {
              setContactInfo({
                ...contactInfo,
                email: e.target.value,
              });
            }}
          />
        </Grid>
      </Grid>
      <div className='flex mt-4'>
        <input
          type='checkbox'
          name='Opt-in to receive your booking confirmation via whatsapp on this number'
          id='whatsapp-checkbox'
          defaultChecked
          value={contactInfo?.whatsappNotification}
          onChange={(e) => {
            setContactInfo({
              ...contactInfo,
              whatsappNotification: !contactInfo?.whatsappNotification,
            });
          }}
        />
        <label
          htmlFor='whatsapp-checkbox'
          className='font350 text-xs gotham-book ml-4'
        >
          Opt-in to receive your booking confirmation via whatsapp on this
          number
        </label>
      </div>
    </div>
  );
};

export default PassengerContact;
