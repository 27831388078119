import React from "react";

const PaxTable = ({ passengers, flightData }) => {
  return (
    <>
      <div className='bg-primary text-white w-28 p-2'>
        {flightData?.[0]?.origin?.code} - {flightData?.[0]?.destination?.code}
      </div>
      <table className='w-full'>
        <thead className='bg-gray300 font350 text-xs'>
          <tr>
            <th>GUEST NAME</th>
            <th>SEAT</th>
            <th>ADD ON</th>
          </tr>
        </thead>
        <tbody>
          {passengers?.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  {item?.firstName} {item?.lastName}
                </td>
                <td>{item?.seat}</td>
                <td>{item?.addOn}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default PaxTable;
