import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { verifyToken } from "../../../../Api/Auth";
import { getBookingByGroupExpanded } from "../../../../Api/Booking";
import { toastHandler } from "../../../../Redux/userReducer/user.action";
import { getFormattedDate } from "../../../../utils/utils";
import "./bookingTable.scss";

export const BookingsTable = ({ bookingType }) => {
  const userData = localStorage.getItem("user");
  const [bookingDataByStage, setBookingDataByStage] = useState([]);
  const [isAuth, setIsAuth] = useState(() => (userData ? true : false));

  const navigate = useNavigate();
  const dispatch = useNavigate();
  const {
    user: { openToast },
  } = useSelector((state) => state);

  useEffect(() => {
    verifyToken()
      .then(({ data }) => setIsAuth(data))
      .catch((err) => {
        setIsAuth(false);
      });
  }, [userData]);

  useEffect(() => {
    isAuth &&
      getBookingByGroupExpanded(bookingType)
        .then(({ data }) => {
          setBookingDataByStage(data);
        })
        .catch((err) => {
          dispatch(
            toastHandler({
              ...openToast,
              open: true,
              message: err?.response?.data?.message,
              severity: "error",
            })
          );
        });
  }, []);

  const handleMyBookings = (item) => {
    navigate(`/my-bookings-itinerary?bookingId=${item?.id}`);
  };

  return (
    <table className="w-full">
      <thead className="bg-primary text-white font350 text-xs">
        <tr>
          <th>DATE</th>
          <th>PNR</th>
          <th>DEPARTURE</th>
          <th>ARRIVAL</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {bookingDataByStage?.map((item, index) => {
          return (
            <tr key={index}>
              <td>{getFormattedDate(item?.departureDate, "DD-MM-YYYY")}</td>
              <td>{item?.bookingId}</td>
              <td>{item?.origin}</td>
              <td>{item?.destination}</td>
              <td
                className={`text-link500 cursor-pointer`}
                onClick={() => handleMyBookings(item)}
              >
                Show itinerary
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
