import { Grid } from "@mui/material";
import React from "react";

export const FlybigContact = () => {
  return (
    <Grid
      container
      spacing={1}
      className='border p-4 flex justify-evenly w-max-desk m-auto'
    >
      <Grid item lg={5} md={5}>
        <p className='font350 text-xs text-gray600 mb-1'>Address</p>
        <p className='font350 text-xs line-height20'>
          Killa No. 13, 3rd Floor, Begumpur, Khatola, Gurugram - 122001,
          Haryana, India
        </p>
      </Grid>
      <Grid item lg={3} md={3}>
        <div className='mb-4'>
          <p className='font350 text-xs text-gray600 mb-1'>Company Name</p>
          <p className='font350 text-xs'>Big Charter Private Limited</p>
        </div>
        <div>
          <p className='font350 text-xs text-gray600 mb-1'>Guest Feedback</p>
          <p className='font350 text-xs'>guest.relations@flybig.in</p>
        </div>
      </Grid>
      <Grid item lg={3} md={3}>
        <div className='mb-4'>
          <p className='font350 text-xs text-gray600 mb-1'>Mobile</p>
          <p className='font350 text-xs'>91-9910 655 655</p>
        </div>
        <div>
          <p className='font350 text-xs text-gray600 mb-1'>Email</p>
          <p className='font350 text-xs'>guest.relations@flybig.in</p>
        </div>
      </Grid>
    </Grid>
  );
};
