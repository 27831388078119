import React from "react";
import { DATE_FORMAT } from "../../utils/constant";
import { getFormattedDate } from "../../utils/utils";
import { FlightType } from "../FlightType";

export const FlightDetailsComp = ({
  departureTime,
  origin,
  departureDate,
  arrivalDate,
  arrivalTime,
  destination,
}) => {
  const { DATE6 } = DATE_FORMAT;

  return (
    <div className='flex justify-around items-center'>
      <div className=''>
        <p className='text-base font-normal mb-2'>{departureTime}</p>
        {/* <p className=' '> */}
        <span className='bg-black text-white text-xs font-normal mt-1 p-1'>
          {origin?.code}
        </span>
        {/* </p> */}
        <p className='p2 text-base font-normal mt-2'>{origin?.city}</p>
        <p className='p4 font-light text-xxs mt-1 gotham-book'>
          {getFormattedDate(departureDate, DATE6)}
        </p>
      </div>
      <div className='flight-stops p-4 mt-8'>
        <FlightType type={"One Stop"} noOfStops={1} />
        {/* {route?.length > 2 && (
          <p className='text-center mt-4 gotham-book text-xs'>{route?.[1]}</p>
        )}{" "} */}
      </div>
      <div className=''>
        <p className='text-base font-normal mb-2'>{arrivalTime}</p>
        <span className='bg-black text-white text-xs font-normal mt-1 p-1'>
          {destination?.code}
        </span>
        {/* </p> */}
        <p className='p2 text-base font-normal mt-2'>{destination?.city}</p>
        <p className='p4 font-light text-xxs mt-1 gotham-book'>
          {getFormattedDate(arrivalDate, DATE6)}
        </p>
      </div>
    </div>
  );
};
