import React from "react";
import { colorPalette } from "../../../../../theme/color-palette";
import { CustomDrawer } from "../../../../common/Drawer";
import { Navbar } from "../../../../Navbar/Mobile";
import { SelectPassenger } from "../../../Common/Select-Passenger";

export const Passenger = ({
  paxDrawer,
  setPaxDrawer,
  passengerNumbersClone,
  handlePassengerValue,
  submitDataHandler,
  setPassengerNumbersClone,
  passengerNumbers,
}) => {
  const { black500, white500 } = colorPalette;

  return (
    <div>
      <CustomDrawer
        position={"bottom"}
        state={paxDrawer}
        setState={(val) => {
          setPaxDrawer(val);
          setPassengerNumbersClone({ ...passengerNumbers });
        }}
        label={""}
      >
        <div className='fixed bottom-52 w-full bg-white'>
          <Navbar
            color={black500}
            bgColor={white500}
            label={"Select Guest"}
            showMenu={false}
            onClose={(val) => {
              setPaxDrawer({ bottom: false });
              setPassengerNumbersClone({ ...passengerNumbers });
            }}
          />
          <div className='box-shadow'>
            <SelectPassenger
              buttonWidth={"100%"}
              passengerNumbers={passengerNumbersClone}
              setPassengerNumbers={setPassengerNumbersClone}
              handlePassengerValue={handlePassengerValue}
              handleClose={submitDataHandler}
              usedIn='mobile'
            />
          </div>
        </div>
      </CustomDrawer>
    </div>
  );
};
