import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { colorPalette } from "../../../theme/color-palette";
import { Navbar } from "../../Navbar/Mobile";
import { Profile } from "../common/Profile";
import logo from "../../../assets/svgs/logo.svg";
import { PREFIX_RADIO, PROFILE_HEADER } from "../../../utils/constant";
import styled from "styled-components";
import { Container, Grid } from "@mui/material";
import { CustomDrawer } from "../../common/Drawer";
// import TravellerForm from "../common/TravellerForm";
import { Travellers } from "../common/Travellers";

const { black500, gray600, primary500, black400, white500 } = colorPalette;

export const MobileProfile = () => {
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState({ bottom: false });
  const [salutaion, setSalutation] = useState(PREFIX_RADIO?.[0]);
  const [traveller, setTraveller] = useState("");

  const navigate = useNavigate();

  const handleSalutation = (e) => {
    setSalutation(e);
  };

  const handleTraveller = () => {
    setOpen({ bottom: true });
  };

  return (
    <div>
      <Navbar
        color={white500}
        bgColor={primary500}
        showMenu={true}
        icon={logo}
        onClose={() => navigate(-1)}
      />
      <Grid container>
        {PROFILE_HEADER?.map((item, index) => (
          <Tab
            key={index}
            onClick={() => setStep(index + 1)}
            item
            selected={index + 1 === step}
            xs={6}
          >
            {item}
          </Tab>
        ))}
      </Grid>
      {step === 1 ? (
        <Profile />
      ) : (
        <Travellers handleTraveller={handleTraveller} />
      )}

      <CustomDrawer
        position={"bottom"}
        state={open}
        setState={(val) => setOpen(val)}
        label={""}
      >
        <div className='h-screen'>
          <Navbar
            color={white500}
            bgColor={primary500}
            showMenu={false}
            onClose={() => setOpen({ bottom: false })}
          />
          <Container>
            {/* <TravellerForm
              salutaion={salutaion}
              handleSalutation={handleSalutation}
              traveller={traveller}
              setTraveller={setTraveller}
            /> */}
          </Container>
        </div>
      </CustomDrawer>
    </div>
  );
};

const Tab = styled(Grid)`
  color: ${({ selected }) => (selected ? black500 : gray600)};
  text-align: center;
  font-size: 14px;
  border-bottom: ${({ selected }) =>
    selected ? "3px solid" + primary500 : "1px solid" + black400};
  padding: 14px 0px;
  &:nth-child(2) {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
`;
