import React from "react";
import { ReviewPageDesk } from "./Desktop/ReviewPageDesk";
import { useWindowSize } from "../../utils/utils";
import { MobileReview } from "./Mobile";
import { useScrollTop } from "../../customHooks/useScrollTop";

export function ReviewPage({ reviewData }) {
  useScrollTop();
  const [innerWidth] = useWindowSize();
  const isMobile = innerWidth < 640 && innerWidth !== 0;

  return isMobile ? (
    <MobileReview reviewData={reviewData} />
  ) : (
    <ReviewPageDesk />
  );
}
