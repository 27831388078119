import React, { useState } from 'react';
import flightUpSvg from '../../../../assets/svgs/flight-up.svg';
import { Navbar } from '../../../Navbar/Desktop/Navbar';
import flybigLogoSearchNav from '../../../../assets/svgs/flybigLogoSearchNav.svg';
import { Grid, Popover } from '@mui/material';
import { FlightDetailsComp } from '../../../common/FlightDetails';
import { Button } from '../../../common/Button';
import { colorPalette } from '../../../../theme/color-palette';
import { useNavigate } from 'react-router-dom';
import { getFormattedDate } from '../../../../utils/utils';
import { DATE_FORMAT } from '../../../../utils/constant';
import { FlybigContact } from '../../../common/FlybigContact';
import calenderSvg from '../../../../assets/svgs/primaryCalenderSvg.svg';
import { DateRange } from '../../../common/DateRange';
import moment from 'moment';
import { searchfareCalender } from '../../../../Api/search';
import { useEffect } from 'react';

export const ModifyBooking = ({ bookingData }) => {
  const [anchorElDepartDate, setAnchorElDepartDate] = useState(null);
  const [anchorElReturnDate, setAnchorElReturnDate] = useState(null);
  const [date, setDate] = useState({
    from: new Date(),
    to: new Date(),
    enteredTo: new Date(),
  });
  const [fareCalender, setFareCalender] = useState({});

  useEffect(() => {
    setDate({
      from: new Date(bookingData?.departureDate),
      to: bookingData?.returnDate
        ? new Date(bookingData?.returnDate)
        : new Date(),
    });
  }, [bookingData]);

  const navigate = useNavigate();

  const {
    bookingId,
    payment,
    routes,
    selectedFare,
    status,
    tripType,
    origin,
    destination,
    id,
  } = bookingData;

  const { DATE9, DATE3 } = DATE_FORMAT;
  const { primary500, white500 } = colorPalette;
  const { from, to } = date;

  const handleModifyBookingDate = () => {
    const itineraryUrl =
      tripType === 'OneWay'
        ? `${origin}-${destination}-${getFormattedDate(
            date?.from,
            DATE_FORMAT?.DATE4
          )}`
        : `${origin}-${destination}-${getFormattedDate(
            date?.from,
            DATE_FORMAT?.DATE4
          )}_${destination}-${origin}-${getFormattedDate(
            date?.to,
            DATE_FORMAT?.DATE4
          )}`;

    navigate(
      `/search-result?tripType=${tripType[0]}&itinerary=${itineraryUrl}&paxType=A-1_C-0_I-0&bookingType=modify&bookingId=${id}`
    );
  };

  const handleRetriveitinerary = () => {
    navigate('/my-bookings');
  };

  const handleClickDapartDate = (event) => {
    setDate({ from: from, to: to });
    setAnchorElDepartDate(event.currentTarget);

    searchfareCalender(origin, destination).then(({ data }) => {
      setFareCalender(data?.prices);
    });
  };

  const anchorElDepartDateOpen = Boolean(anchorElDepartDate);

  const singleDateHandlerDepart = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    setDate({
      ...date,
      from: day,
      to: day > to ? new Date(moment(day).add(1, 'days').toDate()) : to,
    });
    setAnchorElDepartDate(null);
  };

  const handleCloseReturnDate = () => {
    setAnchorElReturnDate(null);
  };

  const singleDateHandlerReturn = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    setDate({
      ...date,
      to: day,
    });
    setAnchorElReturnDate(null);
  };

  const handleClickReturnDate = (event) => {
    setDate({ ...date, from: from, to: to });
    setAnchorElReturnDate(event.currentTarget);

    searchfareCalender(destination, origin).then(({ data }) => {
      setFareCalender(data?.prices);
    });
  };

  return (
    <div className=' w-full m-auto'>
      <div className='nav-container-search-page bg-primary'>
        <Navbar logo={flybigLogoSearchNav} customClass='navbar-search' />
      </div>

      <div className='w-max-desk m-auto p-4 px-6'>
        <p className='font350 text-xl my-6'>Itinerary</p>

        <div className='itinerary-details-wrapper m-auto p-4'>
          <p className='flex  justify-between mb-4'>
            <span className='font325 text-xs text-gray600'>
              Booked on{' '}
              {getFormattedDate(bookingData?.bookingDate, 'DD MMM YYYY HH:MM')}
            </span>
            <span
              className='text-xs text-link500 cursor-pointer'
              onClick={handleRetriveitinerary}
            >
              Retrieve another Itinerary
            </span>
          </p>

          <Grid
            container
            className='itinerary-details-box border w-full h-full'
          >
            <Grid
              item
              lg={8}
              md={8}
              className='main-itinerary-details-box border p-6'
            >
              <div className='onward-heading mb-5'>
                <img src={flightUpSvg} alt='flight icon' />
                <span className='text-base ml-4'>Departure</span>
              </div>
              <p className='font350 text-xs text-gray600 mb-2'>
                PNR / Booking Reference
              </p>
              <p className='mb-4 flex items-center'>
                <span className='font350 text-base mr-4'>{bookingId}</span>
                <span
                  className={`font-normal text-xxs ${
                    status === 'cancelled' ? 'text-red' : 'text-green600'
                  }`}
                >
                  {status}
                </span>
              </p>
              <p className='font350 text-xs text-gray600'>
                Flight{' '}
                {getFormattedDate(routes?.departure?.departureDate, DATE9)},{' '}
                {routes?.departure?.departureTime} -{' '}
                {routes?.departure?.arrivalTime}
              </p>

              <div className='my-6'>
                <FlightDetailsComp
                  departureTime={selectedFare?.schedule?.[0]?.departureTime}
                  arrivalTime={selectedFare?.schedule?.[0]?.arrivalTime}
                  departureDate={selectedFare?.schedule?.[0]?.departureDate}
                  arrivalDate={selectedFare?.schedule?.[0]?.arrivalDate}
                  origin={selectedFare?.schedule?.[0]?.origin}
                  destination={selectedFare?.schedule?.[0]?.destination}
                />
              </div>

              {selectedFare?.returnSchedule && (
                <>
                  <hr style={{ color: '#d1d1d1' }} className='my-8'></hr>

                  <div className='onward-heading mb-5'>
                    <img src={flightUpSvg} alt='flight icon' />
                    <span className='text-base ml-4'>Return</span>
                  </div>

                  <p className='font350 text-xs text-gray600 mb-2'>
                    PNR / Booking Reference
                  </p>
                  <p className='mb-4 flex items-center'>
                    <span className='font350 text-base mr-4'>{bookingId}</span>
                    <span
                      className={`font-normal text-xxs ${
                        status === 'cancelled' ? 'text-red' : 'text-green600'
                      }`}
                    >
                      {status}
                    </span>
                  </p>
                  <p className='font350 text-xs text-gray600'>
                    Flight{' '}
                    {getFormattedDate(routes?.return?.departureDate, DATE9)},{' '}
                    {routes?.return?.departureTime} -{' '}
                    {routes?.return?.arrivalTime}
                  </p>

                  <div className='my-6'>
                    <FlightDetailsComp
                      departureTime={
                        selectedFare?.returnSchedule?.[0]?.departureTime
                      }
                      arrivalTime={
                        selectedFare?.returnSchedule?.[0]?.arrivalTime
                      }
                      departureDate={
                        selectedFare?.returnSchedule?.[0]?.departureDate
                      }
                      arrivalDate={
                        selectedFare?.returnSchedule?.[0]?.arrivalDate
                      }
                      origin={selectedFare?.returnSchedule?.[0]?.origin}
                      destination={
                        selectedFare?.returnSchedule?.[0]?.destination
                      }
                    />
                  </div>
                </>
              )}
            </Grid>

            <Grid
              item
              lg={4}
              md={4}
              className='itinerary-details-aside border p-6'
            >
              <div className='mb-3'>
                <p className='font350 text-xs text-gray600'>Payment Status</p>
                <p className='font350 text-base text-green600'>
                  {payment?.status}
                </p>
              </div>

              <div className='mt-6 mb-16 cursor-pointer'>
                <div
                  className='border m-auto p-3 cursor-pointer flex justify-between'
                  onClick={handleClickDapartDate}
                >
                  {getFormattedDate(date?.from, DATE3)}
                  <img src={calenderSvg} alt='calender' />
                </div>
                <Popover
                  open={anchorElDepartDateOpen}
                  anchorEl={anchorElDepartDate}
                  onClose={() => setAnchorElDepartDate(null)}
                >
                  <DateRange
                    state={date}
                    setState={setDate}
                    handleDayClick={singleDateHandlerDepart}
                    numberOfMonths={1}
                    canChangeMonth={true}
                    selectedDays={[from]}
                    fareCalender={fareCalender}
                  />
                </Popover>
                {tripType === 'RoundTrip' && (
                  <>
                    <div
                      className='border m-auto p-3 cursor-pointer flex justify-between mt-6'
                      onClick={handleClickReturnDate}
                    >
                      <span className='block'>
                        {to === '' ? '' : getFormattedDate(date?.to, DATE3)}
                      </span>
                      <img src={calenderSvg} alt='calender' />
                    </div>
                    <Popover
                      open={Boolean(anchorElReturnDate)}
                      anchorEl={anchorElReturnDate}
                      onClose={handleCloseReturnDate}
                    >
                      <DateRange
                        state={date}
                        setState={setDate}
                        handleDayClick={singleDateHandlerReturn}
                        numberOfMonths={1}
                        canChangeMonth={true}
                        disabledDays={{ before: from }}
                        selectedDays={[to]}
                        fareCalender={fareCalender}
                      />
                    </Popover>
                  </>
                )}
              </div>

              <Button
                label={'select date and continue'}
                bgColor={primary500}
                color={white500}
                width={'100%'}
                onClickHandler={handleModifyBookingDate}
                disabled={
                  tripType === 'RoundTrip'
                    ? !(Boolean(date?.from) && Boolean(date?.to))
                    : !Boolean(date?.from)
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className='w-max-desk m-auto'>
        <p className='font350 text-base my-6 px-6'>Contact Details</p>
        <div className='mt-4 mx-6'>
          <FlybigContact />
        </div>
      </div>
    </div>
  );
};
