import { Container, Grid } from "@mui/material";
import React from "react";
import { colorPalette } from "../../../../../theme/color-palette";
import { DATE_FORMAT, ROUNDTRIP } from "../../../../../utils/constant";
import { getFormattedDate } from "../../../../../utils/utils";
import { Button } from "../../../../common/Button";
import { DateRange } from "../../../../common/DateRange";
import { CustomDrawer } from "../../../../common/Drawer";
import { Navbar } from "../../../../Navbar/Mobile";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { DateUtils } from "react-day-picker";

const Calender = ({
  calenderDrawer,
  setCalenderDrawer,
  date,
  setDate,
  onClickHandler,
  setTripType,
  tripType,
  originalDate,
  fareCalender,
}) => {
  const { black500, white500, primary500 } = colorPalette;
  const { from, to, enteredTo } = date;
  const { DATE1 } = DATE_FORMAT;

  const handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    if (from && to && day >= from && day <= to) {
      handleResetClick();
      return;
    }
    if (isSelectingFirstDay(from, to, day)) {
      setDate({
        from: day,
        to: null,
        enteredTo: null,
      });
    } else {
      setDate({
        ...date,
        to: day,
        enteredTo: day,
      });
    }
  };

  const handleResetClick = () => {
    setDate({
      from: null,
      to: null,
      enteredTo: null, // Keep track of the last day for mouseEnter.
    });
  };

  const singleDateHandler = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    setDate({
      from: day,
      to: null,
      enteredTo: null,
    });
  };

  const isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  return (
    <div>
      <CustomDrawer
        position={"bottom"}
        state={calenderDrawer}
        setState={(val) => setCalenderDrawer(val)}
        label={""}
      >
        <div className="fixed top-0 w-full bg-white z-100 box-shadow">
          <Navbar
            color={black500}
            bgColor={white500}
            label={"Select Departure Date"}
            showMenu={false}
            onClose={(val) => {
              setCalenderDrawer({ bottom: false });
              setDate({ ...originalDate });
            }}
          />
        </div>
        <div className="mt-20 mb-34">
          <DateRange
            handleDayClick={
              tripType === ROUNDTRIP || tripType === "RoundTrip"
                ? handleDayClick
                : singleDateHandler
            }
            state={date}
            setState={setDate}
            numberOfMonths={12}
            canChangeMonth={false}
            fareCalender={fareCalender}
          />
        </div>
        <div className="fixed bottom-0 w-full bg-white z-100 box-shadow py-3">
          <Container>
            {window.location.pathname !== "/modify-booking" && (
              <div className="pb-2">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div className="bg-primary100 p-2 border-lightBlack">
                      <p className="text-xxxs text-primary pb-2">
                        DEPARTURE DATE
                      </p>
                      <h6 className="text-sm text-gray800">
                        {from ? getFormattedDate(from, DATE1) : "-"}
                      </h6>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="bg-primary100 p-2 border-lightBlack relative">
                      <p className="text-xxxs text-primary pb-2">
                        ADD RETURN DATE
                      </p>
                      <h6 className="text-sm text-gray800">
                        {tripType === ROUNDTRIP ? (
                          to || enteredTo ? (
                            getFormattedDate(to, DATE1)
                          ) : (
                            "-"
                          )
                        ) : (
                          <p
                            className="flex items-center"
                            onClick={() => setTripType(ROUNDTRIP)}
                          >
                            <span className="text-sm mr-1">+</span>{" "}
                            <span className="text-xs"> Add Return Date</span>
                          </p>
                        )}
                      </h6>
                      {tripType === ROUNDTRIP ? (
                        <CancelRoundedIcon
                          onClick={() => {
                            setTripType("ONE_WAY");
                            setDate({
                              ...date,
                              to: null,
                              enteredTo: null,
                            });
                          }}
                          className="calender_clear_return"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}
            <Button
              label={"Done"}
              color={white500}
              bgColor={primary500}
              size={"medium"}
              variant={"contained"}
              width={"100%"}
              disabled={
                from
                  ? tripType === ROUNDTRIP || tripType === "RoundTrip"
                    ? to
                      ? false
                      : true
                    : false
                  : true
              }
              onClickHandler={onClickHandler}
            />
          </Container>
        </div>
      </CustomDrawer>
    </div>
  );
};

export default Calender;
