import React from "react";
import CommonSeatMap from "../Common";

const MobileSeatMap = ({ selectedSeatHandler, seatmap }) => {
  return (
    <div>
      <CommonSeatMap
        selectedSeatHandler={selectedSeatHandler}
        seatMapData={seatmap}
      />
    </div>
  );
};

export default MobileSeatMap;
