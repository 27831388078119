import moment from "moment";
import {
  PASSENGER_INITIAL_STATE,
  ROUNDTRIP,
  ATTENDANT_TYPE,
} from "../../utils/constant";
import { searchActionType } from "./search.actionType";

const initialState = {
  flightList: [],
  searchDetails: {
    departure: "",
    arrival: "",
    attendantType: ATTENDANT_TYPE[0],
    passengerNumbers: {
      ...PASSENGER_INITIAL_STATE,
    },
    date: {
      from: new Date(),
      to: moment().add(1, "days").toDate(),
      enteredTo: moment().add(1, "days").toDate(),
    },
    tripType: ROUNDTRIP,
  },
};

const { FLIGHT_LIST, SEARCH_DETAILS, PASSENGER_NUMBER } = searchActionType;

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FLIGHT_LIST:
      return { ...state, flightList: payload };
    case SEARCH_DETAILS:
      return {
        ...state,
        searchDetails: JSON.parse(JSON.stringify(payload)),
      };
    case PASSENGER_NUMBER:
      let newSearchDetails = state.searchDetails;

      newSearchDetails.passengerNumbers = JSON.parse(JSON.stringify(payload));

      return { ...state, newSearchDetails };
    default:
      return state;
  }
};

export default reducer;
