import React from "react";
import { Navbar } from "../../../Navbar/Desktop/Navbar";
import flybigLogoSearchNav from "../../../../assets/svgs/flybigLogoSearchNav.svg";
import "./itinerary.scss";
import { Divider } from "@mui/material";
import { FlightDetailsComp } from "../../../common/FlightDetails";
import { DATE_FORMAT } from "../../../../utils/constant";
import { FlybigContact } from "../../../common/FlybigContact";
import { useNavigate } from "react-router-dom";
import { getFormattedDate, useUrlExtract } from "../../../../utils/utils";
import { Button } from "../../../common/Button";
import CancelModal from "../../common/CancelModal";
import { colorPalette } from "../../../../theme/color-palette";
import { DesktopToast } from "../../../common/Toast/DesktopToast";

export const CancelBooking = ({
  bookingData,
  cancelBooking,
  handleCancelModal,
  isCancelModal,
  handleCloseCancelModal,
  isCancelLoading,
  handleCancelBooking,
}) => {
  const navigate = useNavigate();
  const allUrl = useUrlExtract("all");

  const {
    bookingId,
    passengers,
    payment,
    routes,
    selectedFare,
    status,
    tickets,
  } = bookingData;
  const { DATE9 } = DATE_FORMAT;
  const { white500, orange900, primary500 } = colorPalette;

  const handleRetriveitinerary = () => {
    navigate("/my-bookings");
  };

  const navigateToHome = () => {
    navigate("/");
  };

  return (
    <div className='my-booking-itinerary-container w-full m-auto'>
      <div className='nav-container-search-page bg-primary'>
        <Navbar logo={flybigLogoSearchNav} customClass='navbar-search' />
      </div>

      <div>
        {allUrl?.cancelled === "true" ? (
          <DesktopToast type='success' toastMessage='cancelBooking' />
        ) : allUrl?.cancelled === "false" ? (
          <DesktopToast type='failure' toastMessage='cancelBooking' />
        ) : (
          ""
        )}
      </div>

      <div className='w-max-desk m-auto p-4 px-6'>
        <p className='font350 text-xl my-6'>
          {!allUrl?.cancelled ? "Review your" : "Your"} Itinerary
        </p>

        <div className='itinerary-details-wrapper m-auto p-4 px-6'>
          <p className='flex  justify-between mb-4'>
            {bookingData?.bookingDate && (
              <span className='font325 text-xs text-gray600'>
                Booked on{" "}
                {getFormattedDate(
                  bookingData?.bookingDate,
                  "DD MMM YYYY HH:MM"
                )}
              </span>
            )}
            <span
              className='text-xs text-link500 cursor-pointer'
              onClick={handleRetriveitinerary}
            >
              Retrieve another Itinerary
            </span>
          </p>

          <div className='main-itinerary-details-box border p-6 w-full h-full'>
            <p className='font350 text-xs text-gray600 mb-2'>
              PNR / Booking Reference
            </p>
            <p className='mb-4 flex items-center'>
              <span className='font350 text-base mr-4'>{bookingId}</span>
              <span
                className={`font-normal text-xxs ${
                  status === "cancelled" ? "text-red" : "text-green600"
                }`}
              >
                {status}
              </span>
            </p>
            <p className='font350 text-xs text-gray600'>
              Flight {getFormattedDate(routes?.departure?.departureDate, DATE9)}
              , {routes?.departure?.departureTime} -
              {routes?.departure?.arrivalTime}
            </p>

            <div className='my-6'>
              {selectedFare?.schedule?.[0] && (
                <FlightDetailsComp
                  departureTime={selectedFare?.schedule?.[0]?.departureTime}
                  arrivalTime={selectedFare?.schedule?.[0]?.arrivalTime}
                  departureDate={selectedFare?.schedule?.[0]?.departureDate}
                  arrivalDate={selectedFare?.schedule?.[0]?.arrivalDate}
                  origin={selectedFare?.schedule?.[0]?.origin}
                  destination={selectedFare?.schedule?.[0]?.destination}
                />
              )}{" "}
            </div>

            <p className='font350 text-xs text-gray600'>Payment Status</p>
            <p className='font350 text-base text-green600'>{payment?.status}</p>
          </div>
        </div>

        <div className='mt-8 px-6'>
          <p className='font350 text-base mb-4'>Passenger Details</p>

          <table className='w-full'>
            <thead className='bg-primary text-white font350 text-xs'>
              <tr>
                <th className='srno-column'>Sr. No. </th>
                <th>GUEST NAME</th>
                <th>SEAT NO.</th>
                <th>{allUrl?.cancelled ? "STATUS" : "ADD ON"}</th>
              </tr>
            </thead>
            <tbody>
              {passengers?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {item?.firstName} {item?.lastName}
                    </td>
                    <td>{item?.seat}</td>
                    <td className={allUrl?.cancelled && "text-red"}>
                      {allUrl?.cancelled === "false"
                        ? "Not Cancelled"
                        : allUrl?.cancelled === "true"
                        ? "Cancelled"
                        : item?.addOn}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <p className='mt-6 px-6'>
          <span className='font350 text-base'>
            {allUrl?.cancelled === "true"
              ? "Cancellation Details"
              : "Estimated Refund Amount"}
          </span>
        </p>

        <div className='fare-details-my-booking border p-4 mx-6 mt-4'>
          {cancelBooking?.data?.transactionSummery?.map((item) => {
            return (
              <p className='flex justify-between font325 text-xs mb-2 gotham-book'>
                <span>{item?.charge}</span> <span>{item?.value} INR</span>
              </p>
            );
          })}

          <Divider />
          <p className='flex justify-between font325 text-xs py-2'>
            <span>Refund Amount</span>{" "}
            <span>{cancelBooking?.data?.refundAmount} INR</span>
          </p>
        </div>

        <div className='w-full text-center mt-6 sticky bottom-4'>
          <Button
            label={allUrl?.cancelled ? "Go to home" : "cancel booking"}
            bgColor={allUrl?.cancelled ? primary500 : orange900}
            color={white500}
            onClickHandler={
              allUrl?.cancelled ? navigateToHome : handleCancelModal
            }
            disabled={!cancelBooking?.data}
          />
        </div>

        <p className='font350 text-base my-6 px-6'>Contact Details</p>
        <div className='mt-4 mx-6'>
          <FlybigContact />
        </div>
      </div>

      <CancelModal
        isCancelModal={isCancelModal}
        handleCloseCancelModal={handleCloseCancelModal}
        isCancelLoading={isCancelLoading}
        handleCancelBooking={handleCancelBooking}
      />
    </div>
  );
};
