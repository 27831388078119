import React, { useEffect } from "react";
import PositionedSnackbar from "../components/common/Toast";
import { MyBookingsAndProfile } from "../components/profileDetails-Bookings/desktop/MyBookingsAndProfile";
import { MobileProfile } from "../components/profileDetails-Bookings/mobile/MobileProfile";
import { useWindowSize } from "../utils/utils";
import { useSelector } from "react-redux";

export const MyProfileView = () => {
  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;

  // const {
  //   user: { isLogin },
  // } = useSelector((state) => state);
  //
  // useEffect(() => {
  //   console.log(`#2024151131546831 isLogin`, isLogin);
  // }, [isLogin]);

  return (
    <>
      {isMobile ? <MobileProfile /> : <MyBookingsAndProfile tabV={1} />}

      <PositionedSnackbar />
    </>
  );
};
