import React from "react";
import { CardContent, CardMedia, Card } from "@mui/material";

const PopularRoutesCard = ({ item, handlePopularRoute }) => {
  const cardStyle = { margin: "5px" };
  return (
    <Card
      sx={cardStyle}
      className="popular-routes-card cursor-pointer"
      onClick={() => handlePopularRoute(item)}
    >
      <CardMedia
        component="img"
        alt="popular routes"
        width="305"
        height="240"
        image={item?.image}
        sx={{
          padding: "1em 1em 0 1em",
          borderRadius: "24px 24px 0 0",
          objectFit: "fill",
        }}
      />
      <CardContent>
        <p className="font350 text-base text-center">
          <span>{item?.origin?.city}</span> -{" "}
          <span>{item?.destination?.city}</span>
        </p>
      </CardContent>
    </Card>
  );
};

export default PopularRoutesCard;
