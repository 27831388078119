import React from "react";
import Ancillary from "../components/Ancillary";
import PositionedSnackbar from "../components/common/Toast";

export const AncillaryView = () => {
  return (
    <div>
      <Ancillary />
      <PositionedSnackbar />
    </div>
  );
};
