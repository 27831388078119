import React from "react";
import "./flightDetails.scss";
import crossFlight from "../../../../assets/svgs/crossMIniFlight.svg";
import flightUpSvg from "../../../../assets/svgs/flight-up.svg";
import { getFormattedDate } from "../../../../utils/utils";
import { DATE_FORMAT } from "../../../../utils/constant";

export function FlightDetails({ type, bookingDetailsState }) {
  const {
    aircraft,
    origin,
    destination,
    departureDate,
    arrivalDate,
    flightNumber,
    departureTime,
    arrivalTime,
  } = bookingDetailsState?.[0];

  return (
    <div className="flight-details-wrapper border w-full m-auto">
      <div className="depart-heading mt-4 mb-6">
        <img src={flightUpSvg} alt="" />
        <span className="text-base font350 ml-4">
          <span className="font-semibold">{type}</span> - {origin?.city} to{" "}
          {destination?.city}
        </span>
      </div>

      <div className="flight-details-header border uppercase flex bg-black text-white">
        <span className="font-normal text-xs ml-6">Flight Details</span>
        <span className="text-xs font-light ml-8">
          FLIGHT NO - {flightNumber?.slice(3)}
        </span>
        <span className="text-xs font-light ml-8">AIRCRAFT - {aircraft}</span>
      </div>

      <div className="depart-arrive-info mt-2">
        <p className="text-sm font350 flex justify-between pl-20 pr-43">
          <span>DEPARTURE</span>
          <span>ARRIVAL</span>
        </p>
        <p className="text-sm font350 text-primary flex justify-between pl-20 pr-43">
          <span>{departureTime}</span>
          <span>{arrivalTime}</span>
        </p>

        <section className="relative flght-dtls-cross-svg-section">
          <div className="border-line flex justify-between">
            <span className="dot-before absolute"></span>
            <span className="line"></span>
            <img
              src={crossFlight}
              alt="flight icon"
              className="absolute flght-dtls-cross-svg z-1"
            />
            <span className="dot-after absolute"></span>
          </div>
        </section>

        <div className="cities-dtls flex justify-between mt-4 pl-20 pr-20">
          <section>
            <p className="font-350 text-base review-citycode text-white bg-black">
              {origin?.code}
            </p>

            <p className="font-normal text-xs mb-1 review-cityname uppercase">
              {origin?.city} airport
            </p>

            <p className="font-light text-xxs mb-1 gotham-book">
              {getFormattedDate(departureDate, DATE_FORMAT?.DATE8)}
            </p>
          </section>
          <section>
            <p className="font-350 text-base review-citycode text-white bg-black">
              {destination?.code}
            </p>
            <p className="font-normal text-xs mb-1 review-cityname uppercase">
              {destination?.city} airport
            </p>
            <p className="font-light text-xxs mb-1 gotham-book">
              {getFormattedDate(arrivalDate, DATE_FORMAT?.DATE8)}
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}
