import React from "react";
import { CustomDrawer } from "../common/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import { Container, Divider } from "@mui/material";
import { ONE_WAY, SEARCH_RESULTS_ROUTE } from "../../utils/constant";
import { useSelector } from "react-redux";
import { useUrlExtract } from "../../utils/utils";

export const CartDetails = ({
  CartDetailsDrawer,
  setCartDetailsDrawer,
  data,
  usedIn,
}) => {
  const { search } = useSelector((state) => state);
  const { bookingType } = useUrlExtract("all");

  let pathname = window.location.pathname;

  return (
    <CustomDrawer
      position={"bottom"}
      state={CartDetailsDrawer}
      setState={(val) => setCartDetailsDrawer(val)}
      label={""}
    >
      <div>
        <div className='shadow-lg h-14 text-white bg-primary '>
          <Container className='h-full'>
            <div className='flex justify-between items-center h-full'>
              <div>
                <p className='text-sm'>Fare Breakup</p>
              </div>
              <CloseIcon
                onClick={() => setCartDetailsDrawer({ bottom: false })}
                className='text-white'
              />
            </div>
          </Container>
        </div>
        <div className='mt-4 '>
          <Container>
            <p className='text-xxs'>Base Fare </p>
            <PassengerCost
              type={"Adult"}
              passengers={data?.passengers?.adult}
              baseFare={
                pathname === SEARCH_RESULTS_ROUTE
                  ? data?.schedule?.total?.basicAdult
                  : data?.schedule?.[0]?.fare?.price
              }
              label={"Onwards"}
            />
            {search?.searchDetails?.tripType !== ONE_WAY &&
            (data?.returnSchedule?.total?.basicAdult ||
              data?.returnSchedule?.[0]?.fare?.price) ? (
              <PassengerCost
                type={"Adult"}
                passengers={data?.passengers?.adult}
                baseFare={
                  pathname === SEARCH_RESULTS_ROUTE
                    ? data?.returnSchedule?.total?.basicAdult
                    : data?.returnSchedule?.[0]?.fare?.price
                }
                label={"Return"}
              />
            ) : (
              ""
            )}
            {Number(data?.passengers?.child) ? (
              <PassengerCost
                type={"Child"}
                passengers={data?.passengers?.child}
                baseFare={
                  pathname === SEARCH_RESULTS_ROUTE
                    ? data?.schedule?.total?.basicChild
                    : data?.schedule?.[0]?.fare?.child?.basicFare
                }
                label={"Onwards"}
              />
            ) : (
              ""
            )}
            {search?.searchDetails?.tripType !== ONE_WAY &&
            Number(data?.passengers?.child) &&
            (data?.returnSchedule?.total?.basicChild ||
              data?.returnSchedule?.[0]?.fare?.child?.basicFare) ? (
              <PassengerCost
                type={"Child"}
                passengers={data?.passengers?.child}
                baseFare={
                  pathname === SEARCH_RESULTS_ROUTE
                    ? data?.returnSchedule?.total?.basicChild
                    : data?.returnSchedule?.[0]?.fare?.child?.basicFare
                }
                label={"Return"}
              />
            ) : (
              ""
            )}
            {Number(data?.passengers?.infant) &&
            (data?.schedule?.total?.basicInfant ||
              data?.schedule?.[0]?.fare?.infant?.basicFare) ? (
              <PassengerCost
                type={"Infant"}
                passengers={data?.passengers?.infant}
                baseFare={
                  pathname === SEARCH_RESULTS_ROUTE
                    ? data?.schedule?.total?.basicInfant
                    : data?.schedule?.[0]?.fare?.infant?.basicFare
                }
                label={"Onwards"}
              />
            ) : (
              ""
            )}
            {search?.searchDetails?.tripType !== ONE_WAY &&
            Number(data?.passengers?.infant) &&
            (data?.returnSchedule?.total?.basicInfant ||
              data?.returnSchedule?.[0]?.fare?.infant?.basicFare) ? (
              <PassengerCost
                type={"Infant"}
                passengers={data?.passengers?.infant}
                baseFare={
                  pathname === SEARCH_RESULTS_ROUTE
                    ? data?.returnSchedule?.total?.basicInfant
                    : data?.returnSchedule?.[0]?.fare?.infant?.basicFare
                }
                label={"Return"}
              />
            ) : (
              ""
            )}
            <p className='border-t-lightBlack pt-3 pb-2 text-xxs'>
              Fee & Surcharges
            </p>
            {data?.total?.convenienceFee && bookingType !== "modify" ? (
              <div className='flex justify-between py-2'>
                <p className='text-xxs text-gray800'>Convenience Fee</p>
                <p className='text-xxs'>₹ {data?.total?.convenienceFee}</p>
              </div>
            ) : (
              ""
            )}
            {usedIn === "search-results" ? (
              <div>
                <div className='flex justify-between pb-3'>
                  <p className='text-xxs'>Total Tax And Fees</p>
                  <p className='text-xxs'>₹ {data?.total?.totalTaxAndFees}</p>
                </div>
                {bookingType !== "modify" && (
                  <p className='text-xxs'>
                    *Convenience fee to be added on the next step
                  </p>
                )}
              </div>
            ) : (
              Object?.entries(data?.total?.totalTaxAndFees)
                ?.filter((item) => item?.[1])
                ?.map((value) => {
                  return (
                    <div className='flex justify-between pb-3'>
                      <p className='text-xxs'>
                        {value?.[0] === "total"
                          ? "Total Taxes"
                          : value?.[0][0].toUpperCase() + value?.[0].slice(1)}
                      </p>
                      <p className='text-xxs'>₹ {value?.[1]}</p>
                    </div>
                  );
                })
            )}
          </Container>
        </div>
        <div
          className={`${
            bookingType === "modify" ? "h-32" : "h-12"
          } mt-5 shadow-md`}
        >
          <Container className='h-full'>
            {bookingType === "modify" && (
              <>
                <div className='flex justify-between items-center mb-2 pt-2'>
                  <p>Current Fare</p>
                  <p>₹ {data?.total?.totalWithoutModification}</p>
                </div>
                <div className='flex justify-between items-center mb-2'>
                  <p>Previous Fare</p>
                  <p>- ₹ {data?.total?.previousFareTotal}</p>
                </div>
                <div className='flex justify-between items-center mb-2'>
                  <p>Modification Fee</p>
                  <p>+ ₹ {data?.total?.modificationFee}</p>
                </div>
                <Divider className='mb-2' />
              </>
            )}
            <div
              className={`flex justify-between items-center mb-2 ${
                bookingType !== "modify" && "h-full"
              }`}
            >
              <p>Total Amount</p>
              <p>₹ {data?.total?.total}</p>
            </div>
          </Container>
        </div>
      </div>
    </CustomDrawer>
  );
};

const PassengerCost = ({ type, passengers, baseFare, label }) => {
  return (
    <div className='flex justify-between py-1 '>
      <p className='text-xxs text-gray800'>
        {label}
        {":"}
        {type}(s) {passengers} x ₹ {baseFare && baseFare / passengers}{" "}
      </p>
      <p className='text-xxs'>₹ {baseFare ? baseFare : "-"}</p>
    </div>
  );
};
