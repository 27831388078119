import React, { useState } from 'react';
import flightUpSvg from '../../../../assets/svgs/flight-up.svg';
import { Navbar } from '../../../Navbar/Desktop/Navbar';
import flybigLogoSearchNav from '../../../../assets/svgs/flybigLogoSearchNav.svg';
import './itinerary.scss';
import { Box, CircularProgress, Grid, Modal, TextField } from '@mui/material';
import { FlightDetailsComp } from '../../../common/FlightDetails';
import { DATE_FORMAT } from '../../../../utils/constant';
import { FlybigContact } from '../../../common/FlybigContact';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../common/Button';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { colorPalette } from '../../../../theme/color-palette';
import { useUrlExtract, getFormattedDate } from '../../../../utils/utils';
import {
  bookingDetailsEmail,
  bookingDetailsSms,
  getDetailsByPnr,
} from '../../../../Api/Booking';
import { toastHandler } from '../../../../Redux/userReducer/user.action';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkInDataHandler,
  checkInStepHandler,
} from '../../../../Redux/CheckInReducer/checkIn.action';
import PositionedSnackbar from '../../../common/Toast';
import { DesktopToast } from '../../../common/Toast/DesktopToast';
import FareDetailsCheckin from '../common/FareDetailsCheckin';
import { useEffect } from 'react';

export const Itinerary = ({ bookingData }) => {
  const [isContactModal, setIsContactModal] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState({
    email: false,
    sms: false,
  });
  const [toastMsg, setToastMsg] = useState({
    email: '',
    sms: '',
  });
  const [dateChecker, setDateChecker] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allUrl = useUrlExtract('all');
  const {
    user: { openToast },
  } = useSelector((state) => state);

  const { primary500, white500 } = colorPalette;
  const { DATE9 } = DATE_FORMAT;

  const {
    bookingId,
    passengers,
    payment,
    routes,
    selectedFare,
    status,
    departureDate,
    returnDate,
    webCheckinStatus,
  } = bookingData;

  useEffect(() => {
    const departDate = new Date(departureDate);
    const todaysDate = new Date(Date.now());
    const dateTruth = departDate > todaysDate;
    if (status === 'confirmed') {
      setDateChecker(dateTruth);
    } else {
      setDateChecker(false);
    }
  }, [departureDate, status]);

  const navigateToModifyBooking = () => {
    navigate(`/modify-booking?bookingId=${allUrl?.bookingId}`);
  };

  const navigateToCancelBooking = () => {
    navigate(`/cancel-booking?bookingId=${allUrl?.bookingId}`);
  };

  const handleRetriveitinerary = () => {
    navigate('/my-bookings');
  };

  // const handleUpdateContactDetails = () => {
  //   setIsContactModal(true);
  // };

  const closeContactModal = () => {
    setIsContactModal(false);
  };
  const submitUpdatedContactDetails = () => {
    //contact details API
  };

  const navigateToWebCheckin = () => {
    getDetailsByPnr(bookingId, passengers?.[0]?.lastName)
      .then(({ data }) => {
        dispatch(checkInDataHandler(data));
        dispatch(checkInStepHandler(2));
        navigate('/check-in');
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: 'error',
          })
        );
      });
  };

  const handleSendSms = () => {
    setIsLoading((prev) => ({ ...prev, sms: true }));
    bookingDetailsSms(allUrl?.bookingId)
      .then(() => {
        setIsLoading((prev) => ({ ...prev, sms: false }));
        setToastMsg((prev) => ({ ...prev, sms: 'success' }));
      })
      .catch(() => {
        setIsLoading((prev) => ({ ...prev, sms: false }));
        setToastMsg((prev) => ({ ...prev, sms: 'failure' }));
      });
  };
  const handleSendEmail = () => {
    setIsLoading((prev) => ({ ...prev, email: true }));
    bookingDetailsEmail(allUrl?.bookingId)
      .then(() => {
        setIsLoading((prev) => ({ ...prev, email: false }));
        setToastMsg((prev) => ({ ...prev, email: 'success' }));
      })
      .catch(() => {
        setIsLoading((prev) => ({ ...prev, email: false }));
        setToastMsg((prev) => ({ ...prev, email: 'failure' }));
      });
  };

  return (
    <>
      <div className='my-booking-itinerary-container w-full m-auto'>
        <div className='nav-container-search-page bg-primary'>
          <Navbar logo={flybigLogoSearchNav} customClass='navbar-search' />
        </div>
        {toastMsg?.email === 'success' && (
          <DesktopToast type='success' toastMessage='email' />
        )}
        {toastMsg?.email === 'failure' && (
          <DesktopToast type='failure' toastMessage='email' />
        )}
        {toastMsg?.sms === 'success' && (
          <DesktopToast type='success' toastMessage='sms' />
        )}
        {toastMsg?.sms === 'failure' && (
          <DesktopToast type='failure' toastMessage='sms' />
        )}

        <div className='w-max-desk m-auto px-6'>
          <p className='font350 text-xl my-6'>Itinerary</p>

          <div className='itinerary-details-wrapper m-auto p-4'>
            <p className='flex  justify-between mb-4'>
              {bookingData?.bookingDate && (
                <span className='font325 text-xs text-gray600'>
                  Booked on{' '}
                  {getFormattedDate(
                    bookingData?.bookingDate,
                    'DD MMM YYYY HH:MM'
                  )}
                </span>
              )}
              <span
                className='text-xs text-link500 cursor-pointer'
                onClick={handleRetriveitinerary}
              >
                Retrieve another Itinerary
              </span>
            </p>

            <Grid
              container
              className='itinerary-details-box border w-full h-full'
            >
              <Grid
                item
                lg={8}
                md={8}
                className='main-itinerary-details-box border p-6'
              >
                <div className='onward-heading mb-5'>
                  <img src={flightUpSvg} alt='flight icon' />
                  <span className='text-base ml-4'>Departure</span>
                </div>
                <p className='font350 text-xs text-gray600 mb-2'>
                  PNR / Booking Reference
                </p>
                <p className='mb-4 flex items-center'>
                  <span className='font350 text-base mr-4'>{bookingId}</span>
                  <span
                    className={`font-normal text-xxs ${
                      status === 'cancelled' ? 'text-red' : 'text-green600'
                    }`}
                  >
                    {status}
                  </span>
                </p>
                <p className='font350 text-xs text-gray600'>
                  Flight{' '}
                  {getFormattedDate(routes?.departure?.departureDate, DATE9)}
                  {/* {routes?.departure?.departureTime -
                    routes?.departure?.arrivalTime} */}
                </p>

                <div className='my-6'>
                  {selectedFare?.schedule?.[0] && (
                    <FlightDetailsComp
                      departureTime={selectedFare?.schedule?.[0]?.departureTime}
                      arrivalTime={selectedFare?.schedule?.[0]?.arrivalTime}
                      departureDate={selectedFare?.schedule?.[0]?.departureDate}
                      arrivalDate={selectedFare?.schedule?.[0]?.arrivalDate}
                      origin={selectedFare?.schedule?.[0]?.origin}
                      destination={selectedFare?.schedule?.[0]?.destination}
                    />
                  )}
                </div>

                {selectedFare?.returnSchedule && (
                  <>
                    <hr style={{ color: '#d1d1d1' }} className='my-8'></hr>

                    <div className='onward-heading mb-5'>
                      <img src={flightUpSvg} alt='flight icon' />
                      <span className='text-base ml-4'>Return</span>
                    </div>
                    <p className='font350 text-xs text-gray600 mb-2 mt-2'>
                      PNR / Booking Reference
                    </p>
                    <p className='mb-4 flex items-center'>
                      <span className='font350 text-base mr-4'>
                        {bookingId}
                      </span>
                      <span
                        className={`font-normal text-xxs ${
                          status === 'cancelled' ? 'text-red' : 'text-green600'
                        }`}
                      >
                        {status}
                      </span>
                    </p>
                    <p className='font350 text-xs text-gray600'>
                      Flight{' '}
                      {getFormattedDate(routes?.return?.departureDate, DATE9)}
                      {/* {routes?.departure?.departureTime -
    routes?.departure?.arrivalTime} */}
                    </p>

                    <div className='my-6'>
                      {selectedFare?.returnSchedule?.[0] && (
                        <FlightDetailsComp
                          departureTime={
                            selectedFare?.returnSchedule?.[0]?.departureTime
                          }
                          arrivalTime={
                            selectedFare?.returnSchedule?.[0]?.arrivalTime
                          }
                          departureDate={
                            selectedFare?.returnSchedule?.[0]?.departureDate
                          }
                          arrivalDate={
                            selectedFare?.returnSchedule?.[0]?.arrivalDate
                          }
                          origin={selectedFare?.returnSchedule?.[0]?.origin}
                          destination={
                            selectedFare?.returnSchedule?.[0]?.destination
                          }
                        />
                      )}
                    </div>
                  </>
                )}
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                className='itinerary-details-aside border p-6'
              >
                <p className='font350 text-xs text-gray600'>Payment Status</p>
                <p className='font350 text-base text-green600 mb-6'>
                  {payment?.status}
                </p>

                <p className='font350 text-xs text-gray600 mb-3'>Options</p>
                {status !== 'cancelled' && (
                  <p
                    className='font350 text-xs mb-4 text-link500 cursor-pointer'
                    onClick={navigateToCancelBooking}
                  >
                    Cancel Booking
                  </p>
                )}
                {dateChecker && (
                  <p
                    className='font350 text-xs mb-4 text-link500 cursor-pointer'
                    onClick={navigateToModifyBooking}
                  >
                    Modify Booking
                  </p>
                )}
                {/* <p
                className='font350 text-xs mb-2 text-link500 cursor-pointer'
                onClick={handleUpdateContactDetails}
              >
                Update Contact Details
              </p> */}
                {!(webCheckinStatus || status === 'cancelled') &&
                  dateChecker && (
                    <p
                      className='font350 text-xs mb-4 text-link500 cursor-pointer'
                      onClick={() => navigateToWebCheckin()}
                    >
                      Web Check In
                    </p>
                  )}
                <p
                  className='font350 text-xs mb-4 text-link500 cursor-pointer flex items-center'
                  onClick={handleSendEmail}
                >
                  <span>Email Itinerary</span>
                  {isLoading?.email && (
                    <span className='ml-4 mt-1'>
                      <CircularProgress color='inherit' size={14} />
                    </span>
                  )}
                </p>
                <p
                  className='font350 text-xs mb-4 text-link500 cursor-pointer flex items-center'
                  onClick={handleSendSms}
                >
                  <span>SMS Itinerary</span>
                  {isLoading?.sms && (
                    <span className='ml-4 mt-1'>
                      <CircularProgress color='inherit' size={20} />
                    </span>
                  )}
                </p>
              </Grid>
            </Grid>
          </div>

          <div className='mt-8 px-6'>
            <p className='font350 text-base mb-4'>Guest Details</p>

            <table className='w-full'>
              <thead className='bg-primary text-white font350 text-xs'>
                <tr>
                  <th className='srno-column'>Sr. No.</th>
                  <th>GUEST NAME</th>
                  <th>SEAT NO.</th>
                  <th>ADD ON</th>
                  <th>Boarding Pass</th>
                </tr>
              </thead>
              <tbody>
                {passengers?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {item?.firstName} {item?.lastName}
                      </td>
                      <td>{item?.webCheckin?.[0]?.seat?.number}</td>
                      <td>{item?.webCheckin?.[0]?.ancillaries?.[0]?.code}</td>
                      <td>
                        {item?.webCheckin?.[0]?.boardingPass ? (
                          <a
                            href={item?.webCheckin?.[0]?.boardingPass}
                            className='text-link500 cursor-pointer'
                          >
                            Download
                          </a>
                        ) : (
                          '-'
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <FareDetailsCheckin />

          <p className='font350 text-base my-6 px-6'>Contact Details</p>
          <div className='mt-4 mx-6'>
            <FlybigContact />
          </div>
        </div>

        <Modal open={isContactModal} handleClose={closeContactModal}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: 300,
              height: '100%',
              bgcolor: 'background.paper',
              boxShadow: 24,
            }}
          >
            <div className='flex justify-between bg-gray300 p-4 w-full'>
              <span className='font-normal text-sm'>
                Update Contact Details
              </span>
              <span className='cursor-pointer' onClick={closeContactModal}>
                x
              </span>
            </div>
            <div className='p-2'>
              <p className='text-xs font-medium mb-2'>Mobile Number</p>
              <PhoneInput
                country={'in'}
                value={userDetails?.mobile?.countryCode}
                inputProps={{
                  required: true,
                  autoFocus: true,
                }}
                onChange={(value, data, event, formattedValue) => {
                  setUserDetails({
                    ...userDetails,
                    mobile: {
                      countryCode: data?.dialCode,
                      mobileNumber: value?.slice(data?.dialCode?.length),
                    },
                  });
                }}
              />
            </div>
            <div className='p-2'>
              <p className='text-xs font-medium mb-2'>Alternate Number</p>
              <PhoneInput
                country={'in'}
                value={userDetails?.alternateNo?.countryCode}
                inputProps={{
                  required: true,
                  autoFocus: true,
                }}
                onChange={(value, data, event, formattedValue) => {
                  setUserDetails({
                    ...userDetails,
                    alternateNo: {
                      countryCode: data?.dialCode,
                      mobileNumber: value?.slice(data?.dialCode?.length),
                    },
                  });
                }}
              />
            </div>
            <div className='p-2'>
              <p className='text-xs font-medium mb-2'>Email*</p>
              <TextField
                type='email'
                size={'small'}
                value={userDetails?.email}
                onChange={(e) => {
                  setUserDetails((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }));
                  // setIsValidEmail(emailHandler(e.target.value));
                }}
                // error={userDetails?.email && !isValidEmail}
                // helperText={
                //   userDetails?.email && !isValidEmail ? "Incorrect Email" : ""
                // }
                variant='outlined'
                className='w-full'
                placeholder='Enter your email'
              ></TextField>
            </div>

            <div className='p-2 font350 text-xs gotham-book'>
              <span className='gotham-rounded'>Note</span> : In case you update
              your contact number, you will need to resubscribe to receive
              notifications from us on WhatsApp.
            </div>

            <div className='p-2 mt-4'>
              <Button
                label={'submit'}
                bgColor={primary500}
                color={white500}
                width={'100%'}
                onClickHandler={submitUpdatedContactDetails}
              />
            </div>
          </Box>
        </Modal>
      </div>

      <PositionedSnackbar />
    </>
  );
};
