import React, { useEffect } from "react";
import { colorPalette } from "../../../../theme/color-palette";
import { Navbar } from "../../../Navbar/Mobile";
import logo from "../../../../assets/svgs/logo.svg";
import { useNavigate } from "react-router-dom";
import { useUrlExtract } from "../../../../utils/utils";
import { Box, Divider, Tooltip } from "@mui/material";
import { FlightDetailsComp } from "../../../common/FlightDetails";
import { BsInfoCircle } from "react-icons/bs";
import { Button } from "../../../common/Button";
import PaxTable from "../PaxTable";
import CancelModal from "../../common/CancelModal";
import PostCancelModal from "../common/PostCancelModal";
import { SkeletonComp } from "../../../common/Skeleton/Skeleton";

const CancelBookingMobile = ({
  bookingData,
  cancelBooking,
  handleCancelModal,
  isCancelModal,
  handleCloseCancelModal,
  isCancelLoading,
  handleCancelBooking,
  postCancelModal,
  setPostCancelModal,
}) => {
  const navigate = useNavigate();
  const { cancelled } = useUrlExtract("all");

  const { primary500, white500, orange900 } = colorPalette;

  useEffect(() => {
    cancelled && setPostCancelModal(true);
  }, [cancelled]);

  const {
    bookingId,
    passengers,
    payment,
    routes,
    selectedFare: { schedule },
    selectedFare,
    status,
    tickets,
  } = bookingData;

  //   const handleClosePostCancelModal = () => {
  //     setPostCancelModal(false);
  //   };

  return (
    <>
      {cancelled ? (
        <div className='px-4'>
          <PostCancelModal
            postCancelModal={postCancelModal}
            //   handleClosePostCancelModal={handleClosePostCancelModal}
          />
        </div>
      ) : (
        <div>
          <Navbar
            color={white500}
            bgColor={primary500}
            showMenu={true}
            icon={logo}
            onClose={() => navigate(-1)}
          />
          <main className='w-full p-4'>
            <p className='flex justify-between items-center'>
              <span className='text-lg'>PNR : {bookingId}</span>
              <span className='text-green500'>{status}</span>
            </p>

            <Divider className='my-4' />

            <div>
              {schedule?.[0] && (
                <FlightDetailsComp
                  departureTime={schedule?.[0]?.departureTime}
                  arrivalTime={schedule?.[0]?.arrivalTime}
                  departureDate={schedule?.[0]?.departureDate}
                  arrivalDate={schedule?.[0]?.arrivalDate}
                  origin={schedule?.[0]?.origin}
                  destination={schedule?.[0]?.destination}
                />
              )}
            </div>

            <Divider className='my-4' />

            <div>
              <p>Guest Details</p>
            </div>

            <Divider className='my-4' />

            <PaxTable passengers={passengers} flightData={schedule} />

            {selectedFare?.returnSchedule?.length && (
              <PaxTable
                passengers={passengers}
                flightData={selectedFare?.returnSchedule}
              />
            )}

            <Divider className='my-4' />

            <div className='p-1 flex justify-between items-center'>
              <span>
                Estimated Refund Amount
                <Tooltip
                  title={
                    <Box>
                      {cancelBooking?.data?.transactionSummery?.map((item) => {
                        return (
                          <p className='flex justify-between font325 text-xs mb-2 gotham-book'>
                            <span>{item?.charge}</span>
                            <span>{item?.value}</span>
                          </p>
                        );
                      })}
                    </Box>
                  }
                  arrow
                  enterTouchDelay={0}
                >
                  <span className='ml-2 mt-1 cursor-pointer'>
                    <BsInfoCircle />
                  </span>
                </Tooltip>
              </span>
              {cancelBooking?.data ? (
                <span> {cancelBooking?.data?.refundAmount} </span>
              ) : (
                <SkeletonComp width='20%' />
              )}
            </div>

            <Divider className='my-4' />

            <div className={"mb-4"}>
              <Button
                bgColor={orange900}
                color={white500}
                label='Cancel Booking'
                width={"100%"}
                onClickHandler={handleCancelModal}
                disabled={!cancelBooking?.data}
              />
            </div>
          </main>

          <CancelModal
            isCancelModal={isCancelModal}
            handleCloseCancelModal={handleCloseCancelModal}
            isCancelLoading={isCancelLoading}
            handleCancelBooking={handleCancelBooking}
          />
        </div>
      )}
    </>
  );
};

export default CancelBookingMobile;
