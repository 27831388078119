import React from "react";
import PositionedSnackbar from "../components/common/Toast";
import { PassengerInfoPage } from "../components/passenger_info/Index";

export function PassengerInfoView() {
  return (
    <>
      <PassengerInfoPage />
      <PositionedSnackbar />
    </>
  );
}
