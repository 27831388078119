import React from "react";
import PositionedSnackbar from "../components/common/Toast";
import { SeatMap } from "../components/seatMap";

export const SeatMapView = () => {
  return (
    <>
      <SeatMap />
      <PositionedSnackbar />
    </>
  );
};
