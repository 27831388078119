import axiosInstance from "../axiosInstance";
import { LOGIN_ENDPOINTS } from "../endpoints";

const {
  OTP_REGISTER,
  OTP_LOGIN,
  OTP_VERIFY,
  VERIFY_TOKEN,
  LOG_OUT,
  OTP_RESEND,
  GOOGLE,
  FACEBOOK,
  REFRESH_TOKEN,
} = LOGIN_ENDPOINTS;

export const registerOtp = (body) => axiosInstance.post(OTP_REGISTER, body);

export const verifyOtp = (body) => axiosInstance.post(OTP_VERIFY, body);

export const loginOtp = (body) => axiosInstance.post(OTP_LOGIN, body);

export const verifyToken = () => axiosInstance.post(VERIFY_TOKEN);

export const userLogOut = (body) => axiosInstance.post(LOG_OUT, body);

export const resendOtp = (body) => axiosInstance.post(OTP_RESEND, body);

export const googleLogin = (body) => axiosInstance.post(GOOGLE, body);

export const facebookLogin = (body) => axiosInstance.post(FACEBOOK, body);

export const refreshTokens = (body) => axiosInstance.post(REFRESH_TOKEN, body);
