import React, { useEffect, useState } from "react";
import "./faq-collapse.scss";
import Box from "@mui/material/Box";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import QuestionAnswer from "../../QuestionAnswer/QuestionAnswer";

export default function FaqCollapse({ faqData }) {
  const [faq, setFaq] = React.useState(0);
  const [qna, setQna] = useState({});

  useEffect(() => {
    setQna(() => faqData?.details?.[faq]);
  }, [faq, faqData?.details?.length]);

  const handleChanges = (event) => {
    setFaq(event.target.value);
  };

  return (
    <div className='mt-8'>
      <Box sx={{ minWidth: 120 }}>
        <FormControl className='faq-form'>
          <InputLabel id='demo-simple-select-label'>Faq</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={faq}
            label='Age'
            onChange={handleChanges}
          >
            {faqData?.details?.map((item, index) => {
              return <MenuItem value={index}>{item?.title}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Box>
      {Boolean(qna?.extraDetails?.questionAndAnswers?.length) && (
        <QuestionAnswer qnaData={qna?.extraDetails?.questionAndAnswers} />
      )}
    </div>
  );
}
