import React, { useEffect, useState } from "react";
import "./searchPage.scss";
import { DesktopSearch } from "./Desktop";
import { MobileSearch } from "./Mobile";
import { useWindowSize } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../MobileLoader/index";
import { useScrollTop } from "../../customHooks/useScrollTop";
import { searchFareDetails } from "../../Api/search";
import { toastHandler } from "../../Redux/userReducer/user.action";

export const SearchPage = ({ faresResponse }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [faresDetails, seFaresDetails] = useState(null);

  useScrollTop();
  const dispatch = useDispatch();
  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;

  const {
    cart: { loader },
    user: { openToast },
  } = useSelector((state) => state);

  useEffect(() => {
    searchFareDetails("biglite,bigeasy")
      .then(({ data }) => {
        seFaresDetails(data);
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message || "Something went wrong...",
            severity: "error",
          })
        );
      });
  }, []);

  if (loader) {
    return <Loader />;
  }

  return isMobile ? (
    <MobileSearch
      selectedIndex={selectedIndex}
      setSelectedIndex={setSelectedIndex}
      faresDetails={faresDetails}
    />
  ) : (
    <DesktopSearch faresResponse={faresResponse} />
  );
};
