import React, { useEffect, useState } from "react";
import CommonSignUp from "./Common/SignUp";

export const SignUpModal = ({
  handleClose,
  userData,
  setUserData,
  countryCodeList,
  otp,
  setOtp,
  verifyOtpHandler,
  sendOtp,
  showSignUpOtp,
  setShowSignUpOtp,
  resendOtpHandler,
  registerOtpHandler,
}) => {
  const [registerationBody, setRegistrationBody] = useState({});

  useEffect(() => {
    setRegistrationBody({
      ...registerationBody,
      name: `${userData?.firstName} ${userData?.lastName}`,
      email: userData?.email,
      password: userData?.password,
    });
  }, [userData]);

  return (
    <>
      <section className="h-full flex flex-col justify-center bg-white p-2">
        <p className="text-xl font-350 mb-4 pl-3">Sign Up</p>

        <CommonSignUp
          sendOtp={sendOtp}
          userData={userData}
          setUserData={setUserData}
          verifyOtpHandler={verifyOtpHandler}
          otp={otp}
          setOtp={setOtp}
          showSignUpOtp={showSignUpOtp}
          setShowSignUpOtp={setShowSignUpOtp}
          countryCodeList={countryCodeList}
          registerOtpHandler={registerOtpHandler}
          type="desktop"
          resendOtpHandler={resendOtpHandler}
        />
      </section>
    </>
  );
};
