import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const DropDown = ({ value, handleChange, inputArray, placeholder }) => {
  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id='demo-simple-select-label'>{placeholder}</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={value}
          label='Age'
          onChange={(e) => handleChange(e.target.value)}
          IconComponent={KeyboardArrowDownIcon}
        >
          {inputArray?.map((item,index) => (
            <MenuItem key={index} value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
