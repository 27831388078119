import React from "react";
import { Offers } from "../components/Offers/offers";

export const OffersView = () => {

  return (
    <div>
      <Offers />
    </div>
  );
};
