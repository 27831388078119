import { useState } from "react";
import "./myProfile.scss";
import { ChangePhone } from "./tabs/ChangePhone";
import { Profile } from "../../common/Profile";
import { Travellers } from "../../common/Travellers";

export const MyProfile = () => {
  const [showTabs, setShowTabs] = useState({
    myProfile: true,
    travellers: false,
    changePhone: false,
  });

  return (
    <div className='w-full w-max-desk m-auto myProfile-container flex justify-between'>
      <aside className='border profile-aside'>
        <section
          className={`${
            showTabs?.myProfile ? "text-primary" : "text-link500"
          } p-4 font350 text-xs cursor-pointer border-bottom`}
          onClick={() => setShowTabs({ myProfile: true })}
        >
          My Profile
        </section>
        <section
          className={`${
            showTabs?.travellers ? "text-primary" : "text-link500"
          } p-4 font350 text-xs cursor-pointer border-bottom`}
          onClick={() => setShowTabs({ travellers: true })}
        >
          Guests
        </section>
        {/* <section
          className='text-link500 p-4 font350 text-xs cursor-pointer'
          onClick={() => setShowTabs({ changePhone: true })}
        >
          Change Phone
        </section> */}
      </aside>

      {showTabs?.myProfile ? (
        <Profile />
      ) : showTabs?.travellers ? (
        <Travellers />
      ) : showTabs?.changePhone ? (
        <ChangePhone />
      ) : (
        ""
      )}
    </div>
  );
};
