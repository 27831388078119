import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  bookingDetailsApi,
  getCancelBooking,
  postCancelBooking,
} from "../Api/Booking";
import PositionedSnackbar from "../components/common/Toast";
import { Loader } from "../components/MobileLoader";
import { CancelBooking } from "../components/profileDetails-Bookings/desktop/manageBooking/CancelBooking";
import CancelBookingMobile from "../components/profileDetails-Bookings/mobile/manageBookings/CancelBooking";
import {
  cancelBookingData,
  toastHandler,
} from "../Redux/userReducer/user.action";
import { useUrlExtract, useWindowSize } from "../utils/utils";

const CancelBookingView = () => {
  const [bookingData, setBookingData] = useState("");
  const [cancelBooking, setCancelBooking] = useState({});
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [postCancelModal, setPostCancelModal] = useState(false);

  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allUrl = useUrlExtract("all");
  const {
    user: { openToast },
  } = useSelector((state) => state);

  useEffect(() => {
    bookingDetailsApi(allUrl?.bookingId)
      .then(({ data }) => {
        setBookingData(data);
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
      });
  }, [allUrl?.bookingId]);

  useEffect(() => {
    getCancelBooking(allUrl?.bookingId)
      .then(({ data }) => {
        setCancelBooking(data);
      })
      .catch((err) => {});
  }, []);

  if (!bookingData) {
    return <Loader />;
  }

  const handleCloseCancelModal = () => {
    setIsCancelModal(false);
  };

  const handleCancelModal = () => {
    setIsCancelModal(true);
  };

  const handleCancelBooking = () => {
    setIsCancelLoading(true);
    postCancelBooking(allUrl?.bookingId)
      .then(({ data }) => {
        dispatch(cancelBookingData(data));
        navigate(
          `/cancel-booking?bookingId=${allUrl?.bookingId}&cancelled=true`
        );
        setIsCancelModal(false);
        setPostCancelModal(true);
      })
      .catch((err) => {
        setIsCancelLoading(false);
        navigate(
          `/cancel-booking?bookingId=${allUrl?.bookingId}&cancelled=false`
        );
        setIsCancelModal(false);
        setPostCancelModal(true);
      });
  };

  return (
    <>
      {isMobile ? (
        <CancelBookingMobile
          cancelBooking={cancelBooking}
          bookingData={bookingData}
          isCancelModal={isCancelModal}
          handleCloseCancelModal={handleCloseCancelModal}
          isCancelLoading={isCancelLoading}
          handleCancelBooking={handleCancelBooking}
          handleCancelModal={handleCancelModal}
          postCancelModal={postCancelModal}
          setPostCancelModal={setPostCancelModal}
        />
      ) : (
        <CancelBooking
          cancelBooking={cancelBooking}
          bookingData={bookingData}
          isCancelModal={isCancelModal}
          handleCloseCancelModal={handleCloseCancelModal}
          isCancelLoading={isCancelLoading}
          handleCancelBooking={handleCancelBooking}
          handleCancelModal={handleCancelModal}
        />
      )}
      <PositionedSnackbar />
    </>
  );
};

export default CancelBookingView;
