import React from "react";
import { AboutUs } from "../components/AboutUs/aboutUs";

export const AboutUsView = () => {

  return (
    <div>
      <AboutUs />
    </div>
  );
};
