import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postWebCheckIn } from "../../../../Api/Booking";
import { ancillaryStepHandler } from "../../../../Redux/CartReducer/cart.action";
import { seatCheckInHandler } from "../../../../Redux/CheckInReducer/checkIn.action";
import { toastHandler } from "../../../../Redux/userReducer/user.action";
import { colorPalette } from "../../../../theme/color-palette";
import { useUrlExtract } from "../../../../utils/utils";
import { MobileAmountFooter } from "../../../MobileAmountFooter";
import { Navbar } from "../../../Navbar/Mobile";
import { SeatTypes } from "../../../seatMap/Common/SeatTypes";
import { CustomDrawer } from "../../../common/Drawer";
import CloseIcon from "@mui/icons-material/Close";

const { white500, primary500 } = colorPalette;

const Layout = ({
  label,
  reviewData,
  newItmAnc,
  selectedSeat,
  webCheckInBody,
  allAncillaries,
  children,
}) => {
  const [CartDetailsDrawer, setCartDetailsDrawer] = useState({
    bottom: false,
  });
  const [ancillaryFee, setAncillaryFee] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    user: { openToast },
    checkIn: { seatCheckIn },
  } = useSelector((state) => state);

  const { bookingId } = useUrlExtract("bookingId");

  useEffect(() => {
    if (allAncillaries?.length) {
      const ancFee = allAncillaries
        ?.filter((item) => item?.qty > 0)
        ?.reduce((acc, curr) => {
          acc += curr?.fee;
          return acc;
        }, 0);
      setAncillaryFee(ancFee);
    }
  }, [allAncillaries]);

  const continueToAncillary = () => {
    // if (selectionStep === 1 && tripType === ROUNDTRIP) {
    //   setSelectionStep(2);
    // } else {
    //   postSeatMap(bookingData?.id, seatBody);
    //   dispatch(ancillaryStepHandler(2));
    // }
    dispatch(seatCheckInHandler(selectedSeat));
    dispatch(ancillaryStepHandler(4));
    navigate(`/ancillary?bookingId=${bookingId}`);
  };

  const continueToPayment = () => {
    postWebCheckIn(bookingId, webCheckInBody)
      .then((data) => {
        window.location.href = data?.data;
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
      });
  };

  return (
    <div>
      <Navbar
        color={white500}
        bgColor={primary500}
        label={label}
        showMenu={false}
        // onClose={() => dispatch(ancillaryStepHandler(ancillaryStep - 1))}
      />
      {/* <Grid container>
        {tripType === ROUNDTRIP
          ? MOBILE_SEARCH_TAB?.map((item, index) => (
              <Tab
                key={index}
                onClick={() => setSelectionStep(index + 1)}
                item
                selected={index + 1 === selectionStep}
                xs={6}
              >
                {item}
              </Tab>
            ))
          : ""}
      </Grid> */}
      <Container>
        <div>{children}</div>
      </Container>
      {label?.includes("seat") && (
        <div className='shadow-md pt-2 fixed bottom-17 w-full bg-white'>
          <SeatTypes />
        </div>
      )}
      <MobileAmountFooter
        label={"Continue"}
        CartDetailsDrawer={CartDetailsDrawer}
        setCartDetailsDrawer={setCartDetailsDrawer}
        data={reviewData}
        usedIn={"seat-anc"}
        onClickHandler={
          label?.includes("seat") ? continueToAncillary : continueToPayment
        }
        total={
          (seatCheckIn?.length
            ? seatCheckIn?.[0]?.price
            : selectedSeat?.[0]?.price) + ancillaryFee
        }
      />
      <CustomDrawer
        position={"bottom"}
        state={CartDetailsDrawer}
        setState={(val) => setCartDetailsDrawer(val)}
        label={""}
      >
        <div>
          <div className='shadow-lg h-14 text-white bg-primary '>
            <Container className='h-full'>
              <div className='flex justify-between items-center h-full'>
                <div>
                  <p className='text-sm'>Fare Breakup</p>
                </div>
                <CloseIcon
                  onClick={() => setCartDetailsDrawer({ bottom: false })}
                  className='text-white'
                />
              </div>
            </Container>
          </div>
          <div className='mt-4 p-2'>
            <p className='flex justify-between text-xs mb-2'>
              <span>Seat</span>
              <span>
                ₹{" "}
                {(seatCheckIn?.length
                  ? seatCheckIn?.[0]?.price
                  : selectedSeat?.[0]?.price) || 0}
              </span>
            </p>
            <p className='flex justify-between text-xs mb-16'>
              <span>Ancillary</span>
              <span>₹ {ancillaryFee || 0}</span>
            </p>
          </div>
        </div>
      </CustomDrawer>
    </div>
  );
};

export default Layout;

// const Tab = styled(Grid)`
//   color: ${({ selected }) => (selected ? black500 : gray600)};
//   text-align: center;
//   font-size: 14px;
//   border-bottom: ${({ selected }) =>
//     selected ? "3px solid" + primary500 : "1px solid" + black400};
//   padding: 14px 0px;
//   &:nth-child(2) {
//     border-left: 1px solid rgba(0, 0, 0, 0.12);
//   }
// `;
