import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

export default function DefaultToast({ open,message,severity }){
    return (
        <div>
    <Snackbar
        anchorOrigin={{ horizontal:"right", vertical:"top" }}
        open={open}
        message={"Unable to discuus Protocal"}
        autoHideDuration={2000}
      >
        <Alert severity={severity}>
          {message}
        </Alert>
      </Snackbar>
        </div>
      );
}