import React, { useEffect, useState } from 'react';
import HeaderNavSearch from '../../searchResults/Desktop/headerResultPage/HeaderNavSearch';
import './seatMap.scss';
import flightUpSvg from '../../../assets/svgs/flight-up.svg';
import crossFlight from '../../../assets/svgs/crossMIniFlight.svg';
import { Grid } from '@mui/material';
import { SelectedSeat } from './common/SelectedSeat';
import CommonSeatMap from '../Common';
import { SeatTypes } from '../Common/SeatTypes';
import { ancillaryStepHandler } from '../../../Redux/CartReducer/cart.action';
import { useDispatch, useSelector } from 'react-redux';
import { getFormattedDate, useUrlExtract } from '../../../utils/utils';
import { SkeletonComp } from '../../common/Skeleton/Skeleton';
import { DATE_FORMAT } from '../../../utils/constant';
import { useNavigate } from 'react-router-dom';
import { seatCheckInHandler } from '../../../Redux/CheckInReducer/checkIn.action';

export const DesktopSeatMap = ({
  selectedSeatHandler,
  seatmap,
  type,
  seatBody,
  allBookingData,
  selectedSeat,
}) => {
  const [scheduleState, setScheduleState] = useState('');
  const [returnScheduleState, setReturnScheduleState] = useState('');
  const [isContinueDisabled, setIsContinueDisabled] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    checkIn: { paxCheckIn },
  } = useSelector((state) => state);

  const { bookingId } = useUrlExtract('bookingId');

  useEffect(() => {
    selectedSeat?.length
      ? setIsContinueDisabled(false)
      : setIsContinueDisabled(true);
  }, [selectedSeat]);

  useEffect(() => {
    if (allBookingData?.selectedFare?.schedule?.length) {
      setScheduleState(allBookingData?.selectedFare?.schedule?.[0]);
    }
    if (allBookingData?.selectedFare?.returnSchedule?.length) {
      setReturnScheduleState(allBookingData?.selectedFare?.returnSchedule?.[0]);
    }
  }, [allBookingData]);

  const continueToAncillary = () => {
    dispatch(seatCheckInHandler(selectedSeat));
    dispatch(ancillaryStepHandler(4));
    navigate(`/ancillary?bookingId=${bookingId}`);
  };

  const {
    origin,
    destination,
    flightNumber,
    aircraft,
    departureTime,
    arrivalTime,
    departureDate,
    arrivalDate,
  } = scheduleState;

  const {
    origin: originReturn,
    destination: destinationReturn,
    flightNumber: flightNumberReturn,
    aircraft: aircraftReturn,
    departureTime: departureTimeReturn,
    arrivalTime: arrivalTimeReturn,
    departureDate: departureDateReturn,
    arrivalDate: arrivalDateReturn,
  } = returnScheduleState;

  return (
    <>
      <HeaderNavSearch />
      <div className='container-seat-map-desk max-width w-full m-auto p-4'>
        <div className='onward-heading mt-4 mb-6'>
          <img src={flightUpSvg} alt='flight up' />
          <span className='text-base font350 ml-4'>
            {scheduleState ? (
              <>
                <span className='gotham-rounded'>Departure</span> -{' '}
                <span className='gotham-book'>
                  {origin?.city} to {destination?.city}
                </span>
              </>
            ) : (
              <SkeletonComp />
            )}
          </span>
        </div>

        <div className='flight-details-box w-full m-auto max-width border'>
          <div className='flight-details-header border uppercase flex bg-black text-white'>
            <span className='font-normal text-xs ml-6'>Flight Details</span>
            <span className='text-xs font-light ml-8'>
              FLIGHT NO - {flightNumber}
            </span>
            <span className='text-xs font-light ml-8'>{aircraft}</span>
          </div>

          <div className='depart-arrive-info mt-2 '>
            <p className='text-sm font350 flex justify-between mb-2 pl-20 pr-43'>
              <span>DEPARTURE</span>
              <span>ARRIVAL</span>
            </p>
            <p className='text-sm font350 text-primary flex justify-between pl-20 pr-43'>
              <span>{departureTime}</span>
              {arrivalTime}
            </p>

            <section className='relative flght-dtls-cross-svg-section'>
              <div className='border-line flex justify-between'>
                <span className='dot-before absolute'></span>
                <span className='line'></span>
                <img
                  src={crossFlight}
                  alt='flight icon'
                  className='absolute flght-dtls-cross-svg z-1'
                />
                <span className='dot-after absolute'></span>
              </div>
            </section>

            <div className='cities-dtls flex justify-between mt-4 pl-20 pr-20'>
              <section>
                <p className='font-350 text-base review-citycode text-white bg-black text-center'>
                  {/* {type === "depart" ? origin?.code : originReturn?.code} */}
                  {origin?.code}
                </p>

                <p className='font-normal text-xs mb-1 review-cityname uppercase'>
                  {/* {type === "depart" ? origin?.city : originReturn?.city}{" "} */}
                  {origin?.city} airport
                </p>

                <p className='font-light text-xxs mb-1 gotham-book'>
                  {getFormattedDate(departureDate, DATE_FORMAT?.DATE8)}
                </p>
              </section>
              <section>
                <p className='font-350 text-base review-citycode text-white bg-black text-center'>
                  {destination?.code}
                </p>
                <p className='font-normal text-xs mb-1 review-cityname uppercase'>
                  {destination?.city} airport
                </p>
                <p className='font-light text-xxs mb-1 gotham-book'>
                  {getFormattedDate(arrivalDate, DATE_FORMAT?.DATE8)}
                </p>
              </section>
            </div>
          </div>
        </div>

        <div className='select-seat-sub-headborder font350 mt-8 mb-8'>
          <p className=' text-base'>Select Your Seat</p>
        </div>

        <Grid container className='flex justify-between'>
          {allBookingData ? (
            <Grid
              item
              lg={3}
              md={3}
              sm={3}
              className='selected-seat-info-box border'
            >
              <div>
                <SelectedSeat
                  flightSvg={flightUpSvg}
                  origin={origin?.city}
                  destination={destination?.city}
                  data={paxCheckIn}
                />
              </div>
            </Grid>
          ) : (
            <SkeletonComp />
          )}
          <Grid item lg={4} md={4} sm={4} className='seat-map-layout-container'>
            <div className='seat-map-info'>
              <SeatTypes screen='desktop' />
            </div>
            {seatmap?.data?.length ? (
              <div className='seat-map-layout-box border'>
                <CommonSeatMap
                  selectedSeatHandler={selectedSeatHandler}
                  seatMapData={seatmap}
                />
              </div>
            ) : (
              <SkeletonComp height={'15rem'} />
            )}
          </Grid>
          <div>
            <Grid item lg={3} md={3} sm={3}>
              <div className='fare-summary-box-st border w-full h-full'>
                <section className='fee-surcharge-div flex items-center pl-2'>
                  <span className='font350 text-sm ml-2'>
                    Ancillary Services
                  </span>
                </section>
                <p className='font350 text-xxs flex justify-between line-height17 m-2'>
                  <span className='pl-6'>Seats</span>
                  <span className='pr-6'>
                    ₹ {selectedSeat?.[0]?.price || 0}
                  </span>
                </p>
                <p className='font350 text-xxs flex justify-between line-height17 m-2'>
                  <span className='pl-6'>Ancillaries</span>
                  <span className='pr-6'>₹ 0</span>
                </p>
              </div>
            </Grid>
          </div>
        </Grid>

        <div className='btn-group-seatmap flex justify-center items-center w-full max-width m-auto mt-16'>
          {/* <button
            className='uppercase btn-skip cursor-pointer'
            onClick={skipToAncillary}
          >
            Skip
          </button> */}
          <button
            className='uppercase btn-continue ml-6 cursor-pointer'
            onClick={() => continueToAncillary()}
            disabled={isContinueDisabled}
          >
            continue
          </button>
        </div>
      </div>
    </>
  );
};
