import { userActionType } from "./user.actionType";

const { LOGIN, LOGOUT, TOAST, UPDATE_PASSENGERS, BOOKING_ID, CANCEL_BOOKING } =
  userActionType;

const initialState = {
  isLogin: false,
  bookingData: "",
  passengerList: [],
  openToast: {
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, isLogin: true };
    case LOGOUT:
      return { ...state, isLogin: false };
    case TOAST:
      return { ...state, openToast: action.payload };
    case UPDATE_PASSENGERS:
      return { ...state, passengerList: action.payload };
    case BOOKING_ID:
      return { ...state, bookingData: action.payload };
    case CANCEL_BOOKING:
      return { ...state, cancelledBooking: action.payload };
    default:
      return state;
  }
};
