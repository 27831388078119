import React from 'react';
import PositionedSnackbar from '../components/common/Toast';
import { MyBookingsAndProfile } from '../components/profileDetails-Bookings/desktop/MyBookingsAndProfile';
import MyBookingMobile from '../components/profileDetails-Bookings/mobile/myBookings';
import { useWindowSize } from '../utils/utils';

export const MyBookingsView = () => {
  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;
  return (
    <>
      {isMobile ? <MyBookingMobile /> : <MyBookingsAndProfile tabV={0} />}
      <PositionedSnackbar />
    </>
  );
};
