import { Grid } from "@mui/material";
import React from "react";
import FlightUp from "../../../../assets/svgs/flightup.svg";
import { useUrlExtract } from "../../../../utils/utils";

export const EditSearch = ({
  flightDeparture,
  flightArrival,
  departureDate,
  setEditDrawer,
  passengerNumbers: { adult, children, infant },
  passengerNumbers,
}) => {
  const { bookingType } = useUrlExtract("all");

  return (
    <Grid container>
      <Grid item xs={1}>
        <img src={FlightUp} alt='flightup' />
      </Grid>
      <Grid item xs={8} className='pl-1'>
        <p className='text-xs'>
          {flightDeparture?.city} to {flightArrival?.city}
        </p>
        <p className='text-xxxs mt-1'>
          {departureDate} | {adult} Adults, {children} Child, {infant} Infants
        </p>
      </Grid>
      {bookingType !== "modify" && (
        <Grid item xs={3} onClick={() => setEditDrawer({ top: true })}>
          <p className='text-link500 text-xxs text-right'>Edit Search</p>
        </Grid>
      )}
    </Grid>
  );
};
