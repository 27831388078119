import axiosInstance from "../axiosInstance";
import { BOOKING_PROFILE_ENDPOINTS } from "../endpoints";

const { PROFILE, PASSENGER } = BOOKING_PROFILE_ENDPOINTS;

export const getProfile = () => axiosInstance.get(PROFILE);

export const patchProfile = (body) => axiosInstance.patch(PROFILE, body);

export const getAllPassengers = () => axiosInstance.get(PASSENGER);

export const addPassenger = (body) => axiosInstance.post(PASSENGER, body);

export const editPassenger = (body, passengerId) =>
  axiosInstance.patch(PASSENGER + "/" + passengerId, body);

export const deletePassenger = (passengerId) =>
  axiosInstance.delete(PASSENGER + "/" + passengerId);
