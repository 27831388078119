import { Avatar, Container, Divider } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { colorPalette } from "../../../theme/color-palette";
import { CustomDrawer } from "../../common/Drawer";
import { Navbar } from "../Mobile";
import { useDispatch, useSelector } from "react-redux";
import { userLogOut } from "../../../Api/login";
import {
  logOutHandler,
  toastHandler,
} from "../../../Redux/userReducer/user.action";
import MyBookingIcon from "../../../assets/svgs/myBooking.svg";
import ProfileIcon from "../../../assets/svgs/profile.svg";
import LogoutIcon from "../../../assets/svgs/logout.svg";

const ProfileDropdown = ({ profileDrawer, setProfileDrawer }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    user: { openToast },
  } = useSelector((state) => state);

  const loggedUser = JSON.parse(localStorage.getItem("user"));
  const { orange400, primary500, white500 } = colorPalette;

  const handleProfile = () => {
    navigate("/my-profile");
  };

  const handleLogout = () => {
    const refreshToken = JSON.parse(localStorage.getItem("user"))?.tokens
      ?.refresh?.token;
    userLogOut({ refreshToken })
      .then((data) => {
        localStorage.clear();
        dispatch(logOutHandler());
        setProfileDrawer({ bottom: false });
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
      });
  };

  const handleMyBookings = () => {
    navigate("/my-bookings");
  };

  return (
    <div>
      <CustomDrawer
        position={"bottom"}
        state={profileDrawer}
        setState={(val) => setProfileDrawer(val)}
        label={""}
      >
        <div>
          <Navbar
            color={white500}
            bgColor={primary500}
            label={""}
            showMenu={false}
            onClose={() => {
              setProfileDrawer({ bottom: false });
            }}
          />
        </div>
        <Container className="w-full h-screen p-0">
          <section className="flex items-center p-4 bg-black">
            <Avatar sx={{ background: orange400 }} className="">
              {loggedUser?.user?.name[0].toUpperCase()}
            </Avatar>
            <div className="ml-4">
              <p className="text-sm text-white font-350">
                {loggedUser?.user?.name}{" "}
              </p>
              <p className="text-xs text-white font-normal gotham-book">
                {loggedUser?.user?.email}{" "}
              </p>
              {/* <p className="text-link500 cursor-pointer">Edit Profile</p> */}
            </div>
          </section>
          <Divider className="mb-4" />
          <ul>
            <li
              className="mb-3 cursor-pointer flex items-center px-4"
              onClick={handleMyBookings}
            >
              <img src={MyBookingIcon} alt="my booking" />
              <span className="ml-2">My Bookings</span>
            </li>
            <Divider className="my-4" />

            <li
              className="mb-3 cursor-pointer flex items-center px-4"
              onClick={handleProfile}
            >
              <img src={ProfileIcon} alt="profile" />
              <span className="ml-2">My Profile & Settings</span>
            </li>
            <Divider className="my-4" />

            <li
              className="cursor-pointer flex items-center px-4"
              onClick={handleLogout}
            >
              <img src={LogoutIcon} alt="profile" />
              <span className="ml-2">Log out</span>
            </li>
          </ul>
        </Container>
      </CustomDrawer>
    </div>
  );
};

export default ProfileDropdown;
