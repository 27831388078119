import React from "react";
import ModifySearchBar from "../searchResults/Desktop/modifySearchBar/ModifySearchBar";
import HeaderNavSearch from "../searchResults/Desktop/headerResultPage/HeaderNavSearch";
import "./noResultPage.scss";

export function NoresultsPage() {
  return (
    <>
      <HeaderNavSearch />
      <ModifySearchBar />

      <div className='no-flight-info mt-12'>
        <p className='text-center text-base font350 p-no-flight'>
          No Flights found for your search?
        </p>
        <p className='font325 text-xs text-center mt-4'>
          Use the calendar below to select dates that are available. If no
          flights are available below, please modify your dates or destination
          using the Edit Search button above.
        </p>
      </div>

      <div className='w-full m-auto'>{/* <Example /> */}</div>
    </>
  );
}
