import React, { useEffect, useState } from "react";
import { Autocomplete, Grid, Popover, TextField } from "@mui/material";
import { DATE_FORMAT, ROUNDTRIP, TRIP_TYPE } from "../../../../utils/constant";
import { SelectPassenger } from "../../../homepage/Common/Select-Passenger";
import { RadioBtnGrp } from "../../../common/radio-buttons/RadioBtnGrp";
import arrowSvg from "../../../../assets/svgs/switchIcon.svg";
import "./modifySearchBar.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormattedDate,
  handlePassengerValue,
  handleSwitchCities,
} from "../../../../utils/utils";
import { DateRange } from "../../../common/DateRange";
import { searchDetailsHandler } from "../../../../Redux/searchReducer/search.action";
import { searchfareCalender } from "../../../../Api/search";

export default function ModifySearchBar({
  tripType,
  setTripType,
  departureCityValue,
  setDepartureCityValue,
  arrivalCityValue,
  setArrivalCityValue,
  dateFromState,
  passengerNumbers,
  setPassengerNumbers,
}) {
  const [anchorElDepartDate, setAnchorElDepartDate] = useState(null);
  const [anchorElReturnDate, setAnchorElReturnDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modifyBtnState, setModifyBtnState] = useState(true);
  const [fareCalender, setFareCalender] = useState({});

  const { search } = useSelector((state) => state);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { DATE4, DATE3 } = DATE_FORMAT;

  let { adult, children, infant } = passengerNumbers;

  const {
    from: fromDate,
    to: toDate,
    enteredTo: enteredToDate,
  } = dateFromState;

  const [date, setDate] = useState({
    from: new Date(fromDate),
    to: new Date(toDate),
    enteredTo: new Date(enteredToDate),
  });

  const { from, to } = date;

  useEffect(() => {
    if (
      !departureCityValue?.city ||
      !arrivalCityValue?.city ||
      !adult ||
      !from ||
      (tripType === "Round Trip" && !to)
    ) {
      setModifyBtnState(true);
    }
  }, [
    departureCityValue?.city,
    arrivalCityValue?.city,
    adult,
    from,
    to,
    tripType,
  ]);

  //popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const customId = open ? "simple-popover-passenger-modify" : undefined;

  //modify serch
  const handleModifySearch = () => {
    dispatch(
      searchDetailsHandler({
        departure: departureCityValue,
        arrival: arrivalCityValue,
        attendantType: search?.searchDetails?.attendantType,
        passengerNumbers: passengerNumbers,
        tripType: tripType,
        date: date,
      })
    );

    const fromDate = getFormattedDate(from, DATE4);
    const toDate = getFormattedDate(to, DATE4);
    const urlItinerary =
      tripType === "Round Trip"
        ? `${departureCityValue?.code}-${arrivalCityValue?.code}-${fromDate}_${arrivalCityValue?.code}-${departureCityValue?.code}-${toDate}`
        : `${departureCityValue?.code}-${arrivalCityValue?.code}-${fromDate}`;

    navigate(
      `/search-result?tripType=${tripType[0]}&itinerary=${urlItinerary}&paxType=A-${adult}_C-${children}_I-${infant}&atdType=${search?.searchDetails?.attendantType}`
    );
  };

  ///// date picker
  const singleDateHandlerDepart = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    setDate({
      ...date,
      from: day,
      to: "",
    });
    setAnchorElDepartDate(null);
    setModifyBtnState(false);
  };

  const singleDateHandlerReturn = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    setDate({
      ...date,
      to: day,
    });
    setAnchorElReturnDate(null);
    setModifyBtnState(false);
  };

  const handleClickDapartDate = (event) => {
    tripType === "One Way"
      ? setDate({ from: from, to: "" })
      : setDate({ from: from, to: to });
    setAnchorElDepartDate(event.currentTarget);
    searchfareCalender(departureCityValue?.code, arrivalCityValue?.code).then(
      ({ data }) => {
        setFareCalender(data?.prices);
      }
    );
  };

  const handleCloseDepartDate = () => {
    setAnchorElDepartDate(null);
  };

  const handleClickReturnDate = (event) => {
    setTripType(ROUNDTRIP);
    setDate({ ...date, from: from, to: to });
    setAnchorElReturnDate(event.currentTarget);
    tripType === "One Way" && setModifyBtnState(false);
    searchfareCalender(arrivalCityValue?.code, departureCityValue?.code).then(
      ({ data }) => {
        setFareCalender(data?.prices);
      }
    );
  };

  const handleCloseReturnDate = () => {
    setAnchorElReturnDate(null);
  };

  const anchorElDepartDateOpen = Boolean(anchorElDepartDate);

  const handleSetTripType = (currrentVal) => {
    setTripType(currrentVal);
    setModifyBtnState(false);
  };

  return (
    <Grid
      container
      spacing={0}
      className="flight-search flex items-center justify-between mt-4 m-auto pb-4"
    >
      <Grid item lg={1.1} md={2} sm={2} className="radio-btn-grp">
        <RadioBtnGrp
          value={tripType}
          handleChange={handleSetTripType}
          inputArray={TRIP_TYPE}
          isRow={false}
        />
      </Grid>

      <Grid
        item
        lg={3.5}
        md={4.5}
        sm={4.5}
        className="from-to flex justify-center items-center"
      >
        <div className="from w-full">
          <p className="font350 text-xs mb-1">From</p>

          <div className="input-custom p-2">
            <Autocomplete
              autoComplete
              id="combo-box-demo"
              options={search?.flightList}
              getOptionLabel={(option) => {
                return option?.city;
              }}
              openOnFocus
              includeInputInList
              value={departureCityValue}
              onChange={(event, newValue) => {
                setArrivalCityValue({
                  code: "",
                  airport: "",
                  city: "",
                });
                setDepartureCityValue(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                  variant="standard"
                />
              )}
            />
          </div>
        </div>
        <img
          src={arrowSvg}
          alt="switch arrow"
          className="mt-6 m-1 cursor-pointer"
          onClick={() => {
            handleSwitchCities(
              search?.flightList,
              arrivalCityValue,
              departureCityValue,
              setDepartureCityValue,
              setArrivalCityValue
            );
            setModifyBtnState(false);
          }}
        />
        <div className="to w-full">
          <p className="font350 text-xs mb-1">To</p>
          <div className="input-custom p-2 cursor-pointer">
            <Autocomplete
              autoComplete
              id="combo-box-demo"
              options={
                departureCityValue?.destinations?.length
                  ? departureCityValue?.destinations
                  : [departureCityValue?.destinations]
              }
              getOptionLabel={(option) => {
                return option?.city;
              }}
              openOnFocus
              includeInputInList
              value={arrivalCityValue || departureCityValue?.destinations?.[0]}
              onChange={(event, newValue) => {
                setArrivalCityValue(newValue);
                newValue?.city && setModifyBtnState(false);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                  variant="standard"
                />
              )}
            />
          </div>
        </div>
      </Grid>

      <Grid item lg={3.2} md={4.5} sm={4.5} className="flex justify-evenly">
        <div className="depart-date mr-4 w-full">
          <p className="font350 text-xs mb-1">Departure Date</p>
          <div
            className="input-custom m-auto p-3 cursor-pointer"
            onClick={handleClickDapartDate}
          >
            {getFormattedDate(date?.from, DATE3)}
          </div>
          <Popover
            open={anchorElDepartDateOpen}
            anchorEl={anchorElDepartDate}
            onClose={handleCloseDepartDate}
          >
            <DateRange
              state={date}
              setState={setDate}
              handleDayClick={singleDateHandlerDepart}
              numberOfMonths={1}
              canChangeMonth={true}
              selectedDays={from}
              fareCalender={fareCalender}
            />
          </Popover>
        </div>

        <div className="return-date w-full">
          <p className="font350 text-xs mb-1">Return Date</p>
          {tripType === "Round Trip" ? (
            <div
              className="input-custom m-auto p-3 cursor-pointer"
              onClick={handleClickReturnDate}
            >
              {to === "" ? "" : getFormattedDate(date?.to, DATE3)}
            </div>
          ) : (
            <div
              className="input-custom m-auto p-3 cursor-pointer text-gray600"
              onClick={handleClickReturnDate}
            >
              <span>Round Trip +</span>
            </div>
          )}
          <Popover
            open={Boolean(anchorElReturnDate)}
            anchorEl={anchorElReturnDate}
            onClose={handleCloseReturnDate}
          >
            <DateRange
              state={date}
              setState={setDate}
              handleDayClick={singleDateHandlerReturn}
              numberOfMonths={1}
              canChangeMonth={true}
              disabledDays={{ before: from }}
              selectedDays={[to]}
              fareCalender={fareCalender}
            />
          </Popover>
        </div>
      </Grid>

      <Grid item lg={1.8} md={2.5} sm={2.5} className="passanger">
        <p className="font350 text-xs mb-1">Guest</p>
        <div
          className="input-custom m-auto p-3 cursor-pointer"
          onClick={handleClick}
        >
          {adult + children + infant} Guest(s)
        </div>

        <Popover
          id={customId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          <SelectPassenger
            buttonWidth={"90px"}
            handleClose={handleClose}
            passengerNumbers={passengerNumbers}
            setPassengerNumbers={setPassengerNumbers}
            handlePassengerValue={handlePassengerValue}
            setModifyBtnState={setModifyBtnState}
            usedIn="modifyDesk"
          />
        </Popover>
      </Grid>

      <Grid item lg={1.8} md={2.5} sm={2.5} className="modify-search-btn">
        <button
          className="btn-modify-search cursor-pointer"
          onClick={() => handleModifySearch()}
          disabled={modifyBtnState}
        >
          MODIFY SEARCH
        </button>
      </Grid>
    </Grid>
  );
}
