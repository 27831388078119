import React from "react";
import { Grid, Container } from "@mui/material";
import "./index.scss";

export const Card = ({ item, onChangeHandler, faresDetails }) => {
  const thisFareDetails = faresDetails?.filter(
    (obj) => obj?.fareName?.toUpperCase() === item?.fareBasisName?.toUpperCase()
  );

  return (
    <div className='mt-3 flight_card'>
      <div className='h-10'>
        <Grid container className='h-full'>
          <Grid item xs={6}>
            <div className='bg-primary h-full'>
              <p className='h-full flex items-center justify-center text-white text-xxs'>
                {(item?.fareBasisName).toUpperCase()}
              </p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className='text-right bg-gray100 h-full flex justify-end items-center'>
              <Container>
                <span className='mr-2'>₹ {item?.price}</span>

                <input
                  className='price_radio_button'
                  type='radio'
                  name='pricing'
                  value={item?.price}
                  onChange={() => {
                    onChangeHandler(item?.fareBasisCode, item);
                  }}
                />
              </Container>
            </div>
          </Grid>
        </Grid>
      </div>
      {/* <div className='bg-primary200 h-10 '>
        <Container className='h-full '>
          <div className='flex items-center h-full'>
            <img src={luggage} alt='luggage' />
            <p className='ml-2 text-xxxs'>Checkin Baggage</p>
            <p className='ml-2 text-xxxs'>1x15KG</p>
          </div>
        </Container>
      </div> */}
      <div className='my-1'>
        <Container>
          <Grid container className='py-1'>
            {thisFareDetails?.[0]?.details?.map((val, index) => (
              <Grid key={index} item xs={4} className='flight_card_properties'>
                <div className='text-center py-2 '>
                  <img src={val.url} alt={val.name} />
                  <p className='text-xxxs mt-1'>{val?.name}</p>
                  <p className='text-xxxs mt-1 gotham-book'>
                    {val?.description}
                  </p>
                </div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </div>
  );
};
