import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bookingDetailsApi, getSeatInfo, getSeatMap } from '../../Api/Booking';
import { useScrollTop } from '../../customHooks/useScrollTop';
import { seatDataHandler } from '../../Redux/CartReducer/cart.action';
import { seatCheckInHandler } from '../../Redux/CheckInReducer/checkIn.action';
import { toastHandler } from '../../Redux/userReducer/user.action';
import { useUrlExtract, useWindowSize } from '../../utils/utils';
import Layout from '../passenger_info/Mobile/Layout';
import { DesktopSeatMap } from './Desktop/DesktopSeatMap';
import './index.scss';
import MobileSeatMap from './Mobile';

export const SeatMap = () => {
  const [selectedSeat, setSelectedSeat] = useState([]);
  const [selectedSeatReturn, setSelectedSeatReturn] = useState([]);
  const [seatMapData, setSeatMapData] = useState([]);
  const [seatMapDataReturn, setSeatMapDataReturn] = useState([]);
  const [seatBody, setSeatBody] = useState({});
  const [emergencySeat, setEmergencySeat] = useState([]);
  const [selectionStep, setSelectionStep] = useState(1);
  const [allBookingData, setAllBookingData] = useState({});
  const [selectionType, setSelectionType] = useState('');

  useScrollTop();
  const dispatch = useDispatch();
  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;

  const {
    user: { openToast },
    cart: { seatData, ancillaryStep },
    checkIn: { checkInData, paxCheckIn },
  } = useSelector((state) => state);

  const bookLegIdDeparture = checkInData?.routes?.departure?.bookLegId;
  const bookLegIdReturn = checkInData?.routes?.return?.bookLegId;

  const { bookingId } = useUrlExtract('bookingId');

  useEffect(() => {
    ancillaryStep === 0
      ? setSelectionType('depart')
      : setSelectionType('return');
  }, [ancillaryStep]);

  useEffect(() => {
    if (paxCheckIn?.length > 0 && Object.keys(allBookingData).length === 0) {
      bookingDetailsApi(bookingId)
        .then(({ data }) => {
          setAllBookingData(data);

          getSeatInfo(
            paxCheckIn?.[0]?.isReturn
              ? data?.returnFlightNo
              : data?.departureFlightNo
          ).then(({ data }) => {
            setEmergencySeat(data);
          });
        })
        .catch((err) => {
          dispatch(
            toastHandler({
              ...openToast,
              open: true,
              message: err?.response?.data?.message,
              severity: 'error',
            })
          );
        });
    }
  }, [paxCheckIn]);

  useEffect(() => {
    bookingId &&
      paxCheckIn?.length > 0 &&
      seatMapData?.length === 0 &&
      getSeatMap(bookingId)
        .then(({ data }) => {
          setSeatMapData(
            paxCheckIn?.[0]?.isReturn ? data?.return : data?.departure
          );
          checkInData?.tripType === 'RoundTrip' &&
            setSeatMapDataReturn(data?.return);
        })
        .catch((err) =>
          dispatch(
            toastHandler({
              ...openToast,
              open: true,
              message: err?.response?.data?.message,
              severity: 'error',
            })
          )
        );
  }, [bookingId, paxCheckIn]);

  useEffect(() => {
    const body = (
      paxCheckIn?.[0]?.isReturn
        ? checkInData?.returnPassengers
        : checkInData?.passengers
    )?.map((item, index) => {
      return isMobile
        ? {
            passengerId: item?.passenger?.id,
            seats: [
              {
                bookLegId: bookLegIdDeparture,
                seat: selectedSeat?.[index]?.name,
              },
              {
                bookLegId: bookLegIdReturn,
                seat: selectedSeatReturn?.[index]?.name,
              },
            ],
          }
        : {
            passengerId: item?.id,
            seats: [
              {
                bookLegId: paxCheckIn?.[0]?.isReturn
                  ? bookLegIdReturn
                  : bookLegIdDeparture,
                seat: selectedSeat?.[index]?.name,
              },
            ],
          };
    });
    const filteredBody = body?.filter((item) => item?.seats?.[0]?.seat);
    setSeatBody({ passengers: filteredBody });
  }, [selectedSeat?.length, selectedSeatReturn?.length]);

  const selectedSeatHandler = (id) => {
    let seatMapDataClone = JSON.parse(JSON.stringify(seatMapData));
    let seatSelectedClone = JSON.parse(JSON.stringify(selectedSeat));
    seatMapDataClone?.data.forEach((item) =>
      item?.forEach((val) => {
        if (val?.name === id && val?.selected) {
          seatSelectedClone = seatSelectedClone?.filter(
            (seat) => seat?.name !== id
          );
          delete val?.selected;
        } else if (
          val?.name === id &&
          seatSelectedClone?.length < paxCheckIn?.length &&
          val?.type !== 'Unavailable'
        ) {
          val.selected = 'Selected';
          seatSelectedClone.push(val);
        }
      })
    );
    setSelectedSeat(seatSelectedClone);
    setSeatMapData(seatMapDataClone);
  };

  return (
    <div>
      {isMobile ? (
        <Layout
          reviewData={allBookingData}
          label='Select your seat'
          seatBody={seatBody}
          selectedSeat={selectedSeat}
          // selectionStep={selectionStep}
          // setSelectionStep={setSelectionStep}
        >
          <MobileSeatMap
            selectedSeatHandler={selectedSeatHandler}
            seatmap={seatMapData}
          />
        </Layout>
      ) : (
        <DesktopSeatMap
          selectedSeatHandler={selectedSeatHandler}
          seatmap={seatMapData}
          type={selectionType}
          seatBody={seatBody}
          allBookingData={allBookingData}
          selectedSeat={selectedSeat}
        />
      )}
    </div>
  );
};
