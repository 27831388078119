import { Grid, Container } from "@mui/material";
import React from "react";
import { FlightType } from "../FlightType";
import clock from "../../assets/svgs/clock.svg";
import "./index.scss";
import { getFormattedDate } from "../../utils/utils";
import { DATE_FORMAT } from "../../utils/constant";

export const Card = ({
  item: {
    arrivalDate,
    arrivalTime,
    availability,
    departureDate,
    departureTime,
    destination,
    duration,
    flightNumber,
    noOfStops,
    origin,
    type = "noStop",
    fares,
    schedulePerSegment,
  },
  item,
  type: flightType,
  onClickHandler,
}) => {
  const location = window.location.pathname;

  return (
    <div
      className="flight_card"
      onClick={() => onClickHandler(item, flightType)}
    >
      <div className="h-10 bg-gray100 flex items-center">
        <Container>
          <p className="text-xxs text-right">
            FLIGHT NO - {flightNumber?.slice(3)}
          </p>
        </Container>
      </div>
      <div className="bg-white pt-4 pb-3">
        <Container>
          <Grid container>
            <Grid item xs={3}>
              <FlightDetails
                code={origin?.code}
                timing={departureTime}
                date={departureDate}
                location={origin?.city}
                align={"text-left"}
              />
            </Grid>
            <Grid item xs={6}>
              <div className="flight_card_type_container">
                <div className="text-center -mb-2">
                  <img src={clock} alt="timing" />
                  <p className="text-xxs text-gray700">
                    {/* {duration} */}
                    {schedulePerSegment?.length > 1 &&
                      schedulePerSegment?.map((item) => {
                        return (
                          item?.layover && (
                            <span className="text-center gotham-book text-xs ml-2">
                              {item?.layover}
                            </span>
                          )
                        );
                      })}
                  </p>
                </div>
                <div className="flex flex-col h-full justify-center pr-1">
                  <FlightType type={type} noOfStops={noOfStops} />
                </div>
                {noOfStops > 0 && (
                  <div className="text-center -mt-2">
                    <p className="text-xxs text-black400">
                      {schedulePerSegment?.[0]?.arrivalTime}
                    </p>
                    <p className="text-xxs text-gray700 mt-1">
                      {schedulePerSegment?.[0]?.destination?.code}
                    </p>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={3}>
              <FlightDetails
                code={destination?.code}
                timing={arrivalTime}
                date={arrivalDate}
                location={destination?.city}
                align={"text-right"}
              />{" "}
            </Grid>
          </Grid>
        </Container>
      </div>
      {location !== "/confirmation-page" ? (
        <div className="h-10 bg-gray100 flex items-center">
          <Container>
            <Grid container>
              <Grid item xs={6}>
                <p className="text-primary text-xxs flex items-center h-full">
                  {availability} seat(s) left at this price
                </p>
              </Grid>
              <Grid item xs={6}>
                <p className="text-right">
                  <span className="text-xxs">FROM</span>{" "}
                  <span>₹ {fares?.[0]?.price}</span>
                </p>
              </Grid>
            </Grid>
          </Container>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const FlightDetails = ({ code, timing, date, location, align }) => {
  const { DATE3 } = DATE_FORMAT;
  return (
    <div className={`${align}`}>
      <p className="text-sm ">
        <span className="bg-black text-white p-1">{code}</span>
      </p>
      <p className="text-sm text-blue500 mt-2">{timing}</p>
      <p className="text-xxs text-blue500 mt-1">{location}</p>
      <p className="text-xxs text-gray700 mt-2">
        {getFormattedDate(date, DATE3)}
      </p>
    </div>
  );
};
