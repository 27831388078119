import React from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import "./offer.scss";


export default function Offer({ offer, handelViewOfferButtonClick }) {
  return (
    <>
      <Card sx={{ maxWidth: 370 }} className="offer_card">
        <CardMedia
          component="img"
          height="240"
          image={offer.image}
          alt="Offer"
        />
        <div className="offer_imageOverlay">
          <spam className="offer_expiryDate">{offer.expiryDate}</spam>
          <spam className="offer_tnc">T&C apply*</spam>
        </div>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" className="offer_title">
            {offer.title}
          </Typography>
          <Typography variant="body2" color="text.secondary" className="offer_description">
            {offer.shortDescription}
          </Typography>
        </CardContent>
        <CardActions className="offer_buttonContainer">
          <Button variant="contained" className="offer_grabButton" onClick={() => handelViewOfferButtonClick(offer)}>Grab now</Button>
        </CardActions>
      </Card>
    </>
  );
}



