import React from "react";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuIcon from "@mui/icons-material/Menu";
import { Container } from "@mui/material";

export const Navbar = ({ label, icon, color, bgColor, showMenu, onClose }) => {
  return (
    <NavbarWrap color={color} bgColor={bgColor} className='mobile-home-nav'>
      <CustomContainer maxWidth='sm'>
        <div className='flex items-center'>
          {onClose && <ArrowBackIcon onClick={onClose} />}
          <NavLabel>{label}</NavLabel>
        </div>
        <div>{icon && <img alt='logo' src={icon} />}</div>
        <div>{showMenu && <MenuIcon />}</div>
      </CustomContainer>
    </NavbarWrap>
  );
};

const NavbarWrap = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  height: 56px;
  width: 100%;
`;

const NavLabel = styled.label`
  color: ${({ color }) => color};
  margin-left: 13px;
`;

const CustomContainer = styled(Container)`
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  height: 100%;
`;
