import React,{ useEffect,useState } from "react";
import { BookingDetails } from "../components/BookingDetails";
import PositionedSnackbar from "../components/common/Toast";
import { useScrollTop } from "../customHooks/useScrollTop";

export function BookingConfirmView() {
  useScrollTop();
  const reloadCount = sessionStorage.getItem("reloadCount")
  useEffect(() => {
    if(reloadCount < 2) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      setTimeout(()=>window.location.reload(),200) 
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  }, []);
  return (
    <>
      <BookingDetails />
      <PositionedSnackbar />
    </>
  );
}
