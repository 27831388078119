import React from "react";
import styled from "styled-components";
import { colorPalette } from "../../../../theme/color-palette";

const { primary500, white500 } = colorPalette;

const Service = ({ item, index, ancillaryHandler }) => {
  return (
    <div key={index} className='flex justify-between py-3 border-b-lightgrey'>
      {/* <img src={item?.icon} alt={item?.name} /> */}
      <div className='flex-grow ml-4'>
        <p className='text-xs'>
          {item?.name}{" "}
          <span>{item?.weight ? `( ${item?.weight} Kgs)` : ""}</span>
        </p>
        <p className='text-xxs text-gray800'>{item?.fee}</p>
      </div>
      <div className='flex'>
        <CustomButton
          background={primary500}
          color={white500}
          onClick={() => ancillaryHandler(item, index, "subtract")}
        >
          -
        </CustomButton>
        <div className='flex justify-center items-center bg-white h-6 w-6'>
          {item?.qty}
        </div>
        <CustomButton
          background={primary500}
          color={white500}
          onClick={() => ancillaryHandler(item, index, "add")}
        >
          +
        </CustomButton>
      </div>
    </div>
  );
};

export default Service;

export const CustomButton = styled.div`
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
`;
