import React, { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { DetailsForFlightSearch } from "../../../common/DetailsForFlightSearch/DetailsForFlightSearch";
import Slider from "react-slick";

export const HeroBanner = React.memo(
  ({
    date,
    setDate,
    anchorElCalender,
    setAnchorElCalender,
    tripType,
    setTripType,
    departureLocation,
    setDepartureLocation,
    arrivalLocation,
    setArrivalLocation,
    attendantType,
    setAttendantType,
    flightListState,
    setFlightListState,
    submitDataHandler,
    flightList,
    setPassengerNumbers,
    passengerNumbers,
    uiContent,
  }) => {
    const [tabValue, setTabValue] = useState(0);

    const handleChangeTabs = (event, newValue) => {
      if (newValue === 1) {
        window.open("https://flybig.paxlinks.com/web-checkin");
      }

      if (newValue === 2) {
        window.open("https://flybig.paxlinks.com/manage-booking");
      }
    };

    function a11yProps(index) {
      if (index === 0) {
        return {
          id: `simple-tab-${index}`,
          className: `active-tab-custom`,
        };
      } else {
        return { id: `simple-tab-${index}` };
      }
    }

    const carouselSetting = {
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      cssEase: "linear",
    };

    return (
      <>
        <main>
          <div className="m-auto z--10 home-banner-carousel">
            <Slider {...carouselSetting}>
              {uiContent?.map((item) => {
                return (
                  <img
                    src={item.image}
                    key={item?.key}
                    alt="flybig banner"
                    height={"600"}
                  />
                );
              })}
            </Slider>
          </div>

          <Box className="tabs-box z-10">
            <Box sx={{ margin: 0 }}>
              <Tabs
                value={tabValue}
                indicatorColor="none"
                onChange={handleChangeTabs}
              >
                <Tab label="Flight Booking" {...a11yProps(0)} />
                <Tab label="Web Check-in" {...a11yProps(1)} />
                <Tab label="Manage Booking" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <DetailsForFlightSearch
              tabValue={tabValue}
              date={date}
              setDate={setDate}
              anchorElCalender={anchorElCalender}
              setAnchorElCalender={setAnchorElCalender}
              tripType={tripType}
              setTripType={setTripType}
              departureLocation={departureLocation}
              setDepartureLocation={setDepartureLocation}
              arrivalLocation={arrivalLocation}
              setArrivalLocation={setArrivalLocation}
              attendantType={attendantType}
              setAttendantType={setAttendantType}
              flightListState={flightListState}
              setFlightListState={setFlightListState}
              submitDataHandler={submitDataHandler}
              setPassengerNumbers={setPassengerNumbers}
              passengerNumbers={passengerNumbers}
            />
          </Box>
        </main>
      </>
    );
  }
);
