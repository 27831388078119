import React, { useEffect, useState } from 'react';
import MobileAncillary from './Mobile';
import './index.scss';
import { getAncillary, bookingDetailsApi } from '../../Api/Booking';
import { useWindowSize, useUrlExtract } from '../../utils/utils';
import { DesktopAncillary } from './Desktop/DesktopAncillary';
import { useDispatch, useSelector } from 'react-redux';
import { useScrollTop } from '../../customHooks/useScrollTop';
import { toastHandler } from '../../Redux/userReducer/user.action';
import Layout from '../passenger_info/Mobile/Layout';

const Ancillary = () => {
  useScrollTop();
  const [ancillaryData, setAncillaryData] = useState('');
  const [allAncillaries, setAllAncillaries] = useState([]);
  const [filteredAncillary, setFilteredAncillary] = useState('');
  const [newItmAnc, setNewItmAnc] = useState([]);
  const [allBookingData, setAllBookingData] = useState(null);
  const [webCheckInBody, setWebCheckInBody] = useState(null);

  useScrollTop();
  const dispatch = useDispatch();
  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;

  const {
    user: { openToast },
    cart: { ancillaryStep },
    checkIn: { checkInData, paxCheckIn, seatCheckIn, destinationDetail },
  } = useSelector((state) => state);

  const { bookingId } = useUrlExtract('bookingId');

  useEffect(() => {
    const body = {
      bookLegId: paxCheckIn?.[0]?.isReturn
        ? checkInData?.routes?.return?.bookLegId
        : checkInData?.routes?.departure?.bookLegId,
      passengers: [
        {
          passengerId: paxCheckIn?.[0]?.id,
          seatsAndAncillaries: {
            seat: seatCheckIn?.[0]?.name,
            ancillariesId: newItmAnc,
          },
          ...destinationDetail,
        },
      ],
    };
    setWebCheckInBody(body);
  }, [paxCheckIn, checkInData, seatCheckIn, newItmAnc]);

  useEffect(() => {
    bookingDetailsApi(bookingId)
      .then(({ data }) => setAllBookingData(data))
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: 'error',
          })
        );
      });
  }, []);

  useEffect(() => {
    getAncillary()
      .then(({ data }) => {
        setAncillaryData(data);
      })
      .catch((err) =>
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: 'error',
          })
        )
      );
  }, []);

  useEffect(() => {
    if (ancillaryData?.length) {
      setAllAncillaries(
        ancillaryData?.map((element) => {
          let newEl = { ...element, qty: 0 };
          return newEl;
        })
      );
    }
  }, [ancillaryData]);

  useEffect(() => {
    if (allAncillaries.length) {
      const filtered = allAncillaries.filter((item) => item?.qty > 0);

      if (filtered.length > 0) {
        setFilteredAncillary(filtered);
      } else {
        setNewItmAnc([]);
      }
    }
  }, [allAncillaries]);

  useEffect(() => {
    if (filteredAncillary?.length) {
      const addOn = filteredAncillary.flatMap((item) => {
        return Array(item?.qty).fill({
          code: item?.code,
          weight: item?.weight,
        });
      });
      setNewItmAnc(addOn);
    }
  }, [filteredAncillary, ancillaryStep]);

  const ancillaryHandler = (item, index, operation) => {
    let selectedAncillaryClone = JSON.parse(JSON.stringify(allAncillaries));

    if (operation === 'add') {
      if (item.qty < 2) {
        selectedAncillaryClone[index].qty += 1;
        setAllAncillaries(selectedAncillaryClone);
      }
    } else if (operation === 'subtract' && item?.qty > 0) {
      selectedAncillaryClone[index].qty -= 1;
      setAllAncillaries(selectedAncillaryClone);
    }
  };

  return (
    <div>
      {isMobile ? (
        <Layout
          reviewData={allBookingData}
          label='Select your ancillary'
          newItmAnc={newItmAnc}
          webCheckInBody={webCheckInBody}
          allAncillaries={allAncillaries}
        >
          <MobileAncillary
            allAncillaries={allAncillaries}
            ancillaryHandler={ancillaryHandler}
          />
        </Layout>
      ) : (
        <DesktopAncillary
          tripType={checkInData?.tripType}
          ancillaryHandler={ancillaryHandler}
          allAncillaries={allAncillaries}
          newItmAnc={newItmAnc}
          allBookingData={allBookingData}
          webCheckInBody={webCheckInBody}
        />
      )}
    </div>
  );
};

export default Ancillary;
