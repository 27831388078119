import React, { useEffect, useState } from 'react';
import { Navbar } from '../../Navbar/Desktop/Navbar';
import flybigLogoSearchNav from '../../../assets/svgs/flybigLogoSearchNav.svg';
import { Checkbox, Divider, Grid } from '@mui/material';
import lineArrowSvg from '../../../assets/svgs/LineArrowSvg.svg';
import { Button } from '../../common/Button';
import { colorPalette } from '../../../theme/color-palette';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkInStepHandler,
  paxCheckInHandler,
} from '../../../Redux/CheckInReducer/checkIn.action';
import { useScrollTop } from '../../../customHooks/useScrollTop';
import {
  getFormattedDate,
  useWindowSize,
  validateCheckinTime,
} from '../../../utils/utils';
import { MOBILE_CHECKIN_TAB } from '../../../utils/constant';
import styled from 'styled-components';
import { toastHandler } from '../../../Redux/userReducer/user.action';

const { black500, white500, primary500, gray600, black400 } = colorPalette;

export const CheckInStepTwo = () => {
  const {
    checkIn: { checkInData },
    user: { openToast },
  } = useSelector((state) => state);

  const [isChecked, setIsChecked] = useState(() => {
    return checkInData?.passengers?.reduce((acc, curr) => {
      acc = { ...acc, [curr.firstName]: false };
      return acc;
    }, {});
  });
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [selectedPax, setSelectedPax] = useState([]);
  const [selectionStep, setSelectionStep] = useState(1);

  useEffect(() => {
    let selection = checkInData?.passengers?.map((item) => {
      if (isChecked?.[item.firstName]) {
        return item;
      } else {
        return;
      }
    });

    setSelectedPax(() => selection?.filter((item) => item));
  }, [isChecked, checkInData?.passengers]);

  useEffect(() => {
    isAllChecked &&
      setIsChecked(() => {
        let obj = isChecked;
        for (let i in obj) {
          obj[i] = true;
        }
        return obj;
      });
  }, [isAllChecked, isChecked]);

  useScrollTop();
  const dispatch = useDispatch();
  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;

  const handleCheckIn = (item, departureDate, departureTime, isReturn) => {
    const allowCheckin = validateCheckinTime({ departureDate, departureTime });

    if (allowCheckin) {
      dispatch(checkInStepHandler(3));
      dispatch(paxCheckInHandler([{ ...item, isReturn }]));
    } else {
      dispatch(
        toastHandler({
          ...openToast,
          open: true,
          message:
            'Web Check-in opens from 72 hours until 1 hours before the departure time',
          severity: 'error',
        })
      );
    }
  };

  console.log(checkInData);

  return (
    <div className='w-full m-auto'>
      <main className='checkIn-container w-max-desk m-auto p-6'>
        <p className='font350 text-xl mb-4'>Check-In</p>
        <p className='font325 text-xs gotham-book mb-6'>
          Complete it for free 72 hr to 60 min before your flight.
        </p>

        {!isMobile ? (
          <>
            <div>
              <p className='flex items-center'>
                <span className='font350 text-lg text-primary mr-3'>
                  {checkInData?.origin}
                </span>
                <img src={lineArrowSvg} alt='line arrow' />
                <span className='font350 text-lg text-primary ml-3'>
                  {checkInData?.destination}
                </span>
                <span className='font350 text-xs text-gray600 ml-6'>
                  {getFormattedDate(
                    checkInData?.departureDate,
                    'ddd, DD MMM YYYY'
                  )}{' '}
                  {checkInData?.selectedFare?.schedule?.[0]?.departureTime} -{' '}
                  {checkInData?.selectedFare?.schedule?.[0]?.arrivalTime}
                </span>
              </p>

              <p
                className='text-right text-link500 font350 text-xs cursor-pointer'
                onClick={() => dispatch(checkInStepHandler(1))}
              >
                Check-in with another PNR
              </p>

              <table className='w-full mt-2'>
                <thead className='bg-primary text-white font350 text-xs'>
                  <tr>
                    <th>Sr. No.</th>
                    <th>GUEST NAME</th>
                    <th>SEAT NUMBER</th>
                    <th>ADD-ONS</th>
                    <th>BOARDING PASS</th>
                  </tr>
                </thead>
                <tbody>
                  {checkInData?.passengers?.map((item, index) => {
                    return (
                      <tr>
                        <td>
                          {/* <Checkbox
                        size='small'
                        checked={isChecked[item?.firstName]}
                        onChange={() => {
                          setIsChecked((prev) => {
                            return {
                              ...prev,
                              [item?.firstName]: !isChecked[item?.firstName],
                            };
                          });
                          setIsAllChecked(false);
                        }}
                        sx={{
                          "&.Mui-checked": {
                            color: primary500,
                          },
                        }}
                      /> */}
                          {index + 1}
                        </td>
                        <td>
                          {/* <p className='flex justify-between items-center'> */}
                          <span>
                            {item?.firstName} {item?.lastName}
                          </span>
                          {/* <span className='text-link500 text-xs cursor-pointer'>
                          Edit Seat
                        </span>
                      </p> */}
                        </td>
                        <td>{item?.webCheckin?.[0]?.seat?.number}</td>
                        <td>{item?.webCheckin?.[0]?.ancillaries?.[0]?.code}</td>
                        <td>
                          {item?.webCheckin?.[0]?.boardingPass ? (
                            <a
                              href={item?.webCheckin?.[0]?.boardingPass}
                              className='text-link500 cursor-pointer'
                            >
                              Download
                            </a>
                          ) : (
                            <Button
                              label={'Check In'}
                              bgColor={primary500}
                              color={white500}
                              width={'8rem'}
                              onClickHandler={() =>
                                handleCheckIn(
                                  item,
                                  checkInData.departureDate,
                                  checkInData?.selectedFare?.schedule?.[0]
                                    ?.departureTime
                                )
                              }
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {checkInData?.selectedFare?.returnSchedule && (
              <div className='mt-8'>
                <p className='flex items-center'>
                  <span className='font350 text-lg text-primary mr-3'>
                    {checkInData?.destination}
                  </span>
                  <img src={lineArrowSvg} alt='line arrow' />
                  <span className='font350 text-lg text-primary ml-3'>
                    {checkInData?.origin}
                  </span>
                  <span className='font350 text-xs text-gray600 ml-6'>
                    {getFormattedDate(
                      checkInData?.returnDate,
                      'ddd, DD MMM YYYY'
                    )}{' '}
                    {
                      checkInData?.selectedFare?.returnSchedule?.[0]
                        ?.departureTime
                    }{' '}
                    -{' '}
                    {
                      checkInData?.selectedFare?.returnSchedule?.[0]
                        ?.arrivalTime
                    }
                  </span>
                </p>

                <table className='w-full mt-2'>
                  <thead className='bg-primary text-white font350 text-xs'>
                    <tr>
                      <th>Sr. No.</th>
                      <th>GUEST NAME</th>
                      <th>SEAT NUMBER</th>
                      <th>ADD-ONS</th>
                      <th>BOARDING PASS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkInData?.returnPassengers?.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            <span>
                              {item?.firstName} {item?.lastName}
                            </span>
                          </td>
                          <td>{item?.webCheckin?.[0]?.seat?.number || '-'}</td>
                          <td>
                            {item?.webCheckin?.[0]?.ancillaries?.[0]?.code ||
                              '-'}
                          </td>
                          <td>
                            {item?.webCheckin?.[0]?.boardingPass ? (
                              <a
                                href={item?.webCheckin?.[0]?.boardingPass}
                                className='text-link500 cursor-pointer'
                              >
                                Download
                              </a>
                            ) : (
                              <Button
                                label={'Check In'}
                                bgColor={primary500}
                                color={white500}
                                width={'8rem'}
                                onClickHandler={() =>
                                  handleCheckIn(
                                    item,
                                    checkInData.returnDate,
                                    checkInData?.selectedFare
                                      ?.returnSchedule?.[0]?.departureTime,
                                    true // indicator for stating web checkin for return journey
                                  )
                                }
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </>
        ) : (
          <div>
            <Grid container className='border'>
              {true
                ? MOBILE_CHECKIN_TAB(
                    checkInData?.origin,
                    checkInData?.destination
                  )?.map((item, index) => (
                    <Tab
                      key={index}
                      onClick={() => {
                        // if (cartData?.departure?.id) {
                        if (true) {
                          setSelectionStep(index + 1);
                        }
                      }}
                      item
                      selected={index + 1 === selectionStep}
                      xs={6}
                    >
                      {item}
                    </Tab>
                  ))
                : ''}
              {selectionStep === 1 ? (
                <div>
                  <p className='font350 text-gray600 text-center my-4'>
                    {getFormattedDate(
                      checkInData?.departureDate,
                      'ddd, DD MMM YYYY'
                    )}{' '}
                    {checkInData?.selectedFare?.schedule?.[0]?.departureTime} -{' '}
                    {checkInData?.selectedFare?.schedule?.[0]?.arrivalTime}
                  </p>
                  <table className='w-full mt-2'>
                    <thead className='bg-primary text-white font350 text-xs'>
                      <tr>
                        <th>GUEST NAME</th>
                        <th>SEAT NUMBER</th>
                        <th>ADD-ONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkInData?.passengers?.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <div className='flex justify-between'>
                                <input
                                  className='price_radio_button'
                                  type='radio'
                                  name='pricing'
                                  onClick={() => {
                                    handleCheckIn(
                                      item,
                                      checkInData?.departureDate,
                                      checkInData?.selectedFare?.schedule?.[0]
                                        ?.departureTime
                                    );
                                  }}
                                />
                                <span>
                                  {item?.firstName} {item?.lastName}
                                </span>
                                {/* <p className='text-link500'>Check-In</p> */}
                                {/* <Button
                                  label={"Check-In"}
                                  bgColor={primary500}
                                  color={white500}
                                  onClickHandler={() => handleCheckIn(item)}
                                /> */}
                              </div>
                            </td>
                            <td>{item?.seat ? item?.seat : '-'}</td>
                            <td>{item?.meal ? item?.meal : '-'}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>
                  <p className='font350 text-gray600 text-center my-4'>
                    {getFormattedDate(
                      checkInData?.departureDate,
                      'ddd, DD MMM YYYY'
                    )}{' '}
                    {checkInData?.selectedFare?.schedule?.[0]?.departureTime} -{' '}
                    {checkInData?.selectedFare?.schedule?.[0]?.arrivalTime}
                  </p>
                  <table className='w-full mt-2'>
                    <thead className='bg-primary text-white font350 text-xs'>
                      <tr>
                        <th>GUEST NAME</th>
                        <th>SEAT NUMBER</th>
                        <th>ADD-ONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkInData?.passengers?.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <div className='flex justify-between'>
                                <input
                                  className='price_radio_button'
                                  type='radio'
                                  name='pricing'
                                  onClick={() => {
                                    handleCheckIn(
                                      item,
                                      checkInData?.departureDate,
                                      checkInData?.selectedFare?.schedule?.[0]
                                        ?.departureTime
                                    );
                                  }}
                                />
                                <span>
                                  {item?.firstName} {item?.lastName}
                                </span>
                              </div>
                            </td>
                            <td>{item?.seat ? item?.seat : '-'}</td>
                            <td>{item?.meal ? item?.meal : '-'}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </Grid>
          </div>
        )}

        <div className='my-12 flex justify-center'>
          {/* <div className='mr-4'>
            <Button
              label={"modify seat(s)"}
              bgColor={white500}
              color={gray700}
              width={"10rem"}
              variant={"outlined"}
            />
          </div>
          <div className='ml-4'> */}
          {/* <Button
            label={"Check In"}
            bgColor={primary500}
            color={white500}
            width={"8rem"}
            onClickHandler={handleCheckIn}
          /> */}
          {/* </div> */}
        </div>

        <Divider />

        <div>
          <p className='font350 text-xl my-6'>FAQs</p>

          <section>
            <p className='font350 text-sm mb-4'>Who all can check-in ?</p>

            <ul className='ml-8'>
              <li className='font325 text-xs mb-2 gotham-book'>
                Guests holding a valid and confirmed flybig booking Reference or
                PNR.
              </li>
              <li className='font325 text-xs mb-2 gotham-book'>
                A maximum of 9 guests per PNR with a maximum of 4 segments for
                customers fulfilling the check-in window criteria.
              </li>
            </ul>
          </section>

          <section>
            <p className='font350 text-sm mb-4 mt-6'>
              Guests excluded from mandatory web check-in:
            </p>

            <ul className='ml-8'>
              <li className='font325 text-xs mb-2 gotham-book'>
                Unaccompanied minors (UNMR).
              </li>
              <li className='font325 text-xs mb-2 gotham-book'>
                Guests with medical case (MEDA).
              </li>
              <li className='font325 text-xs mb-2 gotham-book'>
                Guests travelling on a stretcher (STCR).
              </li>
            </ul>
          </section>
        </div>
      </main>
    </div>
  );
};

const Tab = styled(Grid)`
  color: ${({ selected }) => (selected ? black500 : gray600)};
  text-align: center;
  font-size: 14px;
  border-bottom: ${({ selected }) =>
    selected ? '3px solid' + primary500 : '1px solid' + black400};
  padding: 14px 0px;
  &:nth-child(2) {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
`;
