import React, { useEffect, useState } from "react";
import "./PdfModal.scss";
import { colorPalette } from "../../../theme/color-palette";
import { Box, Modal, Checkbox } from "@mui/material";
import { Button } from "../../common/Button";
import { modalStyle } from "../../../utils/constant";
import { useWindowSize } from "../../../utils/utils";

export const PdfModal = ({ open, handleAgreeNContinue }) => {
  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;
  const [checkBox, setCheckBox] = useState(false);
  useEffect(() => {
    // setEnableResendotp(false);
  }, []);
  const { primary500, white500 } = colorPalette;
  return (
    <Modal
      open={open}
      // onClose={() => handleClose(setShowLoginOtp)}
      className="flex justify-center items-center"
    >
      <Box
        sx={modalStyle}
        className="step-three-container w-full m-auto flex justify-between items-center"
      >
        <section className="step-three-banner p-6 flex flex-col">
          {/* <img src="https://flybigassets.s3.ap-south-1.amazonaws.com/Webassets/loginCover.png" alt='' width="100%" height="auto" /> */}
          <img
            src="https://flybigassets.s3.ap-south-1.amazonaws.com/Webassets/Flybig-WebCheckin.png"
            alt=""
            width="100%"
            height="auto"
          />
          <div className="mt-4">
            <span className="mr-3">
              <Checkbox
                size="small"
                checked={checkBox}
                onChange={() => setCheckBox((checkBox) => !checkBox)}
                sx={{
                  "&.Mui-checked": {
                    color: primary500,
                  },
                }}
              />{" "}
            </span>
            <span className="gotham-book">
              I have read & Understood above restrictions and Information.
            </span>
            <div className="flex justify-end">
              <Button
                label={"agree & continue"}
                bgColor={primary500}
                color={white500}
                width={isMobile ? "100%" : "16rem"}
                onClickHandler={handleAgreeNContinue}
                disabled={!checkBox}
              />
            </div>
          </div>
        </section>
      </Box>
    </Modal>
  );
};
