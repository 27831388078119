import React from "react";
import "./headerNavSearch.scss";
import flybigLogoSearchNav from "../../../../assets/svgs/flybigLogoSearchNav.svg";
import { HEADER_SEARCH } from "../../../../utils/constant";
import { Navbar } from "../../../Navbar/Desktop/Navbar";

export default function HeaderNavSearch() {
  return (
    <header className='w-full m-auto'>
      <div className='nav-container-search-page bg-primary'>
        <Navbar logo={flybigLogoSearchNav} customClass='navbar-search' />
      </div>
      {/* <div className="sub-nav border w-full flex justify-center items-center">
        {HEADER_SEARCH.map((item, index) => {
          return (
            <figure key={item?.text} className="relative top-nav-step-fig">
              <img
                src={item?.arrowImg}
                alt={item?.text}
                className="arrow-svg-img"
              />
              <figcaption className="absolute flex justify-center items-center w-full">
                <img src={item?.icon} alt="" className="mr-4" />
                <span id={item?.id} className="text-sm font350">
                  {item?.text}
                </span>
              </figcaption>
            </figure>
          );
        })}
      </div> */}
    </header>
  );
}
