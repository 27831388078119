import React from "react";
import { Box } from "@mui/material";
import { FlightDetails } from "../FlightDetails";

export const TabPanelsComp = ({ tabValue, bookingDetailsState }) => {
  function TabPanel(props) {
    const { children, value, index } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <>{children}</>
          </Box>
        )}
      </div>
    );
  }

  const { schedule, returnSchedule } = bookingDetailsState?.selectedFare;

  return (
    <main className="flight-confirm-tabs">
      <TabPanel value={tabValue} index={0}>
        <FlightDetails type="Departure" bookingDetailsState={schedule} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <FlightDetails type="Return" bookingDetailsState={returnSchedule} />
      </TabPanel>
    </main>
  );
};
