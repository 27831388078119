import React, { useEffect, useState } from "react";
import "./LoginPage.scss";
import { Box, Modal } from "@mui/material";
import logoSvg from "../../../assets/svgs/flybase-logo.svg";
import {
  signInUser,
  successHandler,
  toastHandler,
} from "../../../Redux/userReducer/user.action";
import { useDispatch, useSelector } from "react-redux";
import Login from "./Common/Login";
import { SignUpModal } from "../SignUp/SignUpModal";
import { loginOtp, registerOtp, resendOtp, verifyOtp } from "../../../Api/Auth";
import { modalStyle } from "../../../utils/constant";

export const LoginPage = ({
  open,
  handleClose,
  signUpModal,
  setSignUpModal,
}) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    mobileNumber: "",
    password: "",
    confirmPassword: "",
    title: "Mr",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    email: "",
    countryCode: "91",
  });
  const [loginForm, setLoginForm] = useState({
    countryCode: "91",
    mobileNumber: "",
  });
  const [otp, setOtp] = useState("");
  const [showLoginOtp, setShowLoginOtp] = useState(false);
  const [showSignUpOtp, setShowSignUpOtp] = useState(false);
  const [sendOtpData, setOtpData] = useState();
  const [step, setStep] = useState(1);
  const [sendOtpTime, setSendOtpTime] = useState(Date.now());
  const [enableResendotp, setEnableResendotp] = useState(false);

  const {
    user: { openToast },
  } = useSelector((state) => state);

  const handleSignUp = () => {
    setSignUpModal(true);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(signInUser(userData));
    handleClose();
  };

  const loginHandler = () => {
    loginOtp({
      phone: {
        countryCode: loginForm?.countryCode,
        number: loginForm?.mobileNumber,
      },
    })
      .then(({ data }) => {
        setOtpData(data);
        setShowLoginOtp(true);
        setTimeout(() => {
          setEnableResendotp(true);
        }, 120000);
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message:
              err?.response?.data?.message ||
              "User does not exist. Kindly use a registered user, or sign up",
            severity: "error",
          })
        );
      });
  };

  const registerOtpHandler = () => {
    registerOtp({
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.email,
      phone: {
        countryCode: userData?.countryCode,
        number: userData?.mobileNumber,
      },
    })
      .then(({ data }) => {
        setOtpData(data);
        setShowSignUpOtp(true);
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
      });
  };

  const submitDataHandler = (e) => {
    e.preventDefault();
    if (step === 1) {
      loginHandler();
      setSendOtpTime(Date.now());
    } else {
      registerOtpHandler();
    }
  };

  const verifyOtpHandler = () => {
    verifyOtp({
      id: sendOtpData?.otp?.id,
      otp: otp.toString(),
      phone: {
        countryCode: userData?.countryCode || loginForm.countryCode,
        number: userData?.mobileNumber || loginForm?.mobileNumber,
      },
    })
      .then(({ data }) => {
        localStorage.setItem("user", JSON.stringify(data));
        dispatch(successHandler());
        setOtp("");
        handleClose(setShowLoginOtp);
      })
      .catch((err) => {
        setOtp("");
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
      });
  };

  const resendOtpHandler = (type) => {
    resendOtp({
      id: sendOtpData?.otp?.id,
      phone: {
        countryCode:
          type === "login" ? loginForm?.countryCode : userData?.countryCode,
        number:
          type === "login" ? loginForm?.mobileNumber : userData?.mobileNumber,
      },
    })
      .then(({ data }) => {
        setOtpData(data);
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
      });
  };

  useEffect(() => {
    setEnableResendotp(false);
  }, []);

  return (
    <Modal
      open={open}
      onClose={() => handleClose(setShowLoginOtp)}
      className="flex justify-center items-center"
    >
      <Box
        sx={modalStyle}
        className="login-container w-full m-auto flex justify-between items-center"
      >
        <section className="login-banner p-6 flex flex-col ">
          <img src={logoSvg} alt="" width={80} height={27} />
          <img
            // src={
            //   "https://flybigassets.s3.ap-south-1.amazonaws.com/Webassets/plane1_11_59_1_71_1_70.png"
            // }
            src={
              "https://flybigassets.s3.ap-south-1.amazonaws.com/Webassets/Aircraft_HDC-400.png"
            }
            alt="aeroplane"
            width={173}
            height={93}
            className="m-auto mt-16"
          />
        </section>
        {!signUpModal && (
          <div className="auth-aside">
            <p
              className="absolute text-lg right-4 top-4 cursor-pointer"
              onClick={() => handleClose(setShowLoginOtp)}
            >
              x
            </p>

            <Login
              submitDataHandler={submitDataHandler}
              signUpHandler={() => setStep(2)}
              verifyOtpHandler={verifyOtpHandler}
              otp={otp}
              setOtp={setOtp}
              loginForm={loginForm}
              setLoginForm={setLoginForm}
              showLoginOtp={showLoginOtp}
              setShowLoginOtp={setShowLoginOtp}
              handleClose={handleClose}
              handleSignUp={handleSignUp}
              type="desktop"
              sendOtpTime={sendOtpTime}
              resendOtpHandler={resendOtpHandler}
              enableResendotp={enableResendotp}
            />
          </div>
        )}
        {signUpModal && (
          <div className="signup-aside">
            <p
              className="absolute text-lg right-4 top-4 cursor-pointer"
              onClick={() => handleClose(setShowSignUpOtp)}
            >
              x
            </p>
            <SignUpModal
              handleClose={handleClose}
              userData={userData}
              setUserData={setUserData}
              sendOtp={registerOtpHandler}
              verifyOtpHandler={verifyOtpHandler}
              otp={otp}
              setOtp={setOtp}
              showSignUpOtp={showSignUpOtp}
              setShowSignUpOtp={setShowSignUpOtp}
              resendOtpHandler={resendOtpHandler}
            />
          </div>
        )}
      </Box>
    </Modal>
  );
};
