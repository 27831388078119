import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PositionedSnackbar from "../components/common/Toast";
import { Home } from "../components/homepage/Home";
import { useScrollTop } from "../customHooks/useScrollTop";
import { flightListHandler } from "../Redux/searchReducer/search.action";
import { sentryLoggerAdded } from "../utils/utils";

export const HomeView = () => {
  useScrollTop();
  const dispatch = useDispatch();

  useEffect(() => {
    sentryLoggerAdded();
  }, []);

  useEffect(() => {
    dispatch(flightListHandler());
  }, []);

  return (
    <div>
      <Home />
      <PositionedSnackbar />
    </div>
  );
};
