import React, { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import checkInSuccessSvg from '../../../assets/svgs/checkInSuccess.svg';
import { colorPalette } from '../../../theme/color-palette';
import { Button } from '../../common/Button';
import './CheckInSuccess.scss';
import { useUrlExtract } from '../../../utils/utils';
import { Grid } from '@mui/material';
import { downloadBoardingPass, sendPassToUser } from '../../../Api/Booking';
import DefaultToast from '../../common/Toast/DefaultToast';

export const CheckInSuccess = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { primary500, white500 } = colorPalette;
  const [open, setOpen] = React.useState(false);
  const [toastMsg, setToastMsg] = React.useState('Mail send successfully');
  const [severity, setSeverity] = React.useState('success');
  const navigateToHome = () => {
    navigate('/');
  };
  const bookingId = useRef();
  const seat = useRef();
  const segmentId = useRef();
  useEffect(() => {
    bookingId.current = searchParams.get('bookingId');
    seat.current = searchParams.get('seatNo');
    segmentId.current = searchParams.get('segment');
  }, []);
  const emailBoardingPass = () => {
    sendPassToUser({
      bookingId: bookingId.current,
      seat: seat.current,
      segmentId: segmentId.current,
    })
      .then((blob) => {
        console.log('done mailing......');
        setOpen(true);
        setSeverity('success');
        setTimeout(() => setOpen(false), 1000);
        setToastMsg('Boarding pass sent successfully!');
      })
      .catch((error) => {
        setOpen(true);
        setSeverity('error');
        setTimeout(() => {
          setOpen(false);
          setSeverity('error');
        }, 1000);
        setToastMsg('Failed to send. Please try after sometime');
      });
  };
  const downloadBoarding = () => {
    downloadBoardingPass({
      bookingId: bookingId.current,
      seat: seat.current,
      segmentId: segmentId.current,
    })
      .then((blob) => {
        var templink = document.createElement('a');
        templink.href = blob?.data;
        templink.setAttribute('download', 'boardingpass.pdf');
        templink.click();
        setOpen(true);
        setTimeout(() => setOpen(false), 1000);
        setToastMsg('Boarding pass downloaded successfully!');
      })
      .catch((error) => {
        setOpen(true);
        setSeverity('error');
        setTimeout(() => {
          setOpen(false);
          setSeverity('success');
        }, 1000);
        setToastMsg('Failed to download. Please try after sometime');
      });
  };

  const successStatus = useUrlExtract('payment');

  return (
    <div className='checkin-success-container'>
      <div className='checkin-success-box border m-auto p-6 flex flex-col justify-center items-center mt-20'>
        <p className='text-lg font-medium text-center mb-6 mt-4'>
          {successStatus === 'true'
            ? 'Web check-in successful'
            : 'Web check-in not successful'}
        </p>

        {successStatus === 'true' && (
          <p className='font-normal text-xs text-center mb-8'>
            Your boarding pass with the selected seat(s) will be sent via email,
            12-6 hours prior to the flight departure. Thank you for your
            patience.
          </p>
        )}

        {successStatus === 'true' ? (
          <img src={checkInSuccessSvg} alt='check in success' />
        ) : (
          <p className='text-red text-xxl'>x</p>
        )}
      </div>

      <div className='flex justify-center mt-10'>
        {/* {successStatus === "true" && (
          <div className='mr-6'>
            <Button
              label={"Download borading pass"}
              bgColor={primary500}
              color={white500}
            />
          </div>
        )}
        {successStatus === "true" && (
          <div>
            <Button
              label={"email borading pass"}
              bgColor={primary500}
              color={white500}
            />
          </div>
        )} */}
        <Grid container spacing={2} alignItems='center' justifyContent='center'>
          <Grid item>
            <Button
              label={'Download Boarding Pass'}
              bgColor={primary500}
              color={white500}
              onClickHandler={downloadBoarding}
            />
          </Grid>
          <Grid item>
            <Button
              label={'Mail Boarding Pass'}
              bgColor={primary500}
              color={white500}
              onClickHandler={emailBoardingPass}
            />
          </Grid>
          <Grid item>
            <Button
              label={'go to home page'}
              bgColor={primary500}
              color={white500}
              onClickHandler={navigateToHome}
            />
          </Grid>
        </Grid>
      </div>
      <DefaultToast open={open} severity={severity} message={toastMsg} />
    </div>
  );
};
