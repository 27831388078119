import React, { useState } from 'react';
import { InputLabel, TextField, Box, Autocomplete } from '@mui/material';

export const AutocompleteComp = React.memo(
  ({
    type,
    id,
    img,
    customClass,
    cityValue,
    changeCityValue,
    optionData,
    inputRef,
    openDropdownCities,
    onChangeHandler,
    setShouldUpdate,
  }) => {
    const [tempCity, setTempCity] = useState('');

    const getCitySearchString = (value) => {
      if (value || value === '') {
        value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        setTempCity(value);
      }
    };

    return (
      <div className={customClass}>
        <InputLabel htmlFor={id}>
          {/* <img src={img} alt={id} /> */}
          <span>{type}</span>
        </InputLabel>
        {cityValue ? (
          <div
            onClick={() => openDropdownCities()}
            className='cursor-pointer mt-1'
          >
            <div className='selected-city-styled flex'>
              <span className='city-name pb-1 pt-1'>{cityValue?.city}</span>
              <span className='city-code '>{cityValue?.code}</span>
            </div>
            <div className='airport-name text-xxs font-light gotham-book'>
              {cityValue?.airport}
            </div>
          </div>
        ) : (
          <p className='text-lg pb-1 pt-1 ml-2 mt-2'>
            {tempCity !== '' ? tempCity : 'Select City'}
          </p>
        )}

        <Autocomplete
          options={optionData || []}
          getOptionLabel={(option) => {
            return `${option.code} ${option.city}` || '';
          }}
          ref={inputRef}
          className='auto-input-class-ref'
          id={id}
          autoComplete
          openOnFocus
          value={cityValue}
          onChange={(event, newValue) => {
            changeCityValue(event, newValue);
            type === 'FROM' && setShouldUpdate(true);
            type === 'TO' && setShouldUpdate(false);
          }}
          includeInputInList
          renderOption={(props, option) => (
            <Box
              component='li'
              {...props}
              className='city-selection cursor-pointer'
            >
              <p>
                <span className='city-name'>{option?.city}</span>
                <span className='city-code'>{option?.code}</span>
              </p>
              <p className='airport-name'>{option?.airport}</p>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              variant='standard'
              {...params}
              onChange={(e) => {
                getCitySearchString(e.target.value);
              }}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
            />
          )}
        />
      </div>
    );
  }
);
