import { Container } from "@mui/material";
import React from "react";
import "./index.scss";

const NoResults = ({ type }) => {
  return (
    <Container>
      <div className='flex flex-col justify-center items-center'>
        <img
          className='no-flight-image'
          src={
            "https://flybigassets.s3.ap-south-1.amazonaws.com/Webassets/noflight.png"
          }
          alt='no results'
        />
        {type !== "desktop" && <h4 className='mt-4'>No flights found</h4>}
        <p className='mt-4'>
          No flights found on this route for the requested date, please change
          the date or journey.{" "}
        </p>
      </div>
    </Container>
  );
};

export default NoResults;
