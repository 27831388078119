import React from "react";
import { Container } from "@mui/material";
import Service from "../Common/Services";

const MobileAncillary = ({ allAncillaries, ancillaryHandler }) => {
  return (
    <>
      <Container>
        {allAncillaries?.map((item, index) => (
          <Service
            item={item}
            index={index}
            ancillaryHandler={ancillaryHandler}
          />
        ))}
      </Container>
    </>
  );
};

export default MobileAncillary;
