import { store } from "../store";
import { toastHandler } from "../userReducer/user.action";
import {
  CHECK_IN,
  CHECK_IN_BY_PNR,
  DESTINATION_DETAILS,
  PAX_CHECKIN,
  SEAT_CHECKIN,
} from "./checkIn.types";

export const checkInStepHandler = (step) => {
  const {
    user: { openToast },
  } = store.getState();

  return async (dispatch) => {
    try {
      dispatch(checkInFunc(step));
    } catch (err) {
      dispatch(
        toastHandler({
          ...openToast,
          open: true,
          message: err?.response?.data?.message,
          severity: "error",
        })
      );
    }
  };
};

export const checkInDataHandler = (data) => {
  const {
    user: { openToast },
  } = store.getState();

  return async (dispatch) => {
    try {
      dispatch(checkInDataFunc(data));
    } catch (err) {
      dispatch(
        toastHandler({
          ...openToast,
          open: true,
          message: err?.response?.data?.message,
          severity: "error",
        })
      );
    }
  };
};

export const paxCheckInHandler = (data) => {
  const {
    user: { openToast },
  } = store.getState();

  return async (dispatch) => {
    try {
      dispatch(paxCheckInFunc(data));
    } catch (err) {
      dispatch(
        toastHandler({
          ...openToast,
          open: true,
          message: err?.response?.data?.message,
          severity: "error",
        })
      );
    }
  };
};

export const seatCheckInHandler = (data) => {
  const {
    user: { openToast },
  } = store.getState();

  return async (dispatch) => {
    try {
      dispatch(seatCheckInFunc(data));
    } catch (err) {
      dispatch(
        toastHandler({
          ...openToast,
          open: true,
          message: err?.response?.data?.message,
          severity: "error",
        })
      );
    }
  };
};

export const destinationDetailsHandler = (data) => {
  const {
    user: { openToast },
  } = store.getState();

  return async (dispatch) => {
    try {
      dispatch(destinationDetailsFunc(data));
    } catch (err) {
      dispatch(
        toastHandler({
          ...openToast,
          open: true,
          message: err?.response?.data?.message,
          severity: "error",
        })
      );
    }
  };
};

const checkInFunc = (data) => {
  return {
    type: CHECK_IN,
    payload: data,
  };
};

const checkInDataFunc = (data) => {
  return {
    type: CHECK_IN_BY_PNR,
    payload: data,
  };
};

const paxCheckInFunc = (data) => {
  return {
    type: PAX_CHECKIN,
    payload: data,
  };
};

const seatCheckInFunc = (data) => {
  return {
    type: SEAT_CHECKIN,
    payload: data,
  };
};

const destinationDetailsFunc = (data) => {
  return {
    type: DESTINATION_DETAILS,
    payload: data,
  };
};
