import React from "react";
import { Grid, TextField } from "@mui/material";
import "./contactDeclarationForm.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const ContactDeclarationForm = ({ detailsForm, setDetailsForm }) => {
  return (
    <div>
      <Grid container spacing={2} className='p-4 mb-4'>
        <Grid item lg={6} md={6} xs={12} className='w-full'>
          <p className='text-xxs font350 text-gray700 mb-2'>Mobile Number</p>
          <PhoneInput
            country={"in"}
            value={`${detailsForm?.phone?.countryCode}${detailsForm?.phone?.number}`}
            inputProps={{
              required: true,
              autoFocus: true,
            }}
            countryCodeEditable={false}
            onChange={(value, data, event, formattedValue) => {
              setDetailsForm(() => ({
                ...detailsForm,
                phone: {
                  countryCode: data?.dialCode,
                  number: value?.slice(data?.dialCode?.length),
                },
              }));
            }}
          />
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <p className='text-xxs font350 text-gray700 mb-2'>Email</p>
          <TextField
            type='email'
            required
            size='small'
            variant='outlined'
            className='w-full'
            placeholder='Enter your email...'
            value={detailsForm?.email}
            onChange={(e) => {
              setDetailsForm((prev) => ({ ...prev, email: e.target.value }));
            }}
          />
        </Grid>
        <Grid item lg={8} md={8} xs={12}>
          <p className='text-xxs font350 text-gray700 mb-2'>
            Destination Address
          </p>
          <TextField
            type='text'
            size='small'
            variant='outlined'
            className='w-full'
            placeholder='Enter your address...'
            value={detailsForm?.destination?.address}
            onChange={(e) => {
              setDetailsForm((prev) => ({
                ...prev,
                destination: { ...prev.destination, address: e.target.value },
              }));
            }}
          />
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <p className='text-xxs font350 text-gray700 mb-2'>Destination pin</p>
          <TextField
            type='text'
            size='small'
            variant='outlined'
            className='w-full'
            placeholder='Enter your pincode...'
            value={detailsForm?.destination?.pin}
            onChange={(e) => {
              setDetailsForm((prev) => ({
                ...prev,
                destination: { ...prev.destination, pin: e.target.value },
              }));
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
