import React, { useState, useEffect } from "react";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { reviewBookingData } from "../../../Api/Booking";
import { toastHandler } from "../../../Redux/userReducer/user.action";
import { useUrlExtract } from "../../../utils/utils";
import { SkeletonComp } from "../../common/Skeleton/Skeleton";
import { BsInfoCircle } from "react-icons/bs";
import { Box, Divider, Tooltip } from "@mui/material";

export default function FareSummary() {
  const [totalFares, setTotalFares] = useState("");
  const [totalPassengers, setTotalPassengers] = useState(0);
  const [reviewData, setReviewData] = useState("");
  const [isCollapsed, setIsCollapsed] = useState({
    baseFare: false,
    feeSurcharge: false,
    otherServices: false,
  });
  const taxTerminology = {
    assurance: "Insurance",
    airportTax: "Airport Tax",
    surcharge: "Surcharge",
    terminalFee: "ASF",
    vat: "GST",
    cuteFee: "Cute Fee",
    userDevelopmentFee: "User Development Fee",
    total: "Total",
  };
  useEffect(() => {
    if (reviewData) {
      setTotalFares(reviewData?.total);
      setTotalPassengers(reviewData?.passengers);
    }
  }, [reviewData]);

  const { bookingType, sessionId } = useUrlExtract("all");

  const dispatch = useDispatch();
  const {
    user: { openToast },
  } = useSelector((state) => state);

  useEffect(() => {
    reviewBookingData(sessionId)
      .then(({ data }) => {
        setReviewData(data);
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message || "Something went wrong ...",
            severity: "error",
          })
        );
      });
  }, []);

  return (
    <>
      <section className="pl-6 pt-4 mb-4">
        <p className="font350 text-base mb-2">Fare Summary</p>
        <p className="font-normal text-xxs">Review your Fare Details</p>
      </section>

      {reviewData ? (
        <>
          <div className="">
            <section className="base-fare-div flex items-center pl-2">
              {!isCollapsed?.baseFare ? (
                <AiOutlineMinusCircle
                  onClick={() =>
                    setIsCollapsed({
                      ...isCollapsed,
                      baseFare: !isCollapsed?.baseFare,
                    })
                  }
                  className="cursor-pointer"
                />
              ) : (
                <AiOutlinePlusCircle
                  onClick={() =>
                    setIsCollapsed({
                      ...isCollapsed,
                      baseFare: !isCollapsed?.baseFare,
                    })
                  }
                  className="cursor-pointer"
                />
              )}
              <span className="font350 text-sm ml-2">Base Fare</span>
            </section>

            {!isCollapsed?.baseFare && (
              <section>
                <p className="font350 text-xs flex justify-between line-height17 m-2">
                  <span className="pl-6">
                    Adult(s) ({totalPassengers?.adult} X ₹{" "}
                    {totalFares?.basicAdult / totalPassengers?.adult})
                  </span>
                  <span className="pr-6">₹ {totalFares?.basicAdult}</span>
                </p>

                {totalPassengers?.child > 0 && (
                  <p className="font350 text-xs flex justify-between line-height17 m-2">
                    <span className="pl-6">
                      Children ({totalPassengers?.child} X ₹{" "}
                      {totalFares?.basicChild / totalPassengers?.child})
                    </span>
                    <span className="pr-6">₹ {totalFares?.basicChild}</span>
                  </p>
                )}

                {totalPassengers?.infant > 0 && (
                  <p className="font350 text-xs flex justify-between line-height17 m-2">
                    <span className="pl-6">
                      Infant(s) ({totalPassengers?.infant} X ₹{" "}
                      {totalFares?.basicInfant / totalPassengers?.infant})
                    </span>
                    <span className="pr-6">₹ {totalFares?.basicInfant}</span>
                  </p>
                )}
              </section>
            )}
          </div>
          <div className="">
            <section className="fee-surcharge-div flex items-center pl-2">
              {!isCollapsed?.feeSurcharge ? (
                <AiOutlineMinusCircle
                  onClick={() =>
                    setIsCollapsed({
                      ...isCollapsed,
                      feeSurcharge: !isCollapsed?.feeSurcharge,
                    })
                  }
                  className="cursor-pointer"
                />
              ) : (
                <AiOutlinePlusCircle
                  onClick={() =>
                    setIsCollapsed({
                      ...isCollapsed,
                      feeSurcharge: !isCollapsed?.feeSurcharge,
                    })
                  }
                  className="cursor-pointer"
                />
              )}{" "}
              <span className="font350 text-sm ml-2">Fee & Surcharge</span>
            </section>
            {!isCollapsed?.feeSurcharge && (
              <section>
                <p className="font350 text-xs flex justify-between line-height17 m-2">
                  <span className="pl-6 flex justify-center items-center">
                    Total Taxes & Fees{" "}
                    <Tooltip
                      title={
                        <Box>
                          {totalFares?.totalTaxAndFees &&
                            Object?.entries(totalFares?.totalTaxAndFees)?.map(
                              (item, index) => {
                                return (
                                  <p key={index}>{`${
                                    taxTerminology[item?.[0]]
                                  } : ₹ ${item?.[1]}`}</p>
                                );
                              }
                            )}
                        </Box>
                      }
                      arrow
                    >
                      <span className="ml-2 mt-1 cursor-pointer">
                        <BsInfoCircle />
                      </span>
                    </Tooltip>
                  </span>{" "}
                  <span className="pr-6">
                    ₹ {totalFares?.totalTaxAndFees?.total}
                  </span>
                </p>
                {bookingType !== "modify" && (
                  <p className="font350 text-xxs flex justify-between line-height17 m-2">
                    <span className="pl-6">
                      Convenience Fee : *(Non refundable)
                    </span>
                    <span className="pr-6">₹ {totalFares?.convenienceFee}</span>
                  </p>
                )}
              </section>
            )}
          </div>
          {bookingType === "modify" && (
            <>
              <div className="pl-3 bg-gray100">
                <p className="flex justify-between my-2 text-xs">
                  <span>Current selling fare :</span>
                  <span className="pr-6">
                    ₹ {totalFares?.totalWithoutModification}
                  </span>
                </p>
                <p className="flex justify-between my-2 text-xs">
                  <span>Previous sold fare:</span>
                  <span className="pr-6">
                    - ₹ {totalFares?.previousFareTotal}
                  </span>
                </p>
                <p className="flex justify-between my-2 text-xs">
                  <span>Modification Fee :</span>
                  <span className="pr-6">
                    {" "}
                    + ₹ {totalFares?.modificationFee}
                  </span>
                </p>
              </div>
              <Divider />
            </>
          )}
          <div className="total-amount-div flex justify-between pl-2">
            <span>Total Amount :</span>
            <span className="pr-6">₹ {totalFares?.total}</span>
          </div>
        </>
      ) : (
        <SkeletonComp width="100%" height="100%" varient="rectangular" />
      )}
    </>
  );
}
