import { searchFare } from "../../Api/search";
import { cartActionTypes } from "./cart.actionType";
import { store } from "../store";
import { toastHandler } from "../userReducer/user.action";

const {
  SAVE_CART,
  SEARCH_FARE,
  LOADER,
  ANCILLARY_STEP,
  SEAT_DATA,
  ANCILLARY_DATA,
} = cartActionTypes;

export const flightFareHandler = (data) => {
  const {
    user: { openToast },
  } = store.getState();

  return async (dispatch) => {
    try {
      // let { data } = await searchFare(sendData);
      data && dispatch(saveFlightFare(data));
      data && dispatch(loaderHandler(false));
    } catch (err) {
      dispatch(
        toastHandler({
          ...openToast,
          open: true,
          message: err?.response?.data?.message || "Something went wrong",
          severity: "error",
        })
      );
      dispatch(saveFlightFare([]));
      dispatch(loaderHandler(false));
    }
  };
};

export const saveFlightFare = (data) => {
  return {
    type: SEARCH_FARE,
    payload: data,
  };
};

export const selectedFlightHandler = (data) => {
  return {
    type: SAVE_CART,
    payload: data,
  };
};

export const loaderHandler = (data) => {
  return {
    type: LOADER,
    payload: data,
  };
};

export const ancillaryStepHandler = (data) => {
  return {
    type: ANCILLARY_STEP,
    payload: data,
  };
};

export const ancillaryDataHandler = (data) => {
  return {
    type: ANCILLARY_DATA,
    payload: data,
  };
};

export const seatDataHandler = (data) => {
  return {
    type: SEAT_DATA,
    payload: data,
  };
};
