import { userSignUp } from "../../Api/login";
import { userActionType } from "./user.actionType";
import { store } from "../store";
import { verifyOtp } from "../../Api/Auth";

const { LOGIN, LOGOUT, TOAST, BOOKING_ID, CANCEL_BOOKING } = userActionType;

export const signInUser = (body, setLoginDrawer) => {
  const {
    user: { openToast },
  } = store.getState();

  return async (dispatch) => {
    try {
      let { data } = await verifyOtp(body);
      localStorage.setItem("user", JSON.stringify(data));
      dispatch(successHandler());
      setLoginDrawer && setLoginDrawer({ bottom: false });
    } catch (err) {
      dispatch(
        toastHandler({
          ...openToast,
          open: true,
          message: err?.response?.data?.message,
          severity: "error",
        })
      );
    }
  };
};

export const signUpUser = (body, setLoginDrawer) => {
  const {
    user: { openToast },
  } = store.getState();

  return async (dispatch) => {
    try {
      let { data } = await userSignUp(body);
      localStorage.setItem("user", JSON.stringify(data));
      dispatch(successHandler());
      setLoginDrawer && setLoginDrawer({ bottom: false });
    } catch (err) {
      dispatch(
        toastHandler({
          ...openToast,
          open: true,
          message: err?.response?.data?.message,
          severity: "error",
        })
      );
    }
  };
};

export const cancelBookingData = (data) => {
  const {
    user: { openToast },
  } = store.getState();

  return async (dispatch) => {
    try {
      dispatch(cancelBookingHandler(data));
    } catch (err) {
      dispatch(
        toastHandler({
          ...openToast,
          open: true,
          message: err?.response?.data?.message,
          severity: "error",
        })
      );
    }
  };
};

export const successHandler = () => {
  return {
    type: LOGIN,
  };
};

export const logOutHandler = () => {
  return {
    type: LOGOUT,
  };
};

export const toastHandler = (data) => {
  return {
    type: TOAST,
    payload: data,
  };
};

export const bookingIdHandler = (data) => {
  return {
    type: BOOKING_ID,
    payload: data,
  };
};

export const cancelBookingHandler = (data) => {
  return {
    type: CANCEL_BOOKING,
    payload: data,
  };
};
