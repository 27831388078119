import { useState, useLayoutEffect, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import { DATE_FORMAT, ROUNDTRIP, tripTypes } from './constant';
import { useSearchParams } from 'react-router-dom';

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export const sentryLoggerAdded = (error) => {
  Sentry.captureException(error);
  Sentry.captureMessage('Something went wrong');
};

export const getFormattedDate = (date, format = 'DATE1') =>
  moment(date)?.format(format || DATE_FORMAT['DATE1']);

export const pluralCheck = (value, string) =>
  value > 1 ? string + 's' : string;

export const itineraryUrl = (tripType, data) => {
  const { DATE4 } = DATE_FORMAT;
  const { departureLocation, arrivalLocation, departureDate, arrivalDate } =
    data;

  if (tripType === 'Round Trip') {
    return `${departureLocation}-${arrivalLocation}-${getFormattedDate(
      departureDate,
      DATE4
    )}_${arrivalLocation}-${departureLocation}-${getFormattedDate(
      arrivalDate,
      DATE4
    )}`;
  }
  return `${departureLocation}-${arrivalLocation}-${getFormattedDate(
    departureDate,
    DATE4
  )}`;
};

export const useUrlExtract = (type) => {
  const [searchParams] = useSearchParams();

  switch (type) {
    case 'search':
      return searchFareHandler(searchParams);
    case 'sessionId':
      return reviewFlightHandler(searchParams);
    case 'reviewPricingBody':
      return reviewPricingBody(searchParams);
    case 'bookingId':
      return reviewBookingIdHandler(searchParams);
    case 'payment':
      return reviewPaymentHandler(searchParams);
    case 'pnr':
      return reviewPnrHandler(searchParams);
    case 'all':
      return reviewAllDataHandler(searchParams);
    default:
      return reviewPricingBody(searchParams);
  }
};

const reviewPricingBody = (searchParams) => {
  let allParams = Object.fromEntries([...searchParams]);
  return allParams;
};

const reviewFlightHandler = (searchParams) => {
  let { sessionId } = Object.fromEntries([...searchParams]);

  return sessionId;
};

const reviewBookingIdHandler = (searchParams) => {
  let { bookingId, success } = Object.fromEntries([...searchParams]);

  return { bookingId, success };
};

const reviewPnrHandler = (searchParams) => {
  let { pnr } = Object.fromEntries([...searchParams]);

  return pnr;
};

const reviewAllDataHandler = (searchParams) => {
  return Object.fromEntries([...searchParams]);
};

const reviewPaymentHandler = (searchParams) => {
  let { success } = Object.fromEntries([...searchParams]);

  return success;
};

const searchFareHandler = (searchParams) => {
  let { atdType, itinerary, paxType, tripType } = Object?.fromEntries([
    ...searchParams,
  ]);
  let itineraryData = itinerarySplit(itinerary, tripType);
  let paxData = paxDetails(paxType);

  if (attendantType(atdType?.toLocaleLowerCase())) {
    return {
      ...itineraryData,
      concessionType: attendantType(atdType?.toLocaleLowerCase()),
      tripType: tripTypes?.[tripType],
      passengers: paxData,
    };
  } else {
    return {
      ...itineraryData,
      tripType: tripTypes?.[tripType],
      passengers: paxData,
    };
  }
};

const itinerarySplit = (itinerary, tripType) => {
  const { DATE2 } = DATE_FORMAT;

  if (tripType === 'R') {
    let [departure, arrival] = itinerary?.split('_');
    let departureData = dateLocationSplitter(departure);
    let arrivalData = dateLocationSplitter(arrival);
    return {
      origin: departureData?.from,
      destination: departureData?.to,
      departureDate: getFormattedDate(departureData.date, DATE2),
      returnDate: getFormattedDate(arrivalData.date, DATE2),
    };
  } else if (tripType === 'O') {
    let departureData = dateLocationSplitter(itinerary);
    return {
      origin: departureData.from,
      destination: departureData.to,
      departureDate: getFormattedDate(departureData.date, DATE2),
    };
  }
};

const dateLocationSplitter = (data) => {
  const [from, to, date] = data.split('-');
  return { from, to, date };
};

const paxDetails = (paxType) => {
  let [Adult, Children, Infant] = paxType.split('_');
  return {
    noOfPassengers: Number(Adult[2]) + Number(Children[2]) + Number(Infant[2]),
    adult: Number(Adult[2]),
    child: Number(Children[2]),
    infant: Number(Infant[2]),
  };
};

export const attendantType = (type) => {
  switch (type) {
    case 'armed forces':
      return 'armedForces';
    case 'senior citizen':
      return 'seniorCitizen';
    case 'students':
      return 'students';
    case 'Covid Warriors':
      return 'covidWarriors';
    default:
      return '';
  }
};

export const sessionDataFormatter = (cartData, tripType) => {
  let sessionData = {};
  sessionData.schedule = {
    flightType: cartData.departure.flightType,
    fares: [
      {
        fareBasisCode: cartData.departure.fareBasicCode,
        flightId: cartData.departure.id,
      },
    ],
  };
  if (tripType === ROUNDTRIP) {
    sessionData.returnSchedule = {
      flightType: cartData.arrival.flightType,
      fares: [
        {
          fareBasisCode: cartData.arrival.fareBasicCode,
          flightId: cartData.arrival.id,
        },
      ],
    };
  }
  return sessionData;
};

export const dateScrollHandler = () => {
  const { DATE3, DATE4 } = DATE_FORMAT;
  let initialDate = new Date();
  let dateArray = [
    {
      date: initialDate,
      value: getFormattedDate(initialDate, DATE3),
      apiDate: getFormattedDate(initialDate, DATE4),
    },
  ];

  for (let i = 1; i < 7; i++) {
    initialDate = moment(dateArray[dateArray.length - 1]?.date)
      .add(1, 'days')
      .toDate();
    dateArray.push({
      date: initialDate,
      value: getFormattedDate(initialDate, DATE3),
      apiDate: getFormattedDate(initialDate, DATE4),
    });
  }
  return dateArray;
};

export const dateCompareHandler = (fDate, sDate) => {
  if (
    new Date(fDate).setHours(0, 0, 0, 0) > new Date(sDate).setHours(0, 0, 0, 0)
  ) {
    return true;
  } else {
    return false;
  }
};

export const nameHandler = (value) => {
  if (value) {
    let validationCondition = /^[A-Za-z ]+$/;
    let isValid = validationCondition.test(value);
    return isValid;
  }
  return false;
};

export const emailHandler = (value) => {
  if (value) {
    let validationCondition =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = validationCondition.test(String(value).toLowerCase());
    return isValid;
  }
  return false;
};
export function setGender(salutation) {
  if (salutation === 'MR' || salutation === 'MSTR') {
    return 'Male';
  } else if (
    salutation === 'MRS' ||
    salutation === 'MS' ||
    salutation === 'MISS'
  ) {
    return 'Female';
  }
}

export const handleSwitchCities = (
  flightList,
  arrivalCityValue,
  departureCityValue,
  setDepartureCityValue,
  setArrivalCityValue
) => {
  let switchedDepartureCity = flightList.filter(
    (item) => item?.code === arrivalCityValue?.code
  )?.[0];
  let switchedArrivalCity = flightList.filter(
    (item) => item?.code === departureCityValue?.code
  )?.[0];

  setDepartureCityValue(switchedDepartureCity);
  setArrivalCityValue(switchedArrivalCity);
};

export const modalStyle = (width, padding) => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width,
    bgcolor: 'white',
    boxShadow: 24,
    p: padding || 4,
  };
};

export const handlePassengerValue = (
  passengerNumbers,
  ageCategory,
  isAdded,
  setPassengerNumbers,
  setModifyBtnState,
  usedIn
) => {
  let paxNums = JSON.parse(JSON.stringify(passengerNumbers));

  if (isAdded) {
    if (
      ageCategory === 'infant' &&
      passengerNumbers?.adult > passengerNumbers?.infant
    ) {
      paxNums[ageCategory] = paxNums?.[ageCategory] + 1;
      usedIn === 'modifyDesk' && setModifyBtnState(false);
    } else if (ageCategory === 'adult' && passengerNumbers?.adult < 9) {
      paxNums[ageCategory] = paxNums?.[ageCategory] + 1;
      usedIn === 'modifyDesk' && setModifyBtnState(false);
    } else if (ageCategory === 'children' && passengerNumbers?.children < 9) {
      paxNums[ageCategory] = paxNums?.[ageCategory] + 1;
      usedIn === 'modifyDesk' && setModifyBtnState(false);
    }
  } else {
    if (ageCategory === 'adult' && passengerNumbers?.adult >= 1) {
      paxNums.adult = paxNums?.adult - 1;
      usedIn === 'modifyDesk' && setModifyBtnState(false);
    } else if (ageCategory !== 'adult' && passengerNumbers[ageCategory] > 0) {
      paxNums[ageCategory] = paxNums[ageCategory] - 1;
      usedIn === 'modifyDesk' && setModifyBtnState(false);
    }
  }

  setPassengerNumbers(paxNums);
};

export const validateCheckinTime = ({ departureDate, departureTime }) => {
  const date = parseInt(departureDate?.split('T')?.[0]?.split('-')?.[2]);
  const month = parseInt(departureDate?.split('T')?.[0]?.split('-')?.[1]);
  const year = parseInt(departureDate?.split('T')?.[0]?.split('-')?.[0]);
  const hours = parseInt(departureTime?.split(':')?.[0]);
  const minutes = parseInt(departureTime?.split(':')?.[1]);

  const departureTimestamp = new Date();

  departureTimestamp.setDate(date);
  departureTimestamp.setMonth(month - 1);
  departureTimestamp.setFullYear(year);
  departureTimestamp.setHours(hours);
  departureTimestamp.setMinutes(minutes);

  const currentTime = new Date().getTime();

  const minimum = 1 * 60 * 60 * 1000;
  const maximum = 72 * 60 * 60 * 1000;

  return (
    departureTimestamp.getTime() - currentTime <= maximum &&
    departureTimestamp.getTime() - currentTime >= minimum
  );
};
