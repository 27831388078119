import { LOGIN_ENDPOINTS } from "../endpoints";
import axiosInstance from "../axiosInstance";

const { SIGN_IN, SIGN_UP, LOG_OUT } = LOGIN_ENDPOINTS;

export const userSignIn = (body) => axiosInstance.post(SIGN_IN, body);

export const userSignUp = (body) => axiosInstance.post(SIGN_UP, body);

export const userLogOut = (body) => axiosInstance.post(LOG_OUT, body);
