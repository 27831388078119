import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { colorPalette } from "../../../../theme/color-palette";
import { FlightDetailsComp } from "../../../common/FlightDetails";
import { Navbar } from "../../../Navbar/Mobile";
import logo from "../../../../assets/svgs/logo.svg";
import { Divider, Grid } from "@mui/material";
import { getFormattedDate } from "../../../../utils/utils";
import { Button } from "../../../common/Button";
import { DATE_FORMAT } from "../../../../utils/constant";
import Calender from "../../../homepage/Mobile/Search/Drawer/Calender";
import calenderSvg from "../../../../assets/svgs/primaryCalenderSvg.svg";

const ModifyBooking = ({ bookingData }) => {
  const [calenderDrawer, setCalenderDrawer] = useState({
    bottom: false,
  });
  const [dateClone, setDateClone] = useState({});
  const [activeDate, setActiveDate] = useState(0);

  const navigate = useNavigate();

  const { primary500, white500 } = colorPalette;
  const { from, to } = dateClone;
  const { DATE3, DATE4 } = DATE_FORMAT;
  const { tripType, origin, destination, id, bookingDate, schedule } =
    bookingData;

  const handleModifyBookingDate = () => {
    const itineraryUrl =
      tripType === "OneWay"
        ? `${origin}-${destination}-${getFormattedDate(dateClone?.from, DATE4)}`
        : `${origin}-${destination}-${getFormattedDate(
            dateClone?.from,
            DATE_FORMAT?.DATE4
          )}_${destination}-${origin}-${getFormattedDate(
            dateClone?.to,
            DATE4
          )}`;

    navigate(
      `/search-result?tripType=${tripType[0]}&itinerary=${itineraryUrl}&paxType=A-1_C-0_I-0&bookingType=modify&bookingId=${id}`
    );
  };

  const dateOnSubmitHandler = () => {
    setCalenderDrawer({
      bottom: false,
    });
  };

  return (
    <>
      <Navbar
        color={white500}
        bgColor={primary500}
        showMenu={true}
        icon={logo}
        onClose={() => navigate(-1)}
      />

      <main className='w-full p-4'>
        <p className='font350 text-xl mt-2'>Itinerary</p>
        <p className='font325 gotham-book mt-2 text-xs'>
          {" "}
          Booked On {getFormattedDate(bookingDate, "DD MMM YYYY, HH:MM")}
        </p>

        <div>
          {schedule?.[0] && (
            <FlightDetailsComp
              departureTime={schedule?.[0]?.departureTime}
              arrivalTime={schedule?.[0]?.arrivalTime}
              departureDate={schedule?.[0]?.departureDate}
              arrivalDate={schedule?.[0]?.arrivalDate}
              origin={schedule?.[0]?.origin}
              destination={schedule?.[0]?.destination}
            />
          )}
        </div>
        <Divider className='my-4' />

        <div className='mt-6 mb-12'>
          <Grid
            container
            onClick={() => {
              setCalenderDrawer({ bottom: true });
            }}
          >
            <Grid item xs={12}>
              <div
                onClick={() => {
                  setActiveDate(0);
                  setDateClone({});
                }}
                className='flex justify-between p-2 px-4 border cursor-pointer'
              >
                <p>{getFormattedDate(from, DATE3)}</p>
                <img src={calenderSvg} alt='calender' />
              </div>
            </Grid>
            {tripType === "RoundTrip" && (
              <Grid item xs={12} className='mt-6'>
                <div
                  onClick={() => {
                    setActiveDate(1);
                    setDateClone({ from: from, to: null, enteredTo: null });
                  }}
                  className='flex justify-between p-2 px-4 border cursor-pointer'
                >
                  <p>{getFormattedDate(to, DATE3)}</p>
                  <img src={calenderSvg} alt='calender' />
                </div>
              </Grid>
            )}
          </Grid>
        </div>

        <Button
          label={"select date and continue"}
          bgColor={primary500}
          color={white500}
          width={"100%"}
          onClickHandler={handleModifyBookingDate}
        />
      </main>
      <Calender
        calenderDrawer={calenderDrawer}
        setCalenderDrawer={setCalenderDrawer}
        date={dateClone}
        setDate={setDateClone}
        activeDate={activeDate}
        setActiveDate={setActiveDate}
        onClickHandler={dateOnSubmitHandler}
        tripType={tripType}
      />
    </>
  );
};

export default ModifyBooking;
